import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Components/SideBar";
import {
  Form,
  Button,
  message,
  Upload,
  Spin,
  Modal,
  Row,
  Col,
  DatePicker,
  Dropdown,
  Menu,
  Tooltip,
} from "antd";
import moment from "moment";

import { saveAs } from "file-saver";

import ProfileSideBar from "../Components/ProfileSideBar";
import "./ProfileStyles.css";
import HeaderBarTabs from "../Components/Header/HeaderTabs";
import { Images } from "../Themes";
import ProfileTopBarTabs from "../Components/Shared/ProfileTopBar";
import history from "../services/history";

import ProcessingPerson from "../Components/Client/ProcessingPerson";
import Connections from "../Components/Client/Connections";
import PersonalInformation from "../Components/Client/PersonalInformation";
import CurrentVisa from "../Components/Client/CurrentVisa";
import Medicals from "../Components/Client/Medicals";
import Passport from "../Components/Client/Passport";
import InzLogin from "../Components/Client/InzLogin";
import NZQADetail from "../Components/Client/NZQADetail";
import BillingAddress from "../Components/Client/BillingAddress";
import ClientAreaEmail from "../Components/Client/ClientAreaEmail";
import { setSelectedBranchId } from "./../store/Actions";

import activityData from "../Components/ClientActivity/ActivityData";

import headOption from "../Components/Header/HeaderTabOptions";
import AddAutoReminder from "../Components/Reminder/AddAutoReminder";
import { LinkQuestionnaire } from "../Containers";
import { CheckOutlined } from "@material-ui/icons";
import queryString from "query-string";
import { apiRefresh } from "../services/api";
import AddCustomFields from "../Components/Client/AddCustomfields";

var selectedBranchId = localStorage.getItem("selectedBranchId");

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

// var headOption = [];

const topBar = [
  { tabName: "CLIENT INFORMATION", linkName: "/profile" },
  { tabName: "EMPLOYER INFORMATION", linkName: "/employer" },
  { tabName: "JOB HISTORY", linkName: "/job-history" },
  { tabName: "QUALIFICATION", linkName: "/qualification" },
  { tabName: "OTHER INFO", linkName: "/client-other-info" },
];

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const dateFormat = "DD/MM/YYYY";

var menu = (
  <Menu>
    {/* <Menu.Item key="0">
      <Link to="/profile">CLIENT PROFILE</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to="/partner-profile">PARTNER DETAILS</Link>
    </Menu.Item>
    {/* <Menu.Divider />
    <Menu.Item key="2">
      <Link to="/client-family-member">FAMILY DETAILS</Link>
    </Menu.Item>
    */}
  </Menu>
);

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      selectedOption: null,
      date: new Date(),
      signedClicked: false,
      onshore: false,
      active: false,
      allowUpdate: false,
      areaAccess: false,
      updLoading: false,
      addClientImageUrl: "",
      loadUploadImage: false,
      showClient: false,
      visible: false,
      loadClient: true,
      signedModalVisible: false,
      headerOptions: [],
      selectedQuestionniareId: null,
      showDetail: false,
      users: [],
      showContList: true,
      profileDataState: null,
      branchProfileSetting: null,
    };

    const initialQueryValue = queryString.parse(
      props.location && props.location.search
    );

    var clientIdParam =
      initialQueryValue && initialQueryValue.para ? initialQueryValue.para : "";

    setSelectedBranchId(selectedBranchId);
    // this.setState({ loadClient: true });
    this.props.onSetActiveKey(null);
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let clientProfileIdMainLoc = sessionStorage.getItem("clientProfileIdMain");
    let userId = localStorage.getItem("userId");

    let clientId = props.location.state
      ? props.location.state.id
      : clientIdParam || (clientProfileIdMain && clientProfileIdMain);
    setTimeout(() => {
      if (!clientId) {
        this.setState({ loadClient: false });
      }
    }, 1500);
    // if (this.state.profileDataState && !props.location.state) {
    //   clientId = this.state.profileDataState.id;
    // }
    this.getProfileSettings();
    if (clientId) {
      this.props.onGetAutoImportEmailClient(clientId).then((res) => {
        if (res.payload.length > 0 && res.payload[0].isAutoImportEmail) {
          var emailQueueOpt = {
            url: `v1/ClientEmailImport/ImportEmailQueue`,
          };

          emailQueueOpt.types = ["EMAIL_QUEUE_SUCCESS", "EMAIL_QUEUE_FAILURE"];
          apiRefresh.post(emailQueueOpt, { clientId: clientId });
        }
      });
      this.props
        .onGetClientProfile(clientId)
        .then((res) => {
          let clientNumber = res.payload.clientNumberIZM;
          localStorage.setItem("ezmId", res.payload.clientNumberIZM);
          localStorage.setItem("inzId", res.payload.clientNumber);
          sessionStorage.setItem("agentId", res.payload.agentUserId);
          this.setState({ loadClient: false });

          this.setProfileData(res.payload);
          let data = {
            ezmid: res.payload.clientNumberIZM,
          };
          this.props.onCreateClientLink(data).then((resp) => {
            var hostName =
              window.location.origin == "https://app.ezymigrate.com"
                ? "https://checkmyvisa.io"
                : "https://stage.checkmyvisa.io";

            let tempData = {
              templateName: "clientemail",
              parameters: [
                {
                  key: "Content",
                  value: "CheckMyVisa",
                },
                {
                  key: "ClientId",
                  value: clientId,
                },
                {
                  key: "UserId",
                  value: userId,
                },
                {
                  key: "Link",
                  value: hostName + "/setPassword/" + clientNumber,
                },
              ],
            };
            this.props.onSetCmvLinkTemplate(tempData);
            this.props.onGetSignature();

            // this.props.onGetVisaApplication();
          });
        })
        .catch((err) => {
          this.setState({ loadClient: false });
        });
      if (clientId)
        this.props
          .onGetAllFilledQuestionnaire(clientId)
          .then((res) => {
            this.setState({ loading: false });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
    } else {
      setTimeout(() => {
        this.setState({ loadClient: false });
      }, 1500);
    }
    let selectedTab = {
      headName: "Client Profile",
      headPath: "/profile",
    };
    this.props.onSetClientTab(selectedTab);
    // this.props.onGetUserBranch();
    this.props.getClientSource();
    this.props.onGetClientLink();
    // this.props.onGetAllClient();
    this.props.onGetGroups();
    // this.props.onGetGroupMembers();
    this.props.onGetCountries();
    this.props.onGetTeamMember();
    this.props.onGetAccessingAuth();
    this.props.onGetVisaType(localStorage.getItem("selectedBranchId"));
    // this.props.onGetVisaTypeByCountry("168");
    // this.props.onGetVisaStatus();
    this.props.onGetBVisaCountries();
    this.props.onGetAgents().then((res) => {});
    this.props.onGetPermProcessingPerson(true).then((res) => {
      this.setState({ users: res.payload.users });
    });
    // this.props.onGetReminder(branchId);

    // this.props.onAddAccAuthorities(data)
  }

  getProfileSettings = () => {
    const getBrnchProfileSetOpt = {
      url: `v1/branch/ShowHideClientProfileSetting`,
    };
    getBrnchProfileSetOpt.types = [
      "GET_CLIENT_PROFILE_SETTING_SUCCESS",
      "GET_CLIENT_PROFILE_SETTING_FAILURE",
    ];
    apiRefresh
      .get(getBrnchProfileSetOpt)
      .then((res) => {
        this.setState({ branchProfileSetting: res.clientProfileSetting });
      })
      .catch(() => {
        this.setState({ branchProfileSetting: [] });
      });
    const getClientProfileSetOpt = {
      url: `v1/users/ClientProfileSetting/00000000-0000-0000-0000-000000000000`,
    };
    getClientProfileSetOpt.types = [
      "GET_CLIENT_PROFILE_SETTING_SUCCESS",
      "GET_CLIENT_PROFILE_SETTING_FAILURE",
    ];
    apiRefresh
      .get(getClientProfileSetOpt)
      .then((res) => {
        this.setState({ showContList: res.clientProfileSetting });
      })
      .catch(() => {
        this.setState({ showContList: [] });
      });
  };

  setProfileData = (data) => {
    var clientEmails = data.emails.find((obj) => obj.emailTypeId == 1);
    var clientSecondaryEmail = data.emails.find((obj) => obj.emailTypeId == 2);
    var clientOtherEmail = data.emails.find((obj) => obj.emailTypeId == 3);

    let profileData = {
      image: data.imageBlobUrl,
      fullName: data.firstName + " " + data.lastName,
      ezmid: data.clientNumberIZM,
      inzNumber: data.clientNumber,
      createdDate: data.createdDate,
      modifiedDate: data.modifiedDate,
      primaryEmail: (clientEmails && clientEmails.address) || "",
      secondaryEmail:
        (clientSecondaryEmail && clientSecondaryEmail.address) || "",
      otherEmail: (clientOtherEmail && clientOtherEmail.address) || "",
      mobileNumber: data.phones.length > 0 ? data.phones[0] : null,
    };

    this.props.onSetProfileData(JSON.stringify(profileData));

    localStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("profileData", JSON.stringify(profileData));
    sessionStorage.setItem("agentId", data.agentUserId);
  };

  componentDidUpdate(PrevProps) {
    if (PrevProps.clientTab !== this.props.clientTab) {
      var options = headOption(this.props.clientTab);
      this.setState({ headerOptions: options });
    }
    if (
      PrevProps.allFilledQuestionnaireRes !=
      this.props.allFilledQuestionnaireRes
    ) {
      menu = (
        <Menu>
          {this.props.allFilledQuestionnaireRes.map((questionnaire, index) => (
            <Menu.Item key={index}>
              <div
                onClick={() =>
                  this.setState({
                    selectedQuestionniareId: questionnaire.questionnaireId,
                    showDetail: true,
                  })
                }
              >
                {questionnaire.questionnaireName}
              </div>
            </Menu.Item>
          ))}
        </Menu>
      );
    }
    if (PrevProps.clientProfileData !== this.props.clientProfileData) {
      if (!PrevProps.clientProfileData) {
        for (var i = 0; i < this.props.clientProfileData.groups.length; i++) {
          this.props.onGetGroupMembers(
            this.props.clientProfileData.groups[i].groupId
          );
        }
      }
      let profileObj = this.props.clientProfileData;
      if (profileObj.customFields.length > 0) {
        for (var i = 0; i < profileObj.customFields.length; i++) {
          if (profileObj.customFields[i].fieldType === "2")
            profileObj.customFields[i].fieldData = profileObj.customFields[i]
              .fieldData
              ? moment(profileObj.customFields[i].fieldData)
              : "";
        }
      }
      this.setState({
        addClientImageUrl: this.props.clientProfileData.imageBlobUrl,
        profileDataState: profileObj,
      });
      if (this.props.clientProfileData.clientPermission) {
        this.setState({
          signed: this.props.clientProfileData.clientPermission.signed,
          onshore: this.props.clientProfileData.clientPermission.onshore,
          active: this.props.clientProfileData.clientPermission.active,
          allowUpdate: this.props.clientProfileData.clientPermission
            .allowUpdate,
          areaAccess: this.props.clientProfileData.clientPermission.areaAccess,
        });
      }
      var email = "";
      var secondaryEmail = "";
      var otherEmail = "";
      if (this.props.clientProfileData.emails.length > 0) {
        for (var i = 0; i < this.props.clientProfileData.emails.length; i++) {
          if (this.props.clientProfileData.emails[i].emailTypeId === 1) {
            email = this.props.clientProfileData.emails[i].address;
          }
          if (this.props.clientProfileData.emails[i].emailTypeId === 2) {
            secondaryEmail = this.props.clientProfileData.emails[i].address;
          }
          if (this.props.clientProfileData.emails[i].emailTypeId === 3) {
            otherEmail = this.props.clientProfileData.emails[i].address;
          }
        }
      }
      var billAddressData = null;
      var clientAddressData = null;
      if (this.props.clientProfileData.addresses.length > 0) {
        const findBillAddress = this.props.clientProfileData.addresses.find(
          (obj) => obj.addressTypeId === 2
        );
        if (findBillAddress) {
          billAddressData = {
            contactPerson: findBillAddress.contactPerson,
            flat: findBillAddress.flat,
            streetNumber: findBillAddress.streetNumber,
            suburb: findBillAddress.suburb,
            city: findBillAddress.city,
            country: findBillAddress.country,
            zipcode: findBillAddress.zip,
          };
        }
        const findAddress = this.props.clientProfileData.addresses.find(
          (obj) => obj.addressTypeId === 1
        );
        if (findAddress) {
          clientAddressData = {
            address: findAddress.city,
          };
        }
      }
      var medicalData = null;
      if (this.props.clientProfileData.medicals.length > 0) {
        medicalData = {
          medicalIssueDate: this.props.clientProfileData.medicals[0]
            .medicalIssueDate,
          medicalExpiryDate: this.props.clientProfileData.medicals[0]
            .medicalExpiryDate,
          medicalGrading: this.props.clientProfileData.medicals[0]
            .medicalGrading,
          xrayIssueDate: this.props.clientProfileData.medicals[0].xrayIssueDate,
          xrayExpiryDate: this.props.clientProfileData.medicals[0]
            .xrayExpiryDate,
          xrayGrading: this.props.clientProfileData.medicals[0].xrayGrading,
          medicalNotes: this.props.clientProfileData.medicals[0].medicalNotes,
          medicalNotesDetail: this.props.clientProfileData.medicals[0]
            .medicalNotesDetail,
        };
      }
      var phoneData = {
        mobile: "",
        secondaryMobile: "",
        overseasMobile: "",
        landLine: "",
        otherMobile: "",
      };
      if (this.props.clientProfileData.phones.length > 0) {
        const findMobile = this.props.clientProfileData.phones.find(
          (obj) => obj.phoneTypeId === 1
        );
        if (findMobile) {
          phoneData.mobile = findMobile.contact;
        }
        const findSecondaryMobile = this.props.clientProfileData.phones.find(
          (obj) => obj.phoneTypeId === 2
        );
        if (findSecondaryMobile) {
          phoneData.secondaryMobile = findSecondaryMobile.contact;
        }
        const findOverseasMobile = this.props.clientProfileData.phones.find(
          (obj) => obj.phoneTypeId === 3
        );
        if (findOverseasMobile) {
          phoneData.overseasMobile = findOverseasMobile.contact;
        }
        const findLandLineMobile = this.props.clientProfileData.phones.find(
          (obj) => obj.phoneTypeId === 4
        );
        if (findLandLineMobile) {
          phoneData.landLine = findLandLineMobile.contact;
        }
        const findOtherMobile = this.props.clientProfileData.phones.find(
          (obj) => obj.phoneTypeId === 5
        );
        if (findOtherMobile) {
          phoneData.otherMobile = findOtherMobile.contact;
        }
      }
    }
  }

  formRef = React.createRef();

  myChangeHandler = (text) => {
    this.setState({ username: text });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  onChangeDate = (date) => this.setState({ date });

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  addPoliceCertificateInfo = (policeCertificateData) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let userId = localStorage.getItem("userId");
    let data = {
      clientId: clientprofileid,
      issueDate: "2020-12-07T08:08:09.142Z",
      certificateExpiryDate: "2020-12-07T08:08:09.142Z",
      country: 168,
    };
  };

  onGetProfile = () => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.props.onGetClientProfile(clientprofileid);
  };

  addAuthority = (data) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.props.onAddAccAuthorities(data).then(() => {
      message.success("Accessing Authority added successfully");
      this.props.onGetClientProfile(clientprofileid);
    });
  };

  addProcessingGroup = (data, groupName) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    var clientEmails = this.state.profileDataState.emails.find(
      (obj) => obj.emailTypeId == 1
    );
    var clientSecondaryEmail = this.state.profileDataState.emails.find(
      (obj) => obj.emailTypeId == 2
    );
    var clientOtherEmail = this.state.profileDataState.emails.find(
      (obj) => obj.emailTypeId == 3
    );
    this.setState({ loading: true });
    this.props.onAddProcessingGroups(data).then(() => {
      this.setState({ loading: false });
      this.props.onGetClientProfile(clientprofileid);
      // this.props.onGetGroupMembers(data.groupId);
      // this.props.onGetMailBox(data.userId).then((res) => {
      //   let addEmailImp = {
      //     clientId: clientprofileid,
      //     isRead: true,
      //     isAutoImportEmail: true,
      //     isImportFromPrimaryEmail:
      //       clientEmails && clientEmails.address ? true : false,
      //     isImportFromSecondaryEmail:
      //       clientSecondaryEmail && clientSecondaryEmail.address ? true : false,
      //     isImportFromOtherEmail:
      //       clientOtherEmail && clientOtherEmail.address ? true : false,
      //     importStartDate: new Date(),
      //     istTimeImport: true,
      //   };
      //   const getAutoImportOpt = {
      //     url: `v1/users/AutoEmailON/${data.userId}`,
      //   };
      //   getAutoImportOpt.types = [
      //     "GET_AUTO-IMPORT_SUCCESS",
      //     "GET_AUTO-IMPORT_FAILURE",
      //   ];
      //   apiRefresh.get(getAutoImportOpt).then((res) => {
      //     if (res.isAutoEmailON)
      //       this.props.onAutoImportEmailClient(addEmailImp);
      //   });
      // });
      let userName = localStorage.getItem("userName");
      // var profileData = JSON.parse(sessionStorage.getItem("profileData"));
      var profileData = JSON.parse(this.props.profileDataRdx);
      let myData = {
        clientName: profileData.fullName,
        logMessage: "Processing team " + groupName + " added by " + userName,
        date: moment(new Date()).format("DD/MM/YYYY"),
        logType: "Client Information",
        invoiceId: "0",
      };
      activityData(myData);
    });
  };

  addProcessingPerson = (data, personName) => {
    var clientEmails = this.state.profileDataState.emails.find(
      (obj) => obj.emailTypeId == 1
    );
    var clientSecondaryEmail = this.state.profileDataState.emails.find(
      (obj) => obj.emailTypeId == 2
    );
    var clientOtherEmail = this.state.profileDataState.emails.find(
      (obj) => obj.emailTypeId == 3
    );
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.setState({ loading: true });
    this.props.onAddProcessingPerson(data).then(() => {
      this.props.onGetClientProfile(clientprofileid);
      this.setState({ loading: false });
      this.props.onGetMailBox(data.userId).then((res) => {
        let addEmailImp = {
          clientId: clientprofileid,
          isRead: true,
          isAutoImportEmail: true,
          isImportFromPrimaryEmail:
            clientEmails && clientEmails.address ? true : false,
          isImportFromSecondaryEmail:
            clientSecondaryEmail && clientSecondaryEmail.address ? true : false,
          isImportFromOtherEmail:
            clientOtherEmail && clientOtherEmail.address ? true : false,
          importStartDate: new Date(),
          istTimeImport: true,
          userId: data.userId,
        };
        const getAutoImportOpt = {
          url: `v1/users/AutoEmailON/${data.userId}`,
        };
        getAutoImportOpt.types = [
          "GET_AUTO-IMPORT_SUCCESS",
          "GET_AUTO-IMPORT_FAILURE",
        ];
        apiRefresh.get(getAutoImportOpt).then((res) => {
          if (res.isAutoEmailON)
            this.props.onAutoImportEmailClient(addEmailImp);
        });
      });
      message.success("Updated!");
      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(this.props.profileDataRdx);
      let myData = {
        clientName: profileData.fullName,
        logMessage:
          "Client Information " + personName + " added by " + userName,
        date: moment(new Date()).format("DD/MM/YYYY"),
        logType: "Client Information",
        invoiceId: "0",
      };
      activityData(myData);
    });
  };

  removeCertificate = (data) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.props.onRemovePoliceCertificate(data).then(() => {
      message.success("Police Certificate removed successfully");
      this.props.onGetClientProfile(clientprofileid);
    });
  };

  removeAuthority = (data) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.props.onRemoveAccesingAuthority(data).then(() => {
      message.success("Accessing Authority removed successfully");
      this.props.onGetClientProfile(clientprofileid);
    });
  };

  removeProcessingGroup = (data, groupName) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    this.setState({ loading: true });
    this.props.onRemoveProcessingGroup(data).then(() => {
      this.setState({ loading: false });
      message.success("Processing group removed successfully");
      this.props.onGetClientProfile(clientprofileid);
      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(this.props.profileDataRdx);
      let myData = {
        clientName: profileData.fullName,
        logMessage: "Processing team " + groupName + " removed by " + userName,
        date: moment(new Date()).format("DD/MM/YYYY"),
        logType: "Client Information",
        invoiceId: "0",
      };
      activityData(myData);
    });
  };

  removeProcessingPerson = (data, personName) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.setState({ loading: true });
    this.props.onRemoveProcessingPerson(data).then(() => {
      this.setState({ loading: false });
      message.success("Processing person removed successfully");
      this.props.onGetClientProfile(clientprofileid);
      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(this.props.profileDataRdx);
      let myData = {
        clientName: profileData.fullName,
        logMessage:
          "Client Information " + personName + " removed by " + userName,
        date: moment(new Date()).format("DD/MM/YYYY"),
        logType: "Client Information",
        invoiceId: "0",
      };
      activityData(myData);
    });
  };

  searchConnection = (data) => {
    this.props.onSearchConnection(data).then((res) => {});
  };

  addConnection = (data, connectionData) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.props.onAddConnection(data).then((res) => {
      this.props.onGetClientProfile(clientprofileid);
      message.success("Client connection added successfully");
      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(this.props.profileDataRdx);
      let myData = {
        clientName: profileData.fullName,
        logMessage:
          "connection " +
          connectionData.firstName +
          " " +
          connectionData.lastName +
          " added by " +
          userName,
        date: moment(new Date()).format("DD/MM/YYYY"),
        logType: "Client Information",
        invoiceId: "0",
      };
      activityData(myData);
    });
  };

  removeConnection = (data, connectionName) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.props.onRemoveConnection(data).then((res) => {
      this.props.onGetClientProfile(clientprofileid);
      message.success("Client connection removed successfully");
      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(this.props.profileDataRdx);
      let myData = {
        clientName: profileData.fullName,
        logMessage: "connection " + connectionName + " removed by " + userName,
        date: moment(new Date()).format("DD/MM/YYYY"),
        logType: "Client Information",
        invoiceId: "0",
      };
      activityData(myData);
    });
  };

  checkDuplicateEmail = (values, content) => {
    let data = {
      email: values.email,
      emailTypeId: 1,
    };

    this.props
      .onCheckUniqueEmail(data)
      .then((res) => {
        // this.personalInfoUpdate(values, content);
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          const create = window.confirm(
            "Client with email: " +
              values.email +
              " already exist. Do you want to continue?"
          );
          if (create) {
            // this.personalInfoUpdate(values, content);
          }
        }
      });
  };

  // changes

  getClientProf = (count) => {
    if (count == 4) {
      let clientprofileid = sessionStorage.getItem("clientprofileid");
      this.props
        .onGetClientProfile(clientprofileid)
        .then((res) => {
          this.setState({ loadClient: false });
          this.setProfileData(res.payload);
        })
        .catch(() => {
          this.setState({ loadClient: false });
        });
    }
  };

  transformData = (data) => {
    return data.map((obj) =>
      Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key,
          value === null ? "" : value,
        ])
      )
    );
  };

  personalInfoUpdate = (values, content) => {
    let date1 = new Date();
    let date2 = new Date(this.state.profileDataState.dateOfBirth);
    let yearsDiff = 0;
    var setReminder = false;
    var count = 0;
    if (
      this.state.profileDataState.dateOfBirth !== "1900-01-01T00:00:00+00:00"
    ) {
      yearsDiff = date1.getFullYear() - date2.getFullYear();
    }
    let clientprofileid = sessionStorage.getItem("clientprofileid");

    const userId = localStorage.getItem("userId");
    this.setState({ loadClient: true });
    let addressData = [];
    if (
      this.state.profileDataState.addresses &&
      this.state.profileDataState.addresses.length > 0
    ) {
      if (this.state.profileDataState.addresses.length == 1) {
        let address = {
          id: 0,
          clientId: sessionStorage.getItem("clientprofileid"),
          contactPerson: "",
          flat: "",
          building: "",
          streetName: "",
          suburb: "",
          streetNumber: "",
          city: "",
          state: "",
          zip: "",
          country: 168,
          addressTypeId: 0,
        };
        addressData.push(address);
      }
      for (var i = 0; i < this.state.profileDataState.addresses.length; i++) {
        let addressValues = {
          id: this.state.profileDataState.addresses[i].id,
          clientId: this.state.profileDataState.addresses[i].clientId,
          contactPerson: this.state.profileDataState.addresses[i].contactPerson,
          flat: this.state.profileDataState.addresses[i].flat,
          building: this.state.profileDataState.addresses[i].building,
          streetName: this.state.profileDataState.addresses[i].streetName,
          suburb: this.state.profileDataState.addresses[i].suburb,
          streetNumber: this.state.profileDataState.addresses[i].streetNumber,
          city:
            this.state.profileDataState.addresses[i].addressTypeId === 1
              ? values.address || ""
              : this.state.profileDataState.addresses[i].city,
          state: this.state.profileDataState.addresses[i].state,
          zip: this.state.profileDataState.addresses[i].zip,
          country: this.state.profileDataState.addresses[i].country,
          addressTypeId: this.state.profileDataState.addresses[i].addressTypeId,
        };
        addressData.push(addressValues);
      }
      this.props.onUpdClientAddress(addressData).then((res) => {
        count++;
        this.getClientProf(count);
      });
    } else {
      let addresses = [
        {
          id: 0,
          contactPerson: "",
          flat: "",
          building: "",
          streetName: "",
          suburb: "",
          streetNumber: "",
          city: values.address || "",
          state: "",
          zip: "",
          country: 0,
          addressTypeId: 1,
          clientId: sessionStorage.getItem("clientprofileid"),
        },
        {
          id: 0,
          contactPerson: "",
          flat: "",
          building: "",
          streetName: "",
          suburb: "",
          streetNumber: "",
          city: "",
          state: "",
          zip: "",
          country: 0,
          addressTypeId: 5,
          clientId: sessionStorage.getItem("clientprofileid"),
        },
      ];
      addressData = [...addresses];
      this.props.onUpdClientAddress(addressData).then((res) => {
        count++;
        this.getClientProf(count);
      });
    }
    if (
      this.state.profileDataState.emails &&
      this.state.profileDataState.emails.length > 0
    ) {
      let emailData = [];
      if (this.state.profileDataState.emails.length == 1) {
        let secondaryEmail = {
          id: 0,
          address: values.secondaryEmail || "",
          emailTypeId: 2,
          clientId: sessionStorage.getItem("clientprofileid"),
        };
        let otherEmail = {
          id: 0,
          address: values.otherEmail || "",
          emailTypeId: 3,
          clientId: sessionStorage.getItem("clientprofileid"),
        };
        emailData.push(secondaryEmail);
        emailData.push(otherEmail);
      }
      for (var i = 0; i < this.state.profileDataState.emails.length; i++) {
        let emailValues = {
          id: this.state.profileDataState.emails[i].id,
          clientId: this.state.profileDataState.emails[i].clientId,
          address:
            this.state.profileDataState.emails[i].emailTypeId === 1
              ? values.email || ""
              : this.state.profileDataState.emails[i].emailTypeId === 2
              ? values.secondaryEmail || ""
              : values.otherEmail || "",
          emailTypeId: this.state.profileDataState.emails[i].emailTypeId,
        };
        emailData.push(emailValues);
      }
      this.props.onUpdClientEmail(emailData).then((res) => {
        count++;
        this.getClientProf(count);
      });
    } else {
      let emailData = [];
      let primaryEmail = {
        id: 0,
        address: values.email || "",
        emailTypeId: 1,
        clientId: sessionStorage.getItem("clientprofileid"),
      };
      let secondaryEmail = {
        id: 0,
        address: values.secondaryEmail || "",
        emailTypeId: 2,
        clientId: sessionStorage.getItem("clientprofileid"),
      };
      let otherEmail = {
        id: 0,
        address: values.otherEmail || "",
        emailTypeId: 3,
        clientId: sessionStorage.getItem("clientprofileid"),
      };
      emailData.push(primaryEmail);
      emailData.push(secondaryEmail);
      emailData.push(otherEmail);
      this.props.onUpdClientEmail(emailData).then((res) => {
        count++;
        this.getClientProf(count);
      });
    }
    let phonesData = [];
    if (
      this.state.profileDataState.phones &&
      this.state.profileDataState.phones.length > 0
    ) {
      let phonesData = [];
      if (this.state.profileDataState.phones.length < 5) {
        for (var i = 0; i < 5; i++) {
          let phone = {
            id:
              i == 0
                ? this.state.profileDataState.phones[0].id
                : i == 3
                ? this.state.profileDataState.phones[1].id
                : 0,
            clientId: sessionStorage.getItem("clientprofileid"),
            contact:
              i === 0
                ? (values.mobilePhone && values.mobilePhone.toString()) || ""
                : i === 1
                ? values.secondaryMobile || ""
                : i === 2
                ? values.overseasMobile || ""
                : i === 3
                ? values.landLine || ""
                : values.otherMobile || "",
            countryCodeId: 168,
            phoneTypeId: i + 1,
          };
          phonesData.push(phone);
        }
      } else {
        for (var i = 0; i < this.state.profileDataState.phones.length; i++) {
          let phonesValues = {
            id: this.state.profileDataState.phones[i].id,
            clientId: this.state.profileDataState.phones[i].clientId,
            contact:
              this.state.profileDataState.phones[i].phoneTypeId === 1
                ? (values.mobilePhone && values.mobilePhone.toString()) || ""
                : this.state.profileDataState.phones[i].phoneTypeId === 2
                ? values.secondaryMobile || ""
                : this.state.profileDataState.phones[i].phoneTypeId === 3
                ? values.overseasMobile || ""
                : this.state.profileDataState.phones[i].phoneTypeId === 4
                ? values.landLine || ""
                : values.otherMobile || "",
            countryCodeId:
              this.state.profileDataState.phones[i].phoneTypeId === 1
                ? parseInt(values.countryCodeId) ||
                  this.state.profileDataState.phones[i].countryCodeId
                : this.state.profileDataState.phones[i].countryCodeId,
            phoneTypeId: this.state.profileDataState.phones[i].phoneTypeId,
          };
          phonesData.push(phonesValues);
        }
      }

      this.props.onUpdClientPhone(phonesData).then((res) => {
        count++;
        this.getClientProf(count);
      });
    } else {
      let _phones = [
        {
          id: 0,
          countryCodeId: values.countryCodeId
            ? parseInt(values.countryCodeId)
            : 0,
          phoneTypeId: 1,
          contact: (values.mobilePhone && values.mobilePhone.toString()) || "",
          clientId: sessionStorage.getItem("clientprofileid"),
        },
        {
          id: 0,
          countryCodeId: 168,
          phoneTypeId: 2,
          contact: values.secondaryMobile || "",
          clientId: sessionStorage.getItem("clientprofileid"),
        },
        {
          id: 0,
          countryCodeId: 168,
          phoneTypeId: 3,
          contact: values.overseasMobile || "",
          clientId: sessionStorage.getItem("clientprofileid"),
        },
        {
          id: 0,
          countryCodeId: 168,
          phoneTypeId: 4,
          contact: values.landLine || "",
          clientId: sessionStorage.getItem("clientprofileid"),
        },
        {
          id: 0,
          countryCodeId: 168,
          phoneTypeId: 5,
          contact: values.otherMobile || "",
          clientId: sessionStorage.getItem("clientprofileid"),
        },
      ];
      phonesData = [..._phones];
      this.props.onUpdClientPhone(phonesData).then((res) => {
        count++;
        this.getClientProf(count);
      });
    }
    var dateOfBirth = "";
    if (values.dateOfBirth) {
      dateOfBirth = moment(values.dateOfBirth).format(
        "YYYY-MM-DDT00:00:00+00:00"
      );
      // dateOfBirth.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      // dateOfBirth.toISOString();
      // dateOfBirth.format();
    }
    let data = {
      id: this.state.profileDataState.id,
      clientNumber: this.state.profileDataState.clientNumber,
      familyId: this.state.profileDataState.familyId,
      processingGroupId: 0,
      agentId: values.agentId || "00000000-0000-0000-0000-000000000000",
      clientTag: this.state.profileDataState.clientTag,
      firstName: values.firstName
        ? values.firstName
        : this.state.profileDataState.firstName,
      lastName: values.lastName
        ? values.lastName
        : this.state.profileDataState.lastName,
      middleName: values.middleName
        ? values.middleName
        : this.state.profileDataState.middleName,
      title: values.title ? values.title : "",
      gender: values.gender ? parseInt(values.gender) : 0,
      dateOfBirth: dateOfBirth ? dateOfBirth : "1900-01-01T00:00:00+00:00",
      maritalStatus: values.maritalStatus
        ? values.maritalStatus
        : this.state.profileDataState.maritalStatus,
      dependentChildren: values.dependentChildren
        ? parseInt(values.dependentChildren)
        : 0,
      notes: content || "",
      occupation: values.occupation ? values.occupation : "",
      occupationOrganization: this.state.profileDataState
        .occupationOrganization,
      inzUserName: values.inzUserName
        ? values.inzUserName
        : this.state.profileDataState.inzUserName,
      inzPassword: values.inzPassword
        ? values.inzPassword
        : this.state.profileDataState.inzPassword,
      imageBlobUrl: this.state.profileDataState.imageBlobUrl,
      nationalityId: values.nationalityId !== "" ? values.nationalityId : "",
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : "",
      skypeID: this.state.profileDataState.skypeID,
      preferredName: this.state.profileDataState.preferredName,
      isSubscribed: this.state.profileDataState.isSubscribed,
      arbitaryJson: this.state.profileDataState.arbitaryJson,
      dependentClientIds: this.state.profileDataState.dependentClientIds,
      currentVisaTypeId: parseInt(
        values.currentVisaTypeId
          ? values.currentVisaTypeId
          : this.state.profileDataState.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? values.currentNewZealandVisaExpiry
        : this.state.profileDataState.currentNewZealandVisaExpiry,
      travelConditionsValidTo: values.travelConditionsValidTo
        ? values.travelConditionsValidTo
        : this.state.profileDataState.travelConditionsValidTo,
      visaText: values.visaText
        ? values.visaText
        : this.state.profileDataState.visaText,
      visaDenied: values.visaDenied,
      deniedText: values.deniedText ? values.deniedText : "",
      clientNumberIZM: this.state.profileDataState.clientNumberIZM,
      inzFeeDate: values.inzFeeDate
        ? moment(values.inzFeeDate).format("YYYY-MM-DDT00:00:00+00:00")
        : this.state.profileDataState.inzFeeDate,
      interestedVisa: parseInt(values.interestedVisa) || 0,
      memberType: this.state.profileDataState.memberType,
      clientId: this.state.profileDataState.clientId,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? moment(values.nzqaOnlineSubDate).format("YYYY-MM-DDT00:00:00+00:00")
        : this.state.profileDataState.nzqaOnlineSubDate,
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? moment(values.nzqaDocumentSubDate).format("YYYY-MM-DDT00:00:00+00:00")
        : this.state.profileDataState.nzqaDocumentSubDate,
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? moment(values.nzqaDocumentRetDate).format("YYYY-MM-DDT00:00:00+00:00")
        : this.state.profileDataState.nzqaDocumentRetDate,
      visaCountryId: parseInt(
        values.visaCountryId
          ? values.visaCountryId
          : this.state.profileDataState.visaCountryId
      ),
      visaCountyType: parseInt(
        values.visaCountryType
          ? values.visaCountryType
          : this.state.profileDataState.visaCountyType
      ),
      age: yearsDiff !== 0 ? yearsDiff : this.state.profileDataState.age,
      jobSectorId: parseInt(
        values.jobSectorId
          ? values.jobSectorId
          : this.state.profileDataState.jobSectorId
      ),
      sourceId: parseInt(
        values.sourceId ? values.sourceId : this.state.profileDataState.sourceId
      ),
      sourceDescription: values.sourceDescription
        ? values.sourceDescription
        : "",
      clientSerial: values.clientSerial
        ? values.clientSerial
        : this.state.profileDataState.clientSerial,
      companyOptional: values.companyOptional ? values.companyOptional : "",
      dealWorth: values.dealWorth ? values.dealWorth : "",
      saleDate: values.saleDate
        ? moment(values.saleDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      agentUserId: values.agentUserId || "00000000-0000-0000-0000-000000000000",
      customFields: this.transformData(values.customFields) || [],
      clientPermission: {
        signed: this.state.signed,
        onshore: this.state.onshore,
        active: this.state.active,
        allowUpdate: this.state.allowUpdate,
        areaAccess: this.state.areaAccess,
      },
    };

    var dob = dateOfBirth ? moment(dateOfBirth).format("DD/MM/YYYY") : "";
    var oldDob = moment(this.state.profileDataState.dateOfBirth).format(
      "DD/MM/YYYY"
    );

    if (dob && dob != oldDob && dob != "01/01/1900") {
      setReminder = true;
    }

    this.props
      .onUpdClientProfile(data)
      .then(() => {
        sessionStorage.setItem("agentId", values.agentId);
        this.setState({ updLoading: false });
        message.success("Profile updated successfully");
        count++;
        this.getClientProf(count);

        if (setReminder) {
          var profileData = JSON.parse(this.props.profileDataRdx);
          if (profileData) {
            let data = {
              subjectId: clientprofileid,
              subjectName: profileData && profileData.fullName,
              reminderTypeId: 1,
              reminderDescription: "Date of Birth Reminder",
              reminderDetail: "Date of Birth",
              isCompleted: false,
              reminderDate: moment(dateOfBirth).format(
                "YYYY-MM-DDT00:00:00+00:00"
              ),
            };
            this.props.onAddReminderTask(data);
          }
        }

        let userName = localStorage.getItem("userName");
        let myData = {
          clientName: "",
          logMessage: "Client Information updated by " + userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(myData);
      })
      .catch((err) => {
        this.setState({ updLoading: false, loadClient: false });
        message.error("Profile update failed");
        this.props.onGetClientProfile(clientprofileid);
      });
  };

  onUpdateCurrentVisa = (values) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    const userId = localStorage.getItem("userId");
    this.setState({ loadClient: true });
    let data = {
      id: this.state.profileDataState.id,
      clientNumber: this.state.profileDataState.clientNumber,
      familyId: this.state.profileDataState.familyId,
      processingGroupId: 0,
      agentId: this.state.profileDataState.agentId,
      clientTag: this.state.profileDataState.clientTag,
      firstName: values.firstName
        ? values.firstName
        : this.state.profileDataState.firstName,
      lastName: values.lastName
        ? values.lastName
        : this.state.profileDataState.lastName,
      middleName: values.middleName
        ? values.middleName
        : this.state.profileDataState.middleName,
      title: values.title ? values.title : this.state.profileDataState.title,
      gender: values.gender
        ? values.gender
        : this.state.profileDataState.gender,
      dateOfBirth: this.state.profileDataState.dateOfBirth,
      maritalStatus: values.maritalStatus
        ? values.maritalStatus
        : this.state.profileDataState.maritalStatus,
      dependentChildren: values.dependentChildren
        ? values.dependentChildren
        : this.state.profileDataState.dependentChildren,
      notes: this.state.profileDataState.notes,
      occupation: values.occupation
        ? values.occupation
        : this.state.profileDataState.occupation,
      occupationOrganization: this.state.profileDataState
        .occupationOrganization,
      inzUserName: values.inzUserName
        ? values.inzUserName
        : this.state.profileDataState.inzUserName,
      inzPassword: values.inzPassword
        ? values.inzPassword
        : this.state.profileDataState.inzPassword,
      imageBlobUrl: this.state.profileDataState.imageBlobUrl,
      nationalityId: values.nationalityId
        ? values.nationalityId
        : this.state.profileDataState.nationalityId,
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : this.state.profileDataState.nationalityCountry,
      skypeID: this.state.profileDataState.skypeID,
      preferredName: this.state.profileDataState.preferredName,
      isSubscribed: this.state.profileDataState.isSubscribed,
      arbitaryJson: this.state.profileDataState.arbitaryJson,
      dependentClientIds: this.state.profileDataState.dependentClientIds,
      currentVisaTypeId: parseInt(
        values.currentVisaTypeId
          ? values.currentVisaTypeId
          : this.state.profileDataState.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? moment(values.currentNewZealandVisaExpiry).format(
            "YYYY-MM-DDT00:00:00+00:00"
          )
        : "1900-01-01T00:00:00+00:00",
      travelConditionsValidTo: values.travelConditionsValidTo
        ? moment(values.travelConditionsValidTo).format(
            "YYYY-MM-DDT00:00:00+00:00"
          )
        : "1900-01-01T00:00:00+00:00",
      visaText: values.visaText
        ? values.visaText
        : this.state.profileDataState.visaText,
      visaDenied: this.state.profileDataState.visaDenied,
      deniedText: values.deniedText
        ? values.deniedText
        : this.state.profileDataState.deniedText,
      clientNumberIZM: this.state.profileDataState.clientNumberIZM,
      inzFeeDate: values.inzFeeDate
        ? values.inzFeeDate
        : this.state.profileDataState.inzFeeDate,
      interestedVisa: this.state.profileDataState.interestedVisa,
      memberType: this.state.profileDataState.memberType,
      clientId: this.state.profileDataState.clientId,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? values.nzqaOnlineSubDate
        : this.state.profileDataState.nzqaOnlineSubDate,
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? values.nzqaDocumentSubDate
        : this.state.profileDataState.nzqaDocumentSubDate,
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? values.nzqaDocumentRetDate
        : this.state.profileDataState.nzqaDocumentRetDate,
      visaCountryId: parseInt(
        values.visaCountryId
          ? values.visaCountryId
          : this.state.profileDataState.visaCountryId
      ),
      visaCountyType: parseInt(
        values.visaCountryType
          ? values.visaCountryType
          : this.state.profileDataState.visaCountyType
      ),
      age: values.age ? values.age : this.state.profileDataState.age,
      jobSectorId: parseInt(
        values.jobSectorId
          ? values.jobSectorId
          : this.state.profileDataState.jobSectorId
      ),
      sourceId: parseInt(
        values.sourceId ? values.sourceId : this.state.profileDataState.sourceId
      ),
      sourceDescription: values.sourceDescription
        ? values.sourceDescription
        : this.state.profileDataState.sourceDescription,
      clientSerial: values.clientSerial
        ? values.clientSerial
        : this.state.profileDataState.clientSerial,
      companyOptional: values.companyOptional
        ? values.companyOptional
        : this.state.profileDataState.companyOptional,
      dealWorth: values.dealWorth
        ? values.dealWorth
        : this.state.profileDataState.dealWorth,
      saleDate: values.saleDate
        ? values.saleDate
        : this.state.profileDataState.saleDate,
      agentUserId:
        this.state.profileDataState.agentUserId ||
        "00000000-0000-0000-0000-000000000000",
      customFields: this.transformData(values.customFields) || [],
      clientPermission: {
        signed: this.state.signed,
        onshore: this.state.onshore,
        active: this.state.active,
        allowUpdate: this.state.allowUpdate,
        areaAccess: this.state.areaAccess,
      },
    };

    this.props
      .onUpdClientProfile(data)
      .then(() => {
        this.setState({ updLoading: false });
        message.success("Profile updated successfully");

        this.props
          .onGetClientProfile(clientprofileid)
          .then(() => {
            this.setState({ loadClient: false });
          })
          .catch(() => {
            this.setState({ loadClient: false });
          });
        if (
          moment(data.currentNewZealandVisaExpiry).format("DD/MM/YYYY") !=
            moment(
              this.state.profileDataState.currentNewZealandVisaExpiry
            ).format("DD/MM/YYYY") &&
          moment(data.currentNewZealandVisaExpiry).format("DD/MM/YYYY") !=
            "01/01/1900"
        ) {
          let myData = {
            title: "Current Visa Expiry",
            reminderDate: values.currentNewZealandVisaExpiry
              ? moment(values.currentNewZealandVisaExpiry).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 10,
          };
          AddAutoReminder(myData);
        }

        if (
          moment(data.travelConditionsValidTo).format("DD/MM/YYYY") !=
            moment(this.state.profileDataState.travelConditionsValidTo).format(
              "DD/MM/YYYY"
            ) &&
          moment(data.travelConditionsValidTo).format("DD/MM/YYYY") !=
            "01/01/1900"
        ) {
          let tcData = {
            title: "Travel Condition Valid To",
            reminderDate: values.travelConditionsValidTo
              ? moment(values.travelConditionsValidTo).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 13,
          };
          AddAutoReminder(tcData);
        }
      })
      .catch(() => {
        this.setState({ updLoading: false, loadClient: false });
        message.error("Profile update failed");
        this.props.onGetClientProfile(clientprofileid);
      });
  };

  onUpdateNZQA = (values) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    const userId = localStorage.getItem("userId");
    this.setState({ loadClient: true });
    let data = {
      id: this.state.profileDataState.id,
      clientNumber: this.state.profileDataState.clientNumber,
      familyId: this.state.profileDataState.familyId,
      processingGroupId: 0,
      agentId: this.state.profileDataState.agentId,
      clientTag: this.state.profileDataState.clientTag,
      firstName: values.firstName
        ? values.firstName
        : this.state.profileDataState.firstName,
      lastName: values.lastName
        ? values.lastName
        : this.state.profileDataState.lastName,
      middleName: values.middleName
        ? values.middleName
        : this.state.profileDataState.middleName,
      title: values.title ? values.title : this.state.profileDataState.title,
      gender: values.gender
        ? values.gender
        : this.state.profileDataState.gender,
      dateOfBirth: this.state.profileDataState.dateOfBirth,
      maritalStatus: values.maritalStatus
        ? values.maritalStatus
        : this.state.profileDataState.maritalStatus,
      dependentChildren: values.dependentChildren
        ? values.dependentChildren
        : this.state.profileDataState.dependentChildren,
      notes: this.state.profileDataState.notes,
      occupation: values.occupation
        ? values.occupation
        : this.state.profileDataState.occupation,
      occupationOrganization: this.state.profileDataState
        .occupationOrganization,
      inzUserName: values.inzUserName
        ? values.inzUserName
        : this.state.profileDataState.inzUserName,
      inzPassword: values.inzPassword
        ? values.inzPassword
        : this.state.profileDataState.inzPassword,
      imageBlobUrl: this.state.profileDataState.imageBlobUrl,
      nationalityId: values.nationalityId
        ? values.nationalityId
        : this.state.profileDataState.nationalityId,
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : this.state.profileDataState.nationalityCountry,
      skypeID: this.state.profileDataState.skypeID,
      preferredName: this.state.profileDataState.preferredName,
      isSubscribed: this.state.profileDataState.isSubscribed,
      arbitaryJson: this.state.profileDataState.arbitaryJson,
      dependentClientIds: this.state.profileDataState.dependentClientIds,
      currentVisaTypeId: parseInt(
        values.currentVisaTypeId
          ? values.currentVisaTypeId
          : this.state.profileDataState.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? values.currentNewZealandVisaExpiry
        : this.state.profileDataState.currentNewZealandVisaExpiry,
      travelConditionsValidTo: values.travelConditionsValidTo
        ? values.travelConditionsValidTo
        : this.state.profileDataState.travelConditionsValidTo,
      visaText: values.visaText
        ? values.visaText
        : this.state.profileDataState.visaText,
      visaDenied: this.state.profileDataState.visaDenied,
      deniedText: values.deniedText
        ? values.deniedText
        : this.state.profileDataState.deniedText,
      clientNumberIZM: this.state.profileDataState.clientNumberIZM,
      inzFeeDate: values.inzFeeDate
        ? values.inzFeeDate
        : this.state.profileDataState.inzFeeDate,
      interestedVisa: this.state.profileDataState.interestedVisa,
      memberType: this.state.profileDataState.memberType,
      clientId: this.state.profileDataState.clientId,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? moment(values.nzqaOnlineSubDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? moment(values.nzqaDocumentSubDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? moment(values.nzqaDocumentRetDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      visaCountryId: parseInt(
        values.visaCountryId
          ? values.visaCountryId
          : this.state.profileDataState.visaCountryId
      ),
      visaCountyType: parseInt(
        values.visaCountryType
          ? values.visaCountryType
          : this.state.profileDataState.visaCountyType
      ),
      age: values.age ? values.age : this.state.profileDataState.age,
      jobSectorId: parseInt(
        values.jobSectorId
          ? values.jobSectorId
          : this.state.profileDataState.jobSectorId
      ),
      sourceId: parseInt(
        values.sourceId ? values.sourceId : this.state.profileDataState.sourceId
      ),
      sourceDescription: values.sourceDescription
        ? values.sourceDescription
        : this.state.profileDataState.sourceDescription,
      clientSerial: values.clientSerial
        ? values.clientSerial
        : this.state.profileDataState.clientSerial,
      companyOptional: values.companyOptional
        ? values.companyOptional
        : this.state.profileDataState.companyOptional,
      dealWorth: values.dealWorth
        ? values.dealWorth
        : this.state.profileDataState.dealWorth,
      saleDate: values.saleDate
        ? values.saleDate
        : this.state.profileDataState.saleDate,
      agentUserId:
        this.state.profileDataState.agentUserId ||
        "00000000-0000-0000-0000-000000000000",
      customFields: this.transformData(values.customFields) || [],
      clientPermission: {
        signed: this.state.signed,
        onshore: this.state.onshore,
        active: this.state.active,
        allowUpdate: this.state.allowUpdate,
        areaAccess: this.state.areaAccess,
      },
    };
    this.props
      .onUpdClientProfile(data)
      .then(() => {
        this.setState({ updLoading: false });
        message.success("Profile updated successfully");
        this.props
          .onGetClientProfile(clientprofileid)
          .then(() => {
            this.setState({ loadClient: false });
          })
          .catch(() => {
            this.setState({ loadClient: false });
          });
      })
      .catch(() => {
        this.setState({ updLoading: false, loadClient: false });
        message.error("Profile update failed");
        this.props.onGetClientProfile(clientprofileid);
      });
  };

  onUpdateMedicals = (values, content) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    const userId = localStorage.getItem("userId");
    this.setState({ loadClient: true });
    // let data = {
    //   id: this.state.profileDataState.id,
    //   branchId: this.state.profileDataState.branchId,
    //   clientNumber: this.state.profileDataState.clientNumber,
    //   familyId: this.state.profileDataState.familyId,
    //   processingGroupId: 0,
    //   agentId: this.state.profileDataState.agentId,
    //   clientTag: this.state.profileDataState.clientTag,
    //   firstName: values.firstName
    //     ? values.firstName
    //     : this.state.profileDataState.firstName,
    //   lastName: values.lastName
    //     ? values.lastName
    //     : this.state.profileDataState.lastName,
    //   middleName: values.middleName
    //     ? values.middleName
    //     : this.state.profileDataState.middleName,
    //   title: values.title ? values.title : this.state.profileDataState.title,
    //   gender: values.gender
    //     ? values.gender
    //     : this.state.profileDataState.gender,
    //   dateOfBirth: values.dateOfBirth
    //     ? values.dateOfBirth
    //     : this.state.profileDataState.dateOfBirth,
    //   maritalStatus: values.maritalStatus
    //     ? values.maritalStatus
    //     : this.state.profileDataState.maritalStatus,
    //   dependentChildren: values.dependentChildren
    //     ? values.dependentChildren
    //     : this.state.profileDataState.dependentChildren,
    //   notes: "string",
    //   occupation: values.occupation
    //     ? values.occupation
    //     : this.state.profileDataState.occupation,
    //   occupationOrganization: this.state.profileDataState
    //     .occupationOrganization,
    //   inzUserName: values.inzUserName
    //     ? values.inzUserName
    //     : this.state.profileDataState.inzUserName,
    //   inzPassword: values.inzPassword
    //     ? values.inzPassword
    //     : this.state.profileDataState.inzPassword,
    //   imageBlobUrl: this.state.profileDataState.imageBlobUrl,
    //   nationalityId: values.nationalityId
    //     ? values.nationalityId
    //     : this.state.profileDataState.nationalityId,
    //   nationalityCountry: values.nationalityCountry
    //     ? values.nationalityCountry
    //     : this.state.profileDataState.nationalityCountry,
    //   skypeID: this.state.profileDataState.skypeID,
    //   preferredName: this.state.profileDataState.preferredName,
    //   isSubscribed: this.state.profileDataState.isSubscribed,
    //   arbitaryJson: this.state.profileDataState.arbitaryJson,
    //   dependentClientIds: this.state.profileDataState.dependentClientIds,
    //
    //   currentVisaTypeId: parseInt(
    //     values.currentVisaTypeId
    //       ? values.currentVisaTypeId
    //       : this.state.profileDataState.currentVisaTypeId
    //   ),
    //   medicalGrading: values.medicalGrading ? values.medicalGrading : "",
    //   currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
    //     ? values.currentNewZealandVisaExpiry
    //     : this.state.profileDataState.currentNewZealandVisaExpiry,
    //   travelConditionsValidTo: values.travelConditionsValidTo
    //     ? values.travelConditionsValidTo
    //     : this.state.profileDataState.travelConditionsValidTo,
    //   visaText: values.visaText
    //     ? values.visaText
    //     : this.state.profileDataState.visaText,
    //   visaDenied: this.state.profileDataState.visaDenied,
    //   deniedText: values.deniedText
    //     ? values.deniedText
    //     : this.state.profileDataState.deniedText,
    //   clientNumberIZM: this.state.profileDataState.clientNumberIZM,
    //   inzFeeDate: values.inzFeeDate
    //     ? values.inzFeeDate
    //     : this.state.profileDataState.inzFeeDate,
    //   memberType: this.state.profileDataState.memberType,
    //   clientId: this.state.profileDataState.clientId,
    //   nzqaOnlineSubDate: values.nzqaOnlineSubDate
    //     ? values.nzqaOnlineSubDate
    //     : this.state.profileDataState.nzqaOnlineSubDate,
    //   nzqaDocumentSubDate: values.nzqaDocumentSubDate
    //     ? values.nzqaDocumentSubDate
    //     : this.state.profileDataState.nzqaDocumentSubDate,
    //   nzqaDocumentRetDate: values.nzqaDocumentRetDate
    //     ? values.nzqaDocumentRetDate
    //     : this.state.profileDataState.nzqaDocumentRetDate,
    //   visaCountryId: parseInt(
    //     values.visaCountryId
    //       ? values.visaCountryId
    //       : this.state.profileDataState.visaCountryId
    //   ),
    //   visaCountyType: parseInt(
    //     values.visaCountryType
    //       ? values.visaCountryType
    //       : this.state.profileDataState.visaCountyType
    //   ),
    //   age: values.age ? values.age : this.state.profileDataState.age,
    //   jobSectorId: parseInt(
    //     values.jobSectorId
    //       ? values.jobSectorId
    //       : this.state.profileDataState.jobSectorId
    //   ),
    //   sourceId: parseInt(
    //     values.sourceId
    //       ? values.sourceId
    //       : this.state.profileDataState.sourceId
    //   ),
    //   sourceDescription: values.sourceDescription
    //     ? values.sourceDescription
    //     : this.state.profileDataState.sourceDescription,
    //   clientSerial: values.clientSerial
    //     ? values.clientSerial
    //     : this.state.profileDataState.clientSerial,
    //   companyOptional: values.companyOptional
    //     ? values.companyOptional
    //     : this.state.profileDataState.companyOptional,
    //   dealWorth: values.dealWorth
    //     ? values.dealWorth
    //     : this.state.profileDataState.dealWorth,
    //   saleDate: values.saleDate
    //     ? values.saleDate
    //     : this.state.profileDataState.saleDate,
    //   clientPermission: {
    //     signed: this.state.signed,
    //     onshore: this.state.onshore,
    //     active: this.state.active,
    //     allowUpdate: this.state.allowUpdate,
    //     areaAccess: this.state.areaAccess
    //   }
    // };
    let data = null;
    if (
      this.state.profileDataState &&
      this.state.profileDataState.medicals.length > 0
    ) {
      data = [
        {
          id: this.state.profileDataState.medicals[0].id,
          clientId: clientprofileid,
          er: values.er || "",
          medicalIssueDate: values.medicalIssueDate
            ? moment(values.medicalIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          medicalExpiryDate: values.medicalExpiryDate
            ? moment(values.medicalExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          xrayIssueDate: values.xrayIssueDate
            ? moment(values.xrayIssueDate).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          xrayExpiryDate: values.xrayExpiryDate
            ? moment(values.xrayExpiryDate).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          medicalGrading: values.medicalGrading.toString() || "",
          xrayGrading: values.xrayGrading.toString() || "",
          medicalNotes: values.medicalNotes || "",
          medicalNotesDetail: content,
          customFields: this.transformData(values.customFields) || [],
        },
      ];
    } else {
      data = [
        {
          clientId: clientprofileid,
          er: values.er || "",
          medicalIssueDate: values.medicalIssueDate
            ? moment(values.medicalIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          medicalExpiryDate: values.medicalExpiryDate
            ? moment(values.medicalExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          xrayIssueDate: values.xrayIssueDate
            ? moment(values.xrayIssueDate).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          xrayExpiryDate: values.xrayExpiryDate
            ? moment(values.xrayExpiryDate).format("YYYY-MM-DDT00:00:00+00:00")
            : "1900-01-01T00:00:00+00:00",
          medicalGrading: values.medicalGrading.toString() || "",
          xrayGrading: values.xrayGrading.toString() || "",
          medicalNotes: values.medicalNotes || "",
          medicalNotesDetail: content,
        },
      ];
    }
    let cpData = this.state.profileDataState;
    cpData.customFields = this.transformData(values.customFields) || [];
    this.props
      .onUpdClientMedical(data)
      .then(() => {
        this.setState({ updLoading: false });
        message.success("Profile updated successfully");

        this.props.onUpdClientProfile(cpData).then(() => {
          this.props
            .onGetClientProfile(clientprofileid)
            .then(() => {
              this.setState({ loadClient: false });
            })
            .catch(() => {
              this.setState({ loadClient: false });
            });
        });
        if (
          moment(data[0].medicalExpiryDate).format("DD/MM/YYYY") !=
            moment(
              this.state.profileDataState.medicals[0].medicalExpiryDate
            ).format("DD/MM/YYYY") &&
          moment(data[0].medicalExpiryDate).format("DD/MM/YYYY") != "01/01/1900"
        ) {
          let myData = {
            title: "Medical Expiry",
            reminderDate: values.medicalExpiryDate
              ? moment(values.medicalExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 5,
          };
          AddAutoReminder(myData);
        }
        if (
          moment(data[0].xrayExpiryDate).format("DD/MM/YYYY") !=
            moment(
              this.state.profileDataState.medicals[0].xrayExpiryDate
            ).format("DD/MM/YYYY") &&
          moment(data[0].xrayExpiryDate).format("DD/MM/YYYY") != "01/01/1900"
        ) {
          let xrayData = {
            title: "Xray Expiry",
            reminderDate: values.xrayExpiryDate
              ? moment(values.xrayExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 4,
          };
          AddAutoReminder(xrayData);
        }
      })
      .catch(() => {
        this.setState({ updLoading: false, loadClient: false });
        message.error("Profile update failed");
        this.props.onGetClientProfile(clientprofileid);
      });
  };

  onUpdateInzUserDetail = (values) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    const userId = localStorage.getItem("userId");
    this.setState({ loadClient: true });
    let data = {
      id: this.state.profileDataState.id,
      clientNumber: values.clientNumber || "",
      familyId: this.state.profileDataState.familyId,
      processingGroupId: 0,
      agentId: this.state.profileDataState.agentId,
      clientTag: this.state.profileDataState.clientTag,
      firstName: values.firstName
        ? values.firstName
        : this.state.profileDataState.firstName,
      lastName: values.lastName
        ? values.lastName
        : this.state.profileDataState.lastName,
      middleName: values.middleName
        ? values.middleName
        : this.state.profileDataState.middleName,
      title: values.title ? values.title : this.state.profileDataState.title,
      gender: values.gender
        ? values.gender
        : this.state.profileDataState.gender,
      dateOfBirth: this.state.profileDataState.dateOfBirth,
      maritalStatus: values.maritalStatus
        ? values.maritalStatus
        : this.state.profileDataState.maritalStatus,
      dependentChildren: values.dependentChildren
        ? values.dependentChildren
        : this.state.profileDataState.dependentChildren,
      notes: this.state.profileDataState.notes,
      occupation: values.occupation
        ? values.occupation
        : this.state.profileDataState.occupation,
      occupationOrganization: this.state.profileDataState
        .occupationOrganization,
      inzUserName: values.inzUserName ? values.inzUserName : "",
      inzPassword: values.inzPassword ? values.inzPassword : "",
      imageBlobUrl: this.state.profileDataState.imageBlobUrl,
      nationalityId: values.nationalityId
        ? values.nationalityId
        : this.state.profileDataState.nationalityId,
      nationalityCountry: values.nationalityCountry
        ? values.nationalityCountry.toString()
        : this.state.profileDataState.nationalityCountry,
      skypeID: this.state.profileDataState.skypeID,
      preferredName: this.state.profileDataState.preferredName,
      isSubscribed: this.state.profileDataState.isSubscribed,
      arbitaryJson: this.state.profileDataState.arbitaryJson,
      dependentClientIds: this.state.profileDataState.dependentClientIds,
      currentVisaTypeId: parseInt(
        values.currentVisaTypeId
          ? values.currentVisaTypeId
          : this.state.profileDataState.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: values.currentNewZealandVisaExpiry
        ? values.currentNewZealandVisaExpiry
        : this.state.profileDataState.currentNewZealandVisaExpiry,
      travelConditionsValidTo: values.travelConditionsValidTo
        ? values.travelConditionsValidTo
        : this.state.profileDataState.travelConditionsValidTo,
      visaText: values.visaText
        ? values.visaText
        : this.state.profileDataState.visaText,
      visaDenied: this.state.profileDataState.visaDenied,
      deniedText: values.deniedText
        ? values.deniedText
        : this.state.profileDataState.deniedText,
      clientNumberIZM: this.state.profileDataState.clientNumberIZM,
      inzFeeDate: values.inzFeeDate
        ? moment(values.inzFeeDate).format("YYYY-MM-DDT00:00:00+00:00")
        : "1900-01-01T00:00:00+00:00",
      interestedVisa: this.state.profileDataState.interestedVisa,
      memberType: this.state.profileDataState.memberType,
      clientId: this.state.profileDataState.clientId,
      nzqaOnlineSubDate: values.nzqaOnlineSubDate
        ? values.nzqaOnlineSubDate
        : this.state.profileDataState.nzqaOnlineSubDate,
      nzqaDocumentSubDate: values.nzqaDocumentSubDate
        ? values.nzqaDocumentSubDate
        : this.state.profileDataState.nzqaDocumentSubDate,
      nzqaDocumentRetDate: values.nzqaDocumentRetDate
        ? values.nzqaDocumentRetDate
        : this.state.profileDataState.nzqaDocumentRetDate,
      visaCountryId: parseInt(
        values.visaCountryId
          ? values.visaCountryId
          : this.state.profileDataState.visaCountryId
      ),
      visaCountyType: parseInt(
        values.visaCountryType
          ? values.visaCountryType
          : this.state.profileDataState.visaCountyType
      ),
      age: values.age ? values.age : this.state.profileDataState.age,
      jobSectorId: parseInt(
        values.jobSectorId
          ? values.jobSectorId
          : this.state.profileDataState.jobSectorId
      ),
      sourceId: parseInt(
        values.sourceId ? values.sourceId : this.state.profileDataState.sourceId
      ),
      sourceDescription: values.sourceDescription
        ? values.sourceDescription
        : this.state.profileDataState.sourceDescription,
      clientSerial: values.clientSerial
        ? values.clientSerial
        : this.state.profileDataState.clientSerial,
      companyOptional: values.companyOptional
        ? values.companyOptional
        : this.state.profileDataState.companyOptional,
      dealWorth: values.dealWorth
        ? values.dealWorth
        : this.state.profileDataState.dealWorth,
      saleDate: values.saleDate
        ? values.saleDate
        : this.state.profileDataState.saleDate,
      agentUserId:
        this.state.profileDataState.agentUserId ||
        "00000000-0000-0000-0000-000000000000",

      customFields: this.transformData(values.customFields) || [],
      clientPermission: {
        signed: this.state.signed,
        onshore: this.state.onshore,
        active: this.state.active,
        allowUpdate: this.state.allowUpdate,
        areaAccess: this.state.areaAccess,
      },
    };

    this.props
      .onUpdClientProfile(data)
      .then(() => {
        this.setState({ updLoading: false });
        message.success("Profile updated successfully");
        this.props
          .onGetClientProfile(clientprofileid)
          .then(() => {
            this.setState({ loadClient: false });
          })
          .catch(() => {
            this.setState({ loadClient: false });
          });
      })
      .catch(() => {
        this.setState({ updLoading: false, loadClient: false });
        message.error("Profile update failed");
        this.props.onGetClientProfile(clientprofileid);
      });
  };

  onUpdatePassport = (values) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    const userId = localStorage.getItem("userId");
    this.setState({ loadClient: true });
    var setReminder = false;
    var setSecondReminder = false;

    let passportData = [];
    if (
      this.props &&
      this.state.profileDataState.passports &&
      this.state.profileDataState.passports.length > 0
    ) {
      for (var i = 0; i < this.state.profileDataState.passports.length; i++) {
        let passportValues = {
          id: this.state.profileDataState.passports[i].id,
          clientId: this.state.profileDataState.passports[i].clientId,
          passportNo:
            i === 0 ? values.passportNo || "" : values.secondPassportNo || "",
          passportCountry:
            i === 0
              ? parseInt(values.passportCountry) || 0
              : parseInt(values.secondPassportCountry) || 0,
          passportIssueDate:
            i === 0
              ? values.passportIssueDate
                ? moment(values.passportIssueDate).format(
                    "YYYY-MM-DDT00:00:00+00:00"
                  )
                : "1900-01-01T00:00:00+00:00"
              : values.secondPassportIssueDate
              ? moment(values.secondPassportIssueDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
          passportExpiryDate:
            i === 0
              ? values.passportExpiryDate
                ? moment(values.passportExpiryDate).format(
                    "YYYY-MM-DDT00:00:00+00:00"
                  )
                : "1900-01-01T00:00:00+00:00"
              : values.secondPassportExpiryDate
              ? moment(values.secondPassportExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
          passportType: i === 0 ? 1 : 0,
        };
        var expDate = moment(passportValues.passportExpiryDate).format(
          "DD/MM/YYYY"
        );
        var oldDate = moment(
          this.props &&
            this.state.profileDataStatepassports &&
            this.state.profileDataState.passports[i].passportExpiryDate
        ).format("DD/MM/YYYY");
        if (i == 0 && expDate != oldDate && expDate != "01/01/1900") {
          setReminder = true;
        }
        if (i == 1 && expDate != oldDate && expDate != "01/01/1900") {
          setSecondReminder = true;
        }
        passportData.push(passportValues);
      }
    } else {
      passportData = [
        {
          id: 0,
          passportNo: values.passportNo || "",
          passportCountry: values.passportCountry || 0,
          passportType: 1,
          passportIssueDate: values.passportIssueDate
            ? moment(values.passportIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          passportExpiryDate: values.passportExpiryDate
            ? moment(values.passportExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          clientId: clientprofileid,
        },
        {
          id: 0,
          passportNo: values.secondPassportNo || "",
          passportCountry: values.secondPassportCountry || 0,
          passportType: 0,
          passportIssueDate: values.secondPassportIssueDate
            ? moment(values.secondPassportIssueDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          passportExpiryDate: values.secondPassportExpiryDate
            ? moment(values.secondPassportExpiryDate).format(
                "YYYY-MM-DDT00:00:00+00:00"
              )
            : "1900-01-01T00:00:00+00:00",
          clientId: clientprofileid,
        },
      ];
    }
    let cpData = this.state.profileDataState;
    cpData.customFields = this.transformData(values.customFields) || [];
    this.props
      .onUpdClientPassport(passportData)
      .then(() => {
        this.setState({ updLoading: false });
        message.success("Client passport updated successfully");
        var profileData = JSON.parse(this.props.profileDataRdx);
        var clientprofileid = sessionStorage.getItem("clientprofileid");
        if (setReminder) {
          let myData = {
            title: "Passport Expiry",
            reminderDate: values.passportExpiryDate
              ? moment(values.passportExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 7,
          };
          AddAutoReminder(myData);
        }
        if (setSecondReminder) {
          let myDataSecond = {
            title: "Passport Expiry",
            reminderDate: values.secondPassportExpiryDate
              ? moment(values.secondPassportExpiryDate).format(
                  "YYYY-MM-DDT00:00:00+00:00"
                )
              : "1900-01-01T00:00:00+00:00",
            typeId: 7,
          };
          AddAutoReminder(myDataSecond);
        }

        this.props.onUpdClientProfile(cpData).then(() => {
          this.props
            .onGetClientProfile(clientprofileid)
            .then(() => {
              this.setState({ loadClient: false });
            })
            .catch(() => {
              this.setState({ loadClient: false });
            });
        });
      })
      .catch(() => {
        this.setState({ updLoading: false, loadClient: false });
        message.error("Client Passport update failed");
        this.props.onGetClientProfile(clientprofileid);
      });
  };

  onUpdateBillingAddress = (values) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    const userId = localStorage.getItem("userId");
    this.setState({ loadClient: true });
    let addressData = [];
    if (this.state.profileDataState.addresses.length > 0) {
      if (this.state.profileDataState.addresses.length === 1) {
        let addressValues = {
          id: 0,
          clientId: sessionStorage.getItem("clientprofileid"),
          contactPerson: values.contactPerson || "",
          flat: values.flat || "",
          building: "",
          streetName: "",
          suburb: values.suburb || "",
          streetNumber: values.streetNumber || "",
          city: values.city || "",
          state: "",
          zip: values.zipcode || "",
          country: values.billCountry ? parseInt(values.billCountry) : 0,
          addressTypeId: 5,
        };
        addressData.push(addressValues);
      }
      for (var i = 0; i < this.state.profileDataState.addresses.length; i++) {
        let addressValues = {
          id: this.state.profileDataState.addresses[i].id,
          clientId: this.state.profileDataState.addresses[i].clientId,
          contactPerson:
            this.state.profileDataState.addresses[i].addressTypeId === 1
              ? this.state.profileDataState.addresses[i].contactPerson
              : values.contactPerson || "",
          flat:
            this.state.profileDataState.addresses[i].addressTypeId === 1
              ? this.state.profileDataState.addresses[i].flat
              : values.flat || "",
          building: this.state.profileDataState.addresses[i].building,
          streetName: this.state.profileDataState.addresses[i].streetName,
          suburb:
            this.state.profileDataState.addresses[i].addressTypeId === 1
              ? this.state.profileDataState.addresses[i].suburb
              : values.suburb || "",
          streetNumber:
            this.state.profileDataState.addresses[i].addressTypeId === 1
              ? this.state.profileDataState.addresses[i].streetNumber
              : values.streetNumber || "",
          city:
            this.state.profileDataState.addresses[i].addressTypeId === 1
              ? this.state.profileDataState.addresses[i].city
              : values.city || "",
          state: this.state.profileDataState.addresses[i].state,
          zip:
            this.state.profileDataState.addresses[i].addressTypeId === 1
              ? this.state.profileDataState.addresses[i].zip
              : values.zipcode || "",
          country:
            this.state.profileDataState.addresses[i].addressTypeId === 1
              ? this.state.profileDataState.addresses[i].country
              : parseInt(values.billCountry) || 0,
          addressTypeId:
            this.state.profileDataState.addresses[i].addressTypeId == 2 ||
            this.state.profileDataState.addresses[i].addressTypeId == 0
              ? 5
              : this.state.profileDataState.addresses[i].addressTypeId,
        };
        addressData.push(addressValues);
      }
    } else {
      let addressesAdd = [
        {
          id: 0,
          clientId: sessionStorage.getItem("clientprofileid"),
          contactPerson: "",
          flat: "",
          building: "",
          streetName: "",
          suburb: "",
          streetNumber: "",
          city: "",
          state: "",
          zip: "",
          country: 0,
          addressTypeId: 1,
        },
        {
          id: 0,
          clientId: sessionStorage.getItem("clientprofileid"),
          contactPerson: values.contactPerson || "",
          flat: values.flat || "",
          building: "",
          streetName: "",
          suburb: values.suburb || "",
          streetNumber: values.streetNumber || "",
          city: values.city || "",
          state: "",
          zip: values.zipcode || "",
          country: values.billCountry ? parseInt(values.billCountry) : 0,
          addressTypeId: 5,
        },
      ];
      addressData = [...addressesAdd];
    }
    let cpData = this.state.profileDataState;
    cpData.customFields = this.transformData(values.customFields) || [];
    this.props
      .onUpdClientAddress(addressData)
      .then(() => {
        this.setState({ updLoading: false });
        message.success("Client billing address updated successfully");
        this.props.onUpdClientProfile(cpData).then(() => {
          this.props
            .onGetClientProfile(clientprofileid)
            .then(() => {
              this.setState({ loadClient: false });
            })
            .then(() => {
              this.setState({ loadClient: false });
            });
        });
      })
      .catch(() => {
        this.setState({ updLoading: false, loadClient: false });
        message.error("Client billing address update failed");
        this.props.onGetClientProfile(clientprofileid);
      });
  };

  onProfileTopUpdate = (toggleName) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.setState({ loadClient: true });
    const userId = localStorage.getItem("userId");
    let data = {
      id: this.state.profileDataState.id,
      clientNumber: this.state.profileDataState.clientNumber,
      familyId: this.state.profileDataState.familyId,
      processingGroupId: 0,
      agentId: this.state.profileDataState.agentId,
      clientTag: this.state.profileDataState.clientTag,
      firstName: this.state.profileDataState.firstName,
      lastName: this.state.profileDataState.lastName,
      middleName: this.state.profileDataState.middleName,
      title: this.state.profileDataState.title,
      gender: this.state.profileDataState.gender,
      dateOfBirth: this.state.profileDataState.dateOfBirth,
      maritalStatus: this.state.profileDataState.maritalStatus,
      dependentChildren: this.state.profileDataState.dependentChildren,
      notes: this.state.profileDataState.notes,
      occupation: this.state.profileDataState.occupation,
      occupationOrganization: this.state.profileDataState
        .occupationOrganization,
      inzUserName: this.state.profileDataState.inzUserName,
      inzPassword: this.state.profileDataState.inzPassword,
      imageBlobUrl: this.state.addClientImageUrl
        ? this.state.addClientImageUrl
        : "",
      nationalityId: this.state.profileDataState.nationalityId,
      nationalityCountry: this.state.profileDataState.nationalityCountry,
      skypeID: this.state.profileDataState.skypeID,
      preferredName: this.state.profileDataState.preferredName,
      isSubscribed: this.state.profileDataState.isSubscribed,
      arbitaryJson: this.state.profileDataState.arbitaryJson,
      dependentClientIds: this.state.profileDataState.dependentClientIds,
      currentVisaTypeId: parseInt(
        this.state.profileDataState.currentVisaTypeId
      ),
      currentNewZealandVisaExpiry: this.state.profileDataState
        .currentNewZealandVisaExpiry,
      travelConditionsValidTo: this.state.profileDataState
        .travelConditionsValidTo,
      visaText: this.state.profileDataState.visaText,
      visaDenied: this.state.profileDataState.visaDenied,
      deniedText: this.state.profileDataState.deniedText,
      clientNumberIZM: this.state.profileDataState.clientNumberIZM,
      inzFeeDate: this.state.profileDataState.inzFeeDate,
      interestedVisa: this.state.profileDataState.interestedVisa,
      memberType: this.state.profileDataState.memberType,
      clientId: this.state.profileDataState.clientId,
      nzqaOnlineSubDate: this.state.profileDataState.nzqaOnlineSubDate,
      nzqaDocumentSubDate: this.state.profileDataState.nzqaDocumentSubDate,
      nzqaDocumentRetDate: this.state.profileDataState.nzqaDocumentRetDate,
      visaCountryId: parseInt(this.state.profileDataState.visaCountryId),
      visaCountyType: parseInt(this.state.profileDataState.visaCountyType),
      age: this.state.profileDataState.age,
      jobSectorId: parseInt(this.state.profileDataState.jobSectorId),
      sourceId: parseInt(this.state.profileDataState.sourceId),
      sourceDescription: this.state.profileDataState.sourceDescription,
      clientSerial: this.state.profileDataState.clientSerial,
      companyOptional: this.state.profileDataState.companyOptional,
      dealWorth: this.state.profileDataState.dealWorth,
      saleDate: this.state.profileDataState.saleDate,
      agentUserId: this.state.profileDataState.agentUserId,
      agentUserId:
        this.state.profileDataState.agentUserId ||
        "00000000-0000-0000-0000-000000000000",
      customFields: this.state.profileDataState.customFields || [],
      clientPermission: {
        signed: this.state.signed,
        onshore: this.state.onshore,
        active: this.state.active,
        allowUpdate: this.state.allowUpdate,
        areaAccess: this.state.areaAccess,
      },
    };
    this.props
      .onUpdClientProfile(data)
      .then(() => {
        this.setState({ updLoading: false });
        message.success("Profile updated successfully");
        this.props
          .onGetClientProfile(clientprofileid)
          .then(() => {
            this.setState({ loadClient: false });
          })
          .catch(() => {
            this.setState({ loadClient: false });
          });
        if (toggleName === "onActive") {
          let userName = localStorage.getItem("userName");
          var profileData = JSON.parse(this.props.profileDataRdx);
          let active_inActive = this.state.active ? "Active" : "Inactive";
          if (profileData) {
            let myData = {
              clientName: profileData.fullName,
              logMessage:
                "Client status updated to " +
                active_inActive +
                " by " +
                userName,
              date: moment(new Date()).format("DD/MM/YYYY"),
              logType: "Client Information",
              invoiceId: "0",
            };
            activityData(myData);
          }
        }
        if (toggleName === "onSigned") {
          let userName = localStorage.getItem("userName");
          var profileData = JSON.parse(this.props.profileDataRdx);
          let signed_unSigned = this.state.signed ? "Signed" : "UnSigned";
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "Client contract SIGNED status updated as " +
              signed_unSigned +
              " by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Information",
            invoiceId: "0",
          };
          activityData(myData);
        }
        if (toggleName === "onShore") {
          let userName = localStorage.getItem("userName");
          var profileData = JSON.parse(this.props.profileDataRdx);
          let onshore_offShore = this.state.onshore ? "Onshore" : "Offshore";
          let myData = {
            clientName: profileData.fullName,
            logMessage:
              "Client status updated to " +
              onshore_offShore +
              " by " +
              userName,
            date: moment(new Date()).format("DD/MM/YYYY"),
            logType: "Client Information",
            invoiceId: "0",
          };
          activityData(myData);
        }
      })
      .catch(() => {
        this.setState({ updLoading: false, loadClient: false });
        message.error("Profile update failed");
        this.props.onGetClientProfile(clientprofileid);
      });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  uploadImage = (info, id) => {
    this.setState({ loadUploadImage: true });
    if (id) {
      this.setState({ imageUpdateId: id });
    }

    let formData = new FormData();
    formData.append("File", info);
    this.props.onUploadAvatar(formData).then(() => {
      if (this.props.imageUploadSuccess) {
        this.setState({
          addClientImageUrl: this.props.imageUploadSuccess,
          loadUploadImage: false,
        });
      }
      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(this.props.profileDataRdx);
      let myData = {
        clientName: profileData.fullName,
        logMessage: "Client profile picture uploaded by " + userName,
        date: moment(new Date()).format("DD/MM/YYYY"),
        logType: "Client Information",
        invoiceId: "0",
      };
      activityData(myData);
    });
  };

  handleChangeImage = (info) => {
    console.log("show file data ====== ", info);
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  // setShowClient = () => {
  //   this.setState({ showClient: !this.state.showClient });
  // }
  onLogout = () => {
    this.props.onUserLogout().then(() => {
      localStorage.setItem("token", null);
      this.props.history.push("/");
    });
  };

  onFinishSigned = (values) => {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    this.setState({ loadClient: true });
    let data = {
      id: clientprofileid,
      lastAgreementSigned: values.sigedAgreementDate,
    };

    this.props
      .onUpdateSignedDate(data)
      .then((res) => {
        this.setState({ signed: true, signedModalVisible: false });
        this.setState({ loadClient: false });
        this.onProfileTopUpdate();
        this.formRef.current.resetFields();
        let userName = localStorage.getItem("userName");
        var profileData = JSON.parse(this.props.profileDataRdx);
        let signed_unSigned = this.state.signed ? "enabled" : "disabled";
        let myData = {
          clientName: profileData.fullName,
          logMessage:
            "Client contract SIGNED status " +
            moment(values.sigedAgreementDate).format("DD/MM/YYYY") +
            " " +
            signed_unSigned +
            " by " +
            userName,
          date: moment(new Date()).format("DD/MM/YYYY"),
          logType: "Client Information",
          invoiceId: "0",
        };
        activityData(myData);
      })
      .catch((err) => {
        this.setState({ loadClient: false });
      });
  };

  downlaodImage = () => {
    if (this.state.addClientImageUrl) {
      saveAs(this.state.addClientImageUrl, "image.jpg"); // Put your image url here.
    } else {
      message.warning("Image not attached with this profile!");
    }
  };

  showHideContainers = (data) => {
    let contObjList = [...this.state.showContList];
    if (contObjList.length > 0) {
      let findContObjIndex = contObjList.findIndex(
        (obj) => obj.type === data.type
      );
      if (findContObjIndex > -1) {
        contObjList[findContObjIndex].status = data.status;
      } else {
        contObjList.push(data);
      }
    } else {
      contObjList.push(data);
    }
    this.setState({ loadClient: true });
    const updClientProfileSetOpt = {
      url: `v1/users/ClientProfileSetting`,
    };
    updClientProfileSetOpt.types = [
      "UPD_CLIENT_PROFILE_SETTING_SUCCESS",
      "UPD_CLIENT_PROFILE_SETTING_FAILURE",
    ];
    apiRefresh
      .put(updClientProfileSetOpt, contObjList)
      .then((res) => {
        this.setState({ loadClient: false });
        this.getProfileSettings();
        this.props.onGetClientProfile(
          sessionStorage.getItem("clientprofileid")
        );
      })
      .catch((err) => {
        this.setState({ loadClient: false });
      });
  };

  render() {
    const {
      selectedOption,
      signed,
      onshore,
      active,
      allowUpdate,
      areaAccess,
      updLoading,
      addClientImageUrl,
      showClient,
      visible,
      loadClient,
      signedModalVisible,
      headerOptions,
      showDetail,
      selectedQuestionniareId,
      showContList,
      profileDataState,
      branchProfileSetting,
    } = this.state;

    const {
      countriesData,
      groupsData,
      teamMembers,
      accessingAuthData,
      groupMembersData,
      visaTypeData,
      visaStatusData,
      onUpdateCaseStatus,
      onGetVisaApplication,
      visaAppData,
      onSendEmailLink,
      cmvLinkTemplateData,
      employerJobHistoryCurrentRes,
      onSetActiveKey,
      activeKey,
      clientSourceListing,
      onAddPoliceCertificate,
      onGetClientProfile,
      onAddAccAuthorities,
      onSearchClient,
      searchClientData,
      onUpdatePriority,
      visaPriorityRes,
      onSignedVisa,
      onPaidVisa,
      remindersRes,
      onUpdateReminder,
      onGetClientTask,
      onUpdateTask,
      onGetAllUsers,
      onAddTaskFollower,
      onRemoveTasks,
      onAddDailyTasks,
      onGetClientFamily,
      onUpdateCompletedTask,
      onAddTaskFileNote,
      onAddTaskComment,
      onGetTaskComments,
      onGetTaskFollowers,
      countryVisaTypeData,
      onRemoveFollower,
      onGetReminder,
      onGetVisaStatus,

      onGetAdmissionProgram,
      onGetAdmissionStatuses,

      onGetVisaType,
      onGetVisaTypeByCountry,
      admissionStatusData,
      admissionProgramData,
      onUpdAdmissionStatus,
      onStartNewApplication,
      onAddDraftClient,
      onAddReminderTask,
      signatureRes,
      brnchVisaCountriesData,
      agentsRes,
    } = this.props;

    // console.log("================", headOption(this.props.clientTab));

    return (
      <div>
        <Spin spinning={loadClient} size="large">
          <div>
            {/* <HeaderBar branchData={userBranchData} onLogout={this.onLogout} /> */}
          </div>
          <div style={{ display: "flex" }}>
            <div>{/* <Sidebar activeScreen="Dashboard (BI)" /> */}</div>
            <div className="page-container">
              {headerOptions.length > 0 && (
                <HeaderBarTabs
                  data={headerOptions}
                  activeTab="Client Profile"
                />
              )}
              <ProfileTopBarTabs data={topBar} activeTab="CLIENT INFORMATION" />

              <div className="profile-container" style={{ marginTop: 15 }}>
                <div
                  className={
                    activeKey
                      ? "content-width-open-sidebar"
                      : "content-width-close-sidebar"
                  }
                >
                  <Modal
                    className="reminder-model-main width-modal-outgoing-payments"
                    title="AGREEMENT SIGNED"
                    visible={signedModalVisible}
                    onCancel={() =>
                      this.setState({ signedModalVisible: false })
                    }
                    footer={null}
                    maskClosable={false}
                  >
                    <Form onFinish={this.onFinishSigned} ref={this.formRef}>
                      <div
                        className="form-container"
                        style={{
                          display: "flex",
                          marginTop: 10,
                          justifyContent: "space-between",
                          padding: 20,
                          alignItems: "center",
                        }}
                      >
                        <p className="medical-label">Date</p>
                        <div
                          style={{
                            display: "flex",
                            border: "none",
                            width: "48%",
                          }}
                        >
                          <Form.Item
                            name="sigedAgreementDate"
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message: "Required!",
                              },
                            ]}
                          >
                            <DatePicker format={dateFormat} />
                          </Form.Item>
                        </div>
                      </div>
                      <Row>
                        <Col xs={4} offset={18} style={{ display: "flex" }}>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              style={{ marginRight: "10px" }}
                            >
                              Save
                            </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="primary"
                              onClick={() =>
                                this.setState({ signedModalVisible: false })
                              }
                            >
                              Close
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Modal>
                  {profileDataState && (
                    <div>
                      <div className="profile-first-box">
                        <div>
                          <div
                            style={{ flexDirection: "row", display: "flex" }}
                          >
                            <div className="profile-cont-left">
                              <div className="profile-img-cont ant-upload-profile">
                                <Upload
                                  name="avatar"
                                  listType="picture-card"
                                  className="avatar-uploader ant-upload-profile"
                                  showUploadList={false}
                                  action={this.uploadImage}
                                  onChange={this.handleChangeImage}
                                >
                                  <Spin spinning={this.state.loadUploadImage}>
                                    {addClientImageUrl ? (
                                      <img
                                        src={addClientImageUrl}
                                        alt="avatar"
                                        style={{ width: 105, height: 105 }}
                                      />
                                    ) : (
                                      <img
                                        src={Images.dummyUserImage}
                                        className="profile-img"
                                      />
                                    )}
                                  </Spin>
                                </Upload>
                              </div>
                              <h3
                                style={{
                                  textAlign: "center",
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                }}
                              >
                                {profileDataState
                                  ? profileDataState.firstName +
                                    " " +
                                    profileDataState.lastName
                                  : ""}
                              </h3>
                              <h5>
                                {!profileDataState
                                  ? ""
                                  : profileDataState.visaCountyType === 1
                                  ? "STUDENT"
                                  : profileDataState.visaCountyType === 2
                                  ? "VISA"
                                  : profileDataState.visaCountyType === 3 &&
                                    "UNSUCCESSFULL"}
                              </h5>
                              <div style={{ display: "flex", marginTop: 15 }}>
                                <Tooltip
                                  title="Save Profile Picture"
                                  style={{ display: "inline-block" }}
                                >
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      marginRight: 5,
                                    }}
                                    onClick={() => {
                                      this.onProfileTopUpdate();
                                    }}
                                  >
                                    <CheckOutlined
                                      style={{ color: "#56B1F9" }}
                                    />
                                  </div>
                                </Tooltip>

                                <Tooltip
                                  title="Remove Profile Picture"
                                  style={{ display: "inline-block" }}
                                >
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.setState({ addClientImageUrl: "" });
                                      setTimeout(() => {
                                        this.onProfileTopUpdate();
                                      }, 500);
                                    }}
                                  >
                                    <img
                                      src={Images.cross}
                                      style={{ width: 13, height: 13 }}
                                    />
                                  </div>
                                </Tooltip>

                                <Tooltip
                                  title="Download Profile Picture"
                                  style={{ display: "inline-block" }}
                                >
                                  <div
                                    style={{
                                      marginLeft: 10,
                                      cursor: "pointer",
                                    }}
                                    onClick={this.downlaodImage}
                                  >
                                    <img
                                      src={Images.download}
                                      className="svg-img"
                                    />
                                  </div>
                                </Tooltip>
                                {/* <div style={{ marginLeft: 10 }}>
                                  <img
                                    src={Images.multimediaOption}
                                    className="svg-img"
                                  />
                                </div> */}
                              </div>
                            </div>
                            <div className="right-cont">
                              <div className="profile-puq-cont">
                                <div
                                  className="profile-print-box"
                                  style={{ width: 26, height: 26 }}
                                >
                                  <img
                                    src={Images.printWhite}
                                    className="profile-print-icon"
                                  />
                                </div>
                                <div style={{ display: "flex" }}>
                                  {/* <div
                                    className="profile-updbtn-cont"
                                    style={{
                                      paddingLeft: 10,
                                      cursor: "pointer",
                                      height: 26,
                                    }}
                                    onClick={this.onProfileTopUpdate}
                                  >
                                    <span className="profile-updbtn-text">
                                      UPDATE
                                    </span>
                                    <img
                                      src={Images.updateWhite}
                                      style={{
                                        width: 10,
                                        height: 10,
                                        marginLeft: 8,
                                      }}
                                    />
                                    </div>*/}
                                  <div
                                    className="profile-updbtn-cont"
                                    style={{
                                      marginLeft: 5,
                                      backgroundColor: "#0F7EB6",
                                      border: 1,
                                      borderStyle: "solid",
                                      borderColor: "#0F7EB6",
                                      height: 26,
                                    }}
                                  >
                                    <span className="profile-updbtn-text">
                                      QUESTIONNAIRE
                                    </span>
                                    <Dropdown
                                      placement="bottomRight"
                                      overlay={menu}
                                      trigger={["click"]}
                                    >
                                      <a
                                        className="ant-dropdown-link"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: 20,
                                          height: 20,
                                        }}
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <img
                                          src={Images.rightArrow}
                                          style={{
                                            width: 10,
                                            height: 10,
                                            transform: `rotate(90deg)`,
                                          }}
                                        />
                                      </a>
                                    </Dropdown>
                                    {/* <img
                                      src={Images.rightArrow}
                                      style={{
                                        transform: `rotate(90deg)`,
                                        width: 10,
                                        height: 10,
                                        marginLeft: 3,
                                      }}
                                    /> */}
                                  </div>
                                </div>
                              </div>
                              <div className="date-div">
                                <div>
                                  <span className="date-text">
                                    Created On:{" "}
                                    {profileDataState
                                      ? moment(
                                          profileDataState.createdDate
                                        ).format("DD/MM/YYYY")
                                      : ""}
                                  </span>
                                </div>
                                <div>
                                  <span className="date-text">
                                    Modified On:{" "}
                                    {profileDataState &&
                                      moment(
                                        profileDataState.modifiedDate
                                      ).format("DD/MM/YYYY")}
                                  </span>
                                </div>
                              </div>
                              <div className="lv-main-cont">
                                <div className="label-value-cont">
                                  <div className="label-cont">
                                    <span className="label-text">EZM ID</span>
                                  </div>
                                  <div className="value-cont">
                                    <span className="value-text">
                                      {profileDataState
                                        ? profileDataState.clientNumberIZM
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="label-value-cont">
                                  <div className="label-cont">
                                    <span className="label-text">
                                      Client ID
                                    </span>
                                  </div>
                                  <div className="value-cont">
                                    <span className="value-text">
                                      {profileDataState
                                        ? profileDataState.clientNumber
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="label-value-cont">
                                  <div
                                    className="label-cont"
                                    style={{ marginLeft: 0.5 }}
                                  >
                                    <span className="label-text">DOB</span>
                                  </div>
                                  <div className="value-cont">
                                    <span className="value-text">
                                      {profileDataState &&
                                      profileDataState.dateOfBirth !==
                                        "1900-01-01T00:00:00+00:00"
                                        ? moment(
                                            profileDataState.dateOfBirth
                                          ).format("DD/MM/YYYY")
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="buttons-row">
                                <div>
                                  <div className="black-button">
                                    <span className="black-button-text">
                                      Signed
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      signed
                                        ? "right-green-btn-cont"
                                        : "cross-cont"
                                    }
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (!signed) {
                                        this.setState({
                                          signedModalVisible: true,
                                        });
                                      } else {
                                        this.setState({ signed: !signed });
                                        setTimeout(() => {
                                          this.onProfileTopUpdate("onSigned");
                                        }, 500);
                                      }
                                    }}
                                  >
                                    {signed && (
                                      <img
                                        src={Images.tickWhite}
                                        className="svg-btn-img"
                                        style={{ marginRight: 10 }}
                                      />
                                    )}
                                    <img
                                      src={Images.btnImage}
                                      className="profile-btn-img"
                                    />
                                    {!signed && (
                                      <img
                                        src={Images.crossWhite}
                                        className="svg-btn-img"
                                        style={{ marginLeft: 10 }}
                                      />
                                    )}
                                  </div>
                                  <div></div>
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                  <div className="black-button">
                                    <span className="black-button-text">
                                      Onshore
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      onshore
                                        ? "right-green-btn-cont"
                                        : "cross-cont"
                                    }
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.setState({ onshore: !onshore });
                                      setTimeout(() => {
                                        this.onProfileTopUpdate("onShore");
                                      }, 500);
                                    }}
                                  >
                                    {onshore && (
                                      <img
                                        src={Images.tickWhite}
                                        className="svg-btn-img"
                                        style={{ marginRight: 10 }}
                                      />
                                    )}
                                    <img
                                      src={Images.btnImage}
                                      className="profile-btn-img"
                                    />
                                    {!onshore && (
                                      <img
                                        src={Images.crossWhite}
                                        className="svg-btn-img"
                                        style={{ marginLeft: 10 }}
                                      />
                                    )}
                                  </div>
                                  <div></div>
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                  <div className="black-button">
                                    <span className="black-button-text">
                                      Active
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      active
                                        ? "right-green-btn-cont"
                                        : "cross-cont"
                                    }
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.setState({ active: !active });
                                      setTimeout(() => {
                                        this.onProfileTopUpdate("onActive");
                                      }, 500);
                                    }}
                                  >
                                    {active && (
                                      <img
                                        src={Images.tickWhite}
                                        className="svg-btn-img"
                                        style={{ marginRight: 10 }}
                                      />
                                    )}
                                    <img
                                      src={Images.btnImage}
                                      className="profile-btn-img"
                                    />
                                    {!active && (
                                      <img
                                        src={Images.crossWhite}
                                        className="svg-btn-img"
                                        style={{ marginLeft: 10 }}
                                      />
                                    )}
                                  </div>
                                  <div></div>
                                </div>
                              </div>

                              {profileDataState.agentUserName && (
                                <div>
                                  <div className="agent-tag-cont">
                                    <div className="agent-tag">
                                      <span className="tag-text">
                                        Agent Name:{" "}
                                        {profileDataState.agentUserName}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <ProcessingPerson
                        clientInfo={profileDataState}
                        groupsData={groupsData}
                        personsData={teamMembers}
                        onAddProcessingGroup={this.addProcessingGroup}
                        onRemoveProcessingGroup={this.removeProcessingGroup}
                        onAddProcessingPerson={this.addProcessingPerson}
                        onRemoveProcessingPerson={this.removeProcessingPerson}
                        groupMembers={groupMembersData}
                        users={this.state.users}
                        onGetGroupMembers={this.props.onGetGroupMembers}
                      />
                      <Connections
                        clientInfo={profileDataState}
                        onSearchConnection={this.searchConnection}
                        searchedConnections={searchClientData}
                        onAddConnection={this.addConnection}
                        onRemoveConnection={this.removeConnection}
                        onSearchClient={onSearchClient}
                        history={this.props.history}
                        onGetClientProfile={onGetClientProfile}
                      />
                      <div
                        className="profile-additional-box"
                        style={{ paddingBottom: 50 }}
                      >
                        {branchProfileSetting &&
                        branchProfileSetting.length > 0 &&
                        !branchProfileSetting.find(
                          (obj) => obj.type === "Personal Information"
                        ).status ? (
                          showContList.length > 0 &&
                          showContList.find(
                            (obj) => obj.type === "personal_information"
                          ) &&
                          showContList.find(
                            (obj) => obj.type === "personal_information"
                          ).status ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginRight: 10,
                                padding: 18,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className="profile-down-arrow-cont"
                                  style={{ marginTop: 5, cursor: "pointer" }}
                                  onClick={() =>
                                    this.showHideContainers({
                                      type: "personal_information",
                                      status: false,
                                    })
                                  }
                                >
                                  <img
                                    src={Images.whiteArrow}
                                    className="profile-down-arrow-icon"
                                    style={{ transform: "rotate(270deg)" }}
                                  />
                                </div>
                                <p className="label">Personal Information</p>
                              </div>
                            </div>
                          ) : (
                            <PersonalInformation
                              clientInfo={profileDataState}
                              countriesData={countriesData}
                              accessingAuthData={accessingAuthData}
                              visaTypeData={visaTypeData}
                              updatePersonalInfo={this.personalInfoUpdate}
                              clientSourceListing={clientSourceListing}
                              personsData={teamMembers}
                              brnchVisaCountriesData={brnchVisaCountriesData}
                              history={this.props.history}
                              users={this.state.users}
                              agentsRes={agentsRes}
                              onGetClientProfile={onGetClientProfile}
                              showHideContainers={this.showHideContainers}
                            />
                          )
                        ) : null}
                        {branchProfileSetting &&
                        branchProfileSetting.length > 0 &&
                        !branchProfileSetting.find(
                          (obj) => obj.type === "Current Visa Detail"
                        ).status ? (
                          showContList.length > 0 &&
                          showContList.find(
                            (obj) => obj.type === "current_visa_detail"
                          ) &&
                          showContList.find(
                            (obj) => obj.type === "current_visa_detail"
                          ).status ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginRight: 10,
                                padding: 18,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip title="Show Container Fields">
                                  <div
                                    className="profile-down-arrow-cont"
                                    style={{ marginTop: 5, cursor: "pointer" }}
                                    onClick={() =>
                                      this.showHideContainers({
                                        type: "current_visa_detail",
                                        status: false,
                                      })
                                    }
                                  >
                                    <img
                                      src={Images.whiteArrow}
                                      className="profile-down-arrow-icon"
                                      style={{ transform: "rotate(270deg)" }}
                                    />
                                  </div>
                                </Tooltip>
                                <p className="label">Current Visa Detail</p>
                              </div>
                            </div>
                          ) : (
                            <CurrentVisa
                              clientInfo={profileDataState}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileCurrentVisa={
                                this.onUpdateCurrentVisa
                              }
                              onGetClientProfile={onGetClientProfile}
                              showHideContainers={this.showHideContainers}
                            />
                          )
                        ) : null}
                        {branchProfileSetting &&
                        branchProfileSetting.length > 0 &&
                        !branchProfileSetting.find(
                          (obj) => obj.type === "Medical Detail"
                        ).status ? (
                          showContList.length > 0 &&
                          showContList.find(
                            (obj) => obj.type === "medical_detail"
                          ) &&
                          showContList.find(
                            (obj) => obj.type === "medical_detail"
                          ).status ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginRight: 10,
                                padding: 18,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip title="Show Container Fields">
                                  <div
                                    className="profile-down-arrow-cont"
                                    style={{ marginTop: 5, cursor: "pointer" }}
                                    onClick={() =>
                                      this.showHideContainers({
                                        type: "medical_detail",
                                        status: false,
                                      })
                                    }
                                  >
                                    <img
                                      src={Images.whiteArrow}
                                      className="profile-down-arrow-icon"
                                      style={{ transform: "rotate(270deg)" }}
                                    />
                                  </div>
                                </Tooltip>
                                <p className="label">Medical Detail</p>
                              </div>
                            </div>
                          ) : (
                            <Medicals
                              clientInfo={profileDataState}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileMedicals={this.onUpdateMedicals}
                              onGetClientProfile={onGetClientProfile}
                              showHideContainers={this.showHideContainers}
                            />
                          )
                        ) : null}

                        <Passport
                          clientInfo={profileDataState}
                          countriesData={countriesData}
                          onAddCertificate={onAddPoliceCertificate}
                          onAddAuthority={onAddAccAuthorities}
                          onRemoveCertificate={this.removeCertificate}
                          onRemoveAuthority={this.removeAuthority}
                          accessingAuthData={accessingAuthData}
                          visaTypeData={visaTypeData}
                          updateProfilePassport={this.onUpdatePassport}
                          onGetClientProfile={onGetClientProfile}
                          onAddReminderTask={onAddReminderTask}
                          onGetProfile={this.onGetProfile}
                          showHideContainers={this.showHideContainers}
                          showContList={showContList}
                          branchProfileSetting={branchProfileSetting}
                        />

                        {branchProfileSetting &&
                        branchProfileSetting.length > 0 &&
                        !branchProfileSetting.find(
                          (obj) => obj.type === "Login Detail"
                        ).status ? (
                          showContList.length > 0 &&
                          showContList.find(
                            (obj) => obj.type === "login_detail"
                          ) &&
                          showContList.find(
                            (obj) => obj.type === "login_detail"
                          ).status ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginRight: 10,
                                padding: 18,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip title="Show Container Fields">
                                  <div
                                    className="profile-down-arrow-cont"
                                    style={{ marginTop: 5, cursor: "pointer" }}
                                    onClick={() =>
                                      this.showHideContainers({
                                        type: "login_detail",
                                        status: false,
                                      })
                                    }
                                  >
                                    <img
                                      src={Images.whiteArrow}
                                      className="profile-down-arrow-icon"
                                      style={{ transform: "rotate(270deg)" }}
                                    />
                                  </div>
                                </Tooltip>
                                <p className="label">Login Detail</p>
                              </div>
                            </div>
                          ) : (
                            <InzLogin
                              clientInfo={profileDataState}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileInzDetail={
                                this.onUpdateInzUserDetail
                              }
                              isClient={true}
                              onGetClientProfile={onGetClientProfile}
                              showHideContainers={this.showHideContainers}
                            />
                          )
                        ) : null}
                        {branchProfileSetting &&
                        branchProfileSetting.length > 0 &&
                        !branchProfileSetting.find(
                          (obj) => obj.type === "NZQA Detail"
                        ).status ? (
                          showContList.length > 0 &&
                          showContList.find(
                            (obj) => obj.type === "nzqa_detail"
                          ) &&
                          showContList.find((obj) => obj.type === "nzqa_detail")
                            .status ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginRight: 10,
                                padding: 18,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip title="Show Container Fields">
                                  <div
                                    className="profile-down-arrow-cont"
                                    style={{ marginTop: 5, cursor: "pointer" }}
                                    onClick={() =>
                                      this.showHideContainers({
                                        type: "nzqa_detail",
                                        status: false,
                                      })
                                    }
                                  >
                                    <img
                                      src={Images.whiteArrow}
                                      className="profile-down-arrow-icon"
                                      style={{ transform: "rotate(270deg)" }}
                                    />
                                  </div>
                                </Tooltip>
                                <p className="label">NZQA Detail</p>
                              </div>
                            </div>
                          ) : (
                            <NZQADetail
                              clientInfo={profileDataState}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileNZQA={this.onUpdateNZQA}
                              onGetClientProfile={onGetClientProfile}
                              showHideContainers={this.showHideContainers}
                            />
                          )
                        ) : null}
                        {branchProfileSetting &&
                        branchProfileSetting.length > 0 &&
                        !branchProfileSetting.find(
                          (obj) => obj.type === "Billing Address"
                        ).status ? (
                          showContList.length > 0 &&
                          showContList.find(
                            (obj) => obj.type === "billing_address"
                          ) &&
                          showContList.find(
                            (obj) => obj.type === "billing_address"
                          ).status ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginRight: 10,
                                padding: 18,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip title="Show Container Fields">
                                  <div
                                    className="profile-down-arrow-cont"
                                    style={{ marginTop: 5, cursor: "pointer" }}
                                    onClick={() =>
                                      this.showHideContainers({
                                        type: "billing_address",
                                        status: false,
                                      })
                                    }
                                  >
                                    <img
                                      src={Images.whiteArrow}
                                      className="profile-down-arrow-icon"
                                      style={{ transform: "rotate(270deg)" }}
                                    />
                                  </div>
                                </Tooltip>
                                <p className="label">Billing Address</p>
                              </div>
                            </div>
                          ) : (
                            <BillingAddress
                              clientInfo={profileDataState}
                              countriesData={countriesData}
                              visaTypeData={visaTypeData}
                              updateProfileBillingAddress={
                                this.onUpdateBillingAddress
                              }
                              onGetClientProfile={onGetClientProfile}
                              showHideContainers={this.showHideContainers}
                            />
                          )
                        ) : null}
                        <div
                          className="denied-cont"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div className="denied-cont">
                            <span className="denied-text">
                              Client Area Access
                            </span>
                          </div>
                          {/* <Button
                          loading={updLoading}
                          className="button-blue"
                          onClick={this.onUpdateNZQA}
                        >
                          <span style={{ color: "#FFFFFF" }}>Update</span>
                        </Button> */}
                        </div>
                        <div className="form-container">
                          <div
                            className="buttons-row"
                            style={{ paddingTop: 20, marginBottom: 0 }}
                          >
                            {/*<div>
                            <div className="black-button" style={{ width: 95 }}>
                              <span
                                className="black-button-text"
                                style={{ fontSize: 8 }}
                              >
                                Allow Update Partner
                              </span>
                            </div>
                            <div className="cross-cont" style={{ width: 95 }}>
                              <img src={Images.btnImage} className="profile-btn-img" />
                              <img
                                src={Images.crossWhite}
                                className="svg-btn-img"
                                style={{ paddingLeft: 28, paddingRight: 28 }}
                              />
                            </div>
                            <div></div>
                          </div>*/}
                            <div style={{ marginLeft: 10 }}>
                              <div
                                className="black-button"
                                style={{ width: 95 }}
                              >
                                <span
                                  className="black-button-text"
                                  style={{ fontSize: 8 }}
                                >
                                  Allow Update
                                </span>
                              </div>
                              <div
                                className={
                                  allowUpdate
                                    ? "right-green-btn-cont"
                                    : "cross-cont"
                                }
                                style={{ width: 95, cursor: "pointer" }}
                                onClick={() => {
                                  this.setState({ allowUpdate: !allowUpdate });
                                  setTimeout(() => {
                                    this.onProfileTopUpdate();
                                  }, 500);
                                }}
                              >
                                {allowUpdate && (
                                  <img
                                    src={Images.tickWhite}
                                    className="svg-btn-img"
                                    style={{ marginRight: 20 }}
                                  />
                                )}
                                <img
                                  src={Images.btnImage}
                                  className="profile-btn-img"
                                />
                                {!allowUpdate && (
                                  <img
                                    src={Images.crossWhite}
                                    className="svg-btn-img"
                                    style={{ marginLeft: 20 }}
                                  />
                                )}
                              </div>
                              <div></div>
                            </div>
                            <div style={{ marginLeft: 10 }}>
                              <div
                                className="black-button"
                                style={{ width: 95 }}
                              >
                                <span
                                  className="black-button-text"
                                  style={{ fontSize: 8 }}
                                >
                                  Area Access
                                </span>
                              </div>
                              <div
                                className={
                                  areaAccess
                                    ? "right-green-btn-cont"
                                    : "cross-cont"
                                }
                                style={{ width: 95, cursor: "pointer" }}
                                onClick={() => {
                                  var isPrimaryEmail =
                                    profileDataState &&
                                    profileDataState.emails.find(
                                      (obj) =>
                                        obj.emailTypeId === 1 &&
                                        obj.address !== ""
                                    );
                                  if (isPrimaryEmail) {
                                    this.setState({ areaAccess: !areaAccess });
                                    if (!areaAccess) {
                                      this.setState({ visible: true });
                                    }
                                    setTimeout(() => {
                                      this.onProfileTopUpdate();
                                    }, 500);
                                  } else {
                                    this.setState({ areaAccess: false });
                                    message.error(
                                      "Please fill primary email field!"
                                    );
                                  }
                                }}
                              >
                                {areaAccess && (
                                  <img
                                    src={Images.tickWhite}
                                    className="svg-btn-img"
                                    style={{ marginRight: 20 }}
                                  />
                                )}
                                <img
                                  src={Images.btnImage}
                                  className="profile-btn-img"
                                />
                                {!areaAccess && (
                                  <img
                                    src={Images.crossWhite}
                                    className="svg-btn-img"
                                    style={{ marginLeft: 20 }}
                                  />
                                )}
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                        <Modal
                          visible={showDetail}
                          onCancel={() => this.setState({ showDetail: false })}
                          footer={null}
                          width="80%"
                        >
                          <>
                            <LinkQuestionnaire
                              questionnaireId={selectedQuestionniareId}
                              // onGetDynamicLink={onGetDynamicLink}
                              // onGetCountries={getCountries}
                              // onSubmitQuestionnaire={onSubmitQuestionnaire}
                              // onGetQuestionnaire={onGetQuestionnaire}
                              // onGetFilledQuestionnaire={
                              //   onGetFilledQuestionnaire
                              // }
                              // dynamicLinkData={dynamicLinkData}
                              // countriesData={allCountriesData}
                              // questionnaireData={questionnaireData}
                              // filledQuestionnaireRes={filledQuestionnaireRes}
                              notLink={true}
                              clientprofileid={sessionStorage.getItem(
                                "clientprofileid"
                              )}
                              isPotential={true}
                            />
                          </>
                        </Modal>

                        {visible && (
                          <ClientAreaEmail
                            handleCancel={this.handleCancel}
                            profileData={profileDataState}
                            visible={visible}
                            emailContent={
                              cmvLinkTemplateData &&
                              cmvLinkTemplateData.htmlTemplate
                            }
                            signatureText={
                              signatureRes && signatureRes.signatureText
                            }
                            onGetEmailContent={
                              this.props && this.props.onGetEmailContent
                            }
                            onSendEmail={onSendEmailLink}
                            onAddDraftClient={onAddDraftClient}
                          />
                        )}
                        {/*<div
                        className="button-blue-cont"
                        style={{ marginTop: 23, marginLeft: 10 }}
                      >
                        <Form.Item>
                          <Button className="button-blue" htmlType="submit">
                            <span style={{ color: "#FFFFFF" }}>Update</span>
                          </Button>
                        </Form.Item>
                      </div>*/}
                      </div>
                    </div>
                  )}
                  {/*<div
                    style={{
                      float: "left",
                      position: "fixed",
                      left: 250,
                      bottom: 30
                    }}
                  >
                      <Button
                        loading={updLoading}
                        className="button-blue"
                        htmlType="submit"
                      >
                        <span style={{ color: "#FFFFFF" }}>Update</span>
                      </Button>
                  </div>*/}
                </div>
                <div
                  className=""
                  style={{
                    width: activeKey ? "438px" : "115px",
                    marginLeft: 20,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%", marginTop: -30 }}>
                      <ProfileSideBar
                        onGetClientTag={this.props.onGetClientTag}
                        clientTagRes={this.props.clientTagRes}
                        getClientRes={this.props.getClientRes}
                        onGetProfileClientTag={this.props.onGetProfileClientTag}
                        onAddProfileClientTag={this.props.onAddProfileClientTag}
                        addProfielTagRes={this.props.addProfielTagRes}
                        onRemoveProfileClientTag={
                          this.props.onRemoveProfileClientTag
                        }
                        removeProfileTagRes={this.props.removeProfileTagRes}
                        visaStatusData={visaStatusData}
                        onUpdateCaseStatus={onUpdateCaseStatus}
                        visaAppData={visaAppData}
                        onGetVisaApplication={onGetVisaApplication}
                        employerJobHistoryCurrentRes={
                          employerJobHistoryCurrentRes
                        }
                        onSetActiveKey={onSetActiveKey}
                        activeKey={activeKey}
                        onUpdatePriority={onUpdatePriority}
                        visaPriorityRes={visaPriorityRes}
                        onSignedVisa={onSignedVisa}
                        onPaidVisa={onPaidVisa}
                        remindersRes={remindersRes && remindersRes.items}
                        onUpdateReminder={onUpdateReminder}
                        onGetClientTask={onGetClientTask}
                        onUpdateTask={onUpdateTask}
                        onGetAllUsers={onGetAllUsers}
                        onAddTaskFollower={onAddTaskFollower}
                        onRemoveTasks={onRemoveTasks}
                        onAddDailyTasks={onAddDailyTasks}
                        onGetClientFamily={onGetClientFamily}
                        onUpdateCompletedTask={onUpdateCompletedTask}
                        onAddTaskFileNote={onAddTaskFileNote}
                        onAddTaskComment={onAddTaskComment}
                        onGetTaskComments={onGetTaskComments}
                        onGetTaskFollowers={onGetTaskFollowers}
                        visaTypeData={countryVisaTypeData}
                        onRemoveFollower={onRemoveFollower}
                        onLoadClient={loadClient}
                        onGetReminder={onGetReminder}
                        onGetVisaStatus={onGetVisaStatus}
                        onGetAdmissionProgram={onGetAdmissionProgram}
                        onGetAdmissionStatuses={onGetAdmissionStatuses}
                        onGetVisaType={onGetVisaType}
                        onGetVisaTypeByCountry={onGetVisaTypeByCountry}
                        admissionStatusData={admissionStatusData}
                        admissionProgramData={admissionProgramData}
                        onUpdAdmissionStatus={onUpdAdmissionStatus}
                        onStartNewApplication={onStartNewApplication}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default Profile;
