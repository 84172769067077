// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import {
  message,
  Spin,
  Button,
  Upload,
  Select,
  Form,
  Modal,
  Tooltip,
  Input,
  Row,
  Col,
  DatePicker,
} from "antd";
import { CloseOutlined, LeftCircleFilled } from "@ant-design/icons";
import { apiRefresh } from "../../services/api";
var notSearch = true;
function AddCustomFields(Props) {
  const [show, setShow] = useState(false);
  // const [name, setName] = useState(Props.manager.fullName);
  // const [email, setEmail] = useState(Props.manager.alternateEmail);

  const [connection, setConnection] = useState("");
  const [searchedConnections, setSearchedConnections] = useState("");
  const [connectionsData, setConnectionsData] = useState("");
  const [relation, setRelation] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [answerTypeOptions, setAnswerTypeOptions] = useState([]);
  const [typesArray, setTypesArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fieldType, setFieldType] = useState("1");

  const [form] = Form.useForm();

  useEffect(() => {
    var answerTypes = [
      { value: "1", label: "Textbox" },
      // { value: "2", label: "Date" },
      { value: "3", label: "Textarea" },
    ];
    var types = [];
    for (var i = 0; i < answerTypes.length; i++) {
      types.push(
        <Option value={answerTypes[i].value}>{answerTypes[i].label}</Option>
      );
    }
    setTypesArray(answerTypes);
    setAnswerTypeOptions(types);
  }, []);

  const userOwner = localStorage.getItem("userOwner");

  const { Option } = Select;
  const connectionsList = [];

  //   if (
  //     searchedConnections &&
  //     searchedConnections.clients &&
  //     searchedConnections.clients.length > 0
  //   ) {
  //     for (let i = 0; i < searchedConnections.clients.length; i++) {
  //       connectionsList.push(
  //         <Option key={searchedConnections.clients[i].id}>
  //           {searchedConnections.clients[i].firstName +
  //             " " +
  //             searchedConnections.clients[i].lastName}
  //         </Option>
  //       );
  //     }
  //   }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    let data = {
      fieldType: values.fieldType || "",
      fieldName: values.fieldName || "",
      fieldData: values.fieldData || "",
      fieldFor: values.fieldFor || "",
    };
    const addCustomFieldOpt = {
      url: `v1/branch/CustomFeild`,
    };
    addCustomFieldOpt.types = [
      "ADD_CUSTOM_FIELD_SUCCESS",
      "ADD_CUSTOM_FIELD_FAILURE",
    ];
    setLoading(true);
    apiRefresh
      .post(addCustomFieldOpt, data)
      .then((res) => {
        setLoading(false);
        // Props.onGetClientProfile(sessionStorage.getItem("clientprofileid"));
        form.resetFields();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div
        className="d-flex align-item Reminder-top"
        style={{ justifyContent: "space-between", paddingBottom: 20 }}
      >
        <div className="d-flex align-item">
          <div className="client-tag-top">
            <img src={Images.documentSync} className="sus-bottom-icon" />
          </div>
          <span className="top-text">Client Profile Custom Fields</span>
        </div>

        <LeftCircleFilled
          onClick={() => Props.onSetActiveInnerTab("")}
          className="ac-back-icon"
        />
      </div>
      {/* <Modal
        title="Add Custom Field"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="SAVE"
        footer={false}
      > */}
      <Form
        // {...layoutSendingReceipt}
        onFinish={onFinish}
        form={form}
        style={{ padding: 40 }}
      >
        {/* {this.getAddBankForm(this.props)} */}

        <Row>
          <Col xs={24}>
            <div style={{ display: "flex" }}>
              <div
                className="label-form"
                style={{
                  marginRight: "6px",
                  width: "30%",
                }}
              >
                Field For<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name={"fieldFor"}
                style={{ width: "70%" }}
                rules={[{ required: true, message: "Required" }]}
              >
                <Select showSearch optionFilterProp="children">
                  <Option value="personal_information">
                    Personal Information
                  </Option>
                  <Option value="current_visa_detail">
                    Current Visa Detail
                  </Option>
                  <Option value="Medical_detail">Medical Detail</Option>
                  <Option value="passport_detail">Passport Detail</Option>
                  <Option value="second_passport_detail">
                    Secondary Passport Detail
                  </Option>
                  <Option value="login_detail">Login Detail</Option>
                  <Option value="nzqa_detail">NZQA Detail</Option>
                  <Option value="billing_address">Billing Address</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div style={{ display: "flex" }}>
              <div
                className="label-form"
                style={{
                  marginRight: "6px",
                  width: "30%",
                }}
              >
                Field Type<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name={"fieldType"}
                style={{ width: "70%" }}
                rules={[{ required: true, message: "Required" }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(e) => {
                    setFieldType(e);
                  }}
                >
                  {answerTypeOptions}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div style={{ display: "flex" }}>
              <div
                className="label-form"
                style={{
                  marginRight: "6px",
                  width: "30%",
                }}
              >
                Field Name<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name={"fieldName"}
                style={{ width: "70%" }}
                rules={[{ required: true, message: "Required" }]}
              >
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div style={{ display: "flex" }}>
              <div
                className="label-form"
                style={{
                  marginRight: "6px",
                  width: "30%",
                }}
              >
                Field Data
              </div>

              {fieldType == "1" ? (
                <Form.Item name={"fieldData"} style={{ width: "70%" }}>
                  <Input />
                </Form.Item>
              ) : fieldType == "2" ? (
                <Form.Item name={"fieldData"} style={{ width: "70%" }}>
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              ) : (
                fieldType == "3" && (
                  <Form.Item name={"fieldData"} style={{ width: "70%" }}>
                    <Input.TextArea rows={4} />
                  </Form.Item>
                )
              )}
            </div>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Col xs={4} style={{ display: "flex", marginTop: 20 }}>
            <Form.Item>
              <Button
                type="primary"
                className={"button-blue"}
                htmlType="submit"
                style={{ marginRight: "10px" }}
                loading={loading}
                // disabled={this.state.isButtonDisabled}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* </Modal> */}
    </div>
  );
}

export default AddCustomFields;
