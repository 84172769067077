import React from "react";
import {
  message,
  Checkbox,
  Spin,
  Modal,
  Select,
  Row,
  Col,
  Button,
  Tabs,
} from "antd";

import "./QuestionnaireStyles.css";
import HeaderBar from "../Components/Header/HeaderBar";

import Sidebar from "../Components/SideBar";
import { Images } from "../Themes";
import { apiRefresh } from "../services/api";

var optionsArray = [];
var optionsSectionArray = [];

const { Option } = Select;
const { TabPane } = Tabs;

// const baseURL = "https://beta-api.ezymigrate.co.nz/";
// const baseURL = "https://linuxapi-stage.ezymigrate.co.nz/";
const baseURL = "https://uatapi.ezymigrate.co.nz/";

const answersApiData = {
  questionnaireId: 0,
  branchId: localStorage.getItem("selectedBranchId"),
  clientName: "",
  createdBy: localStorage.getItem("userId"),
  answers: [
    {
      questionId: 0,
      answer: "",
    },
  ],
  position: 0,
  count: 0,
};

class QuestionnaireList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      checked: false,
      show: false,
      loading: true,
      imageUpdateId: "",
      loadUploadImage: false,
      imageUrl: "",
      addUserImageUrl: "",
      exportLoading: false,

      fullName: "",
      email: "",
      password: "",

      questionNumbers: 1,
      clickedButton: "",
      modal1Visible: false,
      questionnaireId: null,
      activeTab: "customQuestionnaire",
      visaFormArray: [],
      isGrouped: 0,
      visaFormQuestionnaireList: [],
      groupedQuestionnaire: [],
      visibleModalDuplicate: false,
      publicApiRes: "",
      modal2Visible: false,
      answersApiRes: "",
      handleKey: "1",
      questionnairePopupId: 0,
    };

    const hubspotOnOpt = {
      url: `v1/hubspot/HubSpotON`,
    };
    hubspotOnOpt.types = ["GET_HUBSPOT_ON_SUCCESS", "GET_HUBSPOT_ON_FAILURE"];

    apiRefresh
      .get(hubspotOnOpt)
      .then((response) => {
        if (response.isHubSpotON) {
          const hubspotFormsOpt = {
            url: `v1/hubspot/forms`,
          };
          hubspotFormsOpt.types = [
            "GET_HUBSPOT_FORMS_SUCCESS",
            "GET_HUBSPOT_FORMS_FAILURE",
          ];

          apiRefresh.get(hubspotFormsOpt).then((res) => {
            this.props
              .onGetAllQuestionnaire()
              .then((res) => {
                this.setState({ loading: false });
              })
              .catch(() => {
                this.setState({ loading: false });
              });
          });
        } else {
          this.props
            .onGetAllQuestionnaire()
            .then((res) => {
              this.setState({ loading: false });
            })
            .catch(() => {
              this.setState({ loading: false });
            });
        }
      })
      .catch(() => {
        this.props
          .onGetAllQuestionnaire()
          .then((res) => {
            this.setState({ loading: false });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.questionnaireGroupRes != this.props.questionnaireGroupRes) {
      var visaForms = [];
      for (var i = 0; i < this.props.questionnaireGroupRes.length; i++) {
        visaForms.push(
          <Option key={this.props.questionnaireGroupRes[i].id}>
            {this.props.questionnaireGroupRes[i].name}
          </Option>
        );
      }

      this.setState({ visaFormArray: visaForms });
    }
    if (
      prevProps.groupedQuestionnaireRes != this.props.groupedQuestionnaireRes
    ) {
      this.setState({
        groupedQuestionnaire: this.props.groupedQuestionnaireRes,
      });
    }
  }

  myChangeHandler = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChange = (value) => {
    console.log(`selected ${value}`);
  };

  onBlur = () => {
    console.log("blur");
  };

  onFocus = () => {
    console.log("focus");
  };

  onSearch = (val) => {
    console.log("search:", val);
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  addQuestion = (value) => {
    this.setState({ questionNumbers: value });
  };

  makeOptionsArray = (options) => {
    optionsArray.push(options);
  };

  makeCheckboxArray = (options) => {
    optionsArray.push(options);
  };

  makeDropdownArray = (options) => {
    optionsArray.push(options);
  };

  addedOptionSection = (sectionOptions) => {
    optionsSectionArray = sectionOptions;
  };

  createDuplicateQuestionnaire = () => {
    let data = {
      questionnaireId: this.state.questionnaireId,
    };
    this.setState({ loading: true });
    this.props
      .onCreateDuplicateQuestionnaire(data)
      .then(() => {
        message.success("Questionnaire duplicated successfully!");
        this.setState({ loading: false, visibleModalDuplicate: false });
        this.props.onGetAllQuestionnaire();
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  onDeleteQuestionnaire = (id) => {
    let data = {
      id: id,
    };
    this.setState({ loading: true });

    const deleteQuestionnaireOpt = {
      url: `v1/questionnaire`,
    };
    deleteQuestionnaireOpt.types = [
      "DELETE_QUESTIONNAIRE_SUCCESS",
      "DELETE_QUESTIONNAIRE_FAILURE",
    ];

    apiRefresh
      .delete(deleteQuestionnaireOpt, data)
      .then((res) => {
        this.props
          .onGetAllQuestionnaire()
          .then((res) => {
            this.setState({ loading: false, modal1Visible: false });
          })
          .catch(() => {
            this.setState({ loading: false, modal1Visible: false });
          });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  callback = (key) => {
    this.setState({ handleKey: key });
  };

  updCheckbox = (val, questionnaire, checkboxName) => {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    let userId = localStorage.getItem("userId");
    let data = {
      questionnaireId: questionnaire.questionnaireId,
      name: questionnaire.name,
      clientName: questionnaire.clientName || "",
      branchId: selectedBranchId,
      allowAttachments: questionnaire.allowAttachments,
      deceleration: questionnaire.deceleration,
      showInPotential:
        checkboxName == "potential"
          ? val.target.checked
          : questionnaire.showInPotential,
      showInClient:
        checkboxName == "client"
          ? val.target.checked
          : questionnaire.showInClient,
      showInPublic:
        checkboxName == "website"
          ? val.target.checked
          : questionnaire.showInPublic,
      showInEmployer:
        checkboxName == "employer"
          ? val.target.checked
          : questionnaire.showInEmployer,
      showInPublicApi:
        checkboxName == "publicApi"
          ? val.target.checked
          : questionnaire.showInPublicApi,
      showInEzyform:
        checkboxName == "ezyform"
          ? val.target.checked
          : questionnaire.showInEzyform,
      questionnaireEmail: questionnaire.questionnaireEmail,
      declarationText: questionnaire.declarationText,
      clientId: questionnaire.clientId,
      parentQuestionnaireId: questionnaire.parentQuestionnaireId,
      newQuestionnaireId: questionnaire.newQuestionnaireId,
      isPotential: questionnaire.isPotential,
      createdBy: userId,
      hasFilledAnswers: questionnaire.hasFilledAnswers || false,
    };

    this.setState({ loading: true });
    const updQuestionnaireOpt = {
      url: `v1/questionnaire/SimpleUpdate`,
    };
    updQuestionnaireOpt.types = [
      "DELETE_QUESTIONNAIRE_SUCCESS",
      "DELETE_QUESTIONNAIRE_FAILURE",
    ];

    apiRefresh
      .put(updQuestionnaireOpt, data)
      .then((res) => {
        this.props
          .onGetAllQuestionnaire()
          .then((res) => {
            this.setState({ loading: false });
          })
          .catch(() => {
            this.setState({ loading: false });
          });
        if (checkboxName == "publicApi") {
          if (val.target.checked) {
            // const getAnswersApiOpt = {
            //   url: `v1/QuestionaireOpenApi/InsertFilledAnswers`,
            // };
            // getAnswersApiOpt.types = [
            //   "GET_ANSWERS_API_SUCCESS",
            //   "GET_ANSWERS_API_FAILURE",
            // ];
            // apiRefresh.post(getAnswersApiOpt).then((res) => {
            //   this.setState({
            //     answersApiRes: JSON.parse(res.result),
            //     modal2Visible: true,
            //   });
            // });

            this.getPublicApiRes(questionnaire);
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getPublicApiRes = (questionnaire) => {
    const getPublicApiOpt = {
      url: `v1/QuestionnairePublicApi/Recursive/${questionnaire.questionnaireId}`,
    };
    getPublicApiOpt.types = [
      "GET_PUBLIC_API_SUCCESS",
      "GET_PUBLIC_API_FAILURE",
    ];
    this.setState({ loading: true });

    apiRefresh
      .get(getPublicApiOpt)
      .then((res) => {
        this.setState({
          publicApiRes: JSON.parse(res.result),
          questionnairePopupId: questionnaire.questionnaireId,
          modal2Visible: true,
          loading: false,
        });
      })
      .catch((er) => {
        this.setState({ loading: false });
      });
  };

  onChangeIsPotential = (val, questionnaire) => {
    this.updCheckbox(val, questionnaire, "potential");
  };
  onChangeIsEmployer = (val, questionnaire) => {
    this.updCheckbox(val, questionnaire, "employer");
  };
  onChangeIsClient = (val, questionnaire) => {
    this.updCheckbox(val, questionnaire, "client");
  };
  onChangeIsPublic = (val, questionnaire) => {
    this.updCheckbox(val, questionnaire, "website");
  };
  onChangeIsPublicApi = (val, questionnaire) => {
    this.updCheckbox(val, questionnaire, "publicApi");
  };
  onChangeEzyform = (val, questionnaire) => {
    this.updCheckbox(val, questionnaire, "ezyform");
  };

  onChangeTab = (tabName) => {
    this.setState({ loading: true });
    this.setState({ activeTab: tabName });
    if (tabName == "visaForm") {
      this.props
        .onGetQuestionnaireGroup()
        .then((res) => {
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    } else {
      this.props
        .onGetAllQuestionnaire()
        .then((res) => {
          this.setState({ loading: false });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  onChangeVisaForm = (val) => {
    this.setState({ loading: true, isVisaForm: true });

    this.props
      .onGetGroupedQuestionnaire(val)
      .then((res) => {
        this.setState({ isGrouped: val, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleCancelVisibleModalDuplicate = () => {
    this.setState({ visibleModalDuplicate: false });
  };

  PublicApiResModalClose = () => {
    this.setState({ modal2Visible: false });
  };

  render() {
    const {
      loading,
      modal1Visible,
      questionnaireId,
      activeTab,
      groupedQuestionnaire,
      visaFormArray,
      visibleModalDuplicate,
      modal2Visible,
      handleKey,
    } = this.state;
    const { questionnaireList } = this.props;

    return (
      <div>
        <Spin spinning={loading}>
          <div style={{ display: "flex" }}>
            <div className="page-container">
              <div className="sus-container" style={{ paddingBottom: 80 }}>
                <div style={{ marginTop: 20 }}>
                  <div className="heading-container">
                    <span
                      className="heading-text"
                      style={{
                        cursor: "pointer",
                        color:
                          activeTab == "customQuestionnaire"
                            ? "rgb(16, 129, 184)"
                            : "#2f4678",
                      }}
                      onClick={() => this.onChangeTab("customQuestionnaire")}
                    >
                      CUSTOM QUESTIONNAIRE
                    </span>
                    <span className="heading-text" style={{ marginLeft: 15 }}>
                      |
                    </span>
                    <span
                      className="heading-text"
                      style={{
                        marginLeft: 15,
                        cursor: "pointer",
                        color:
                          activeTab == "visaForm"
                            ? "rgb(16, 129, 184)"
                            : "#2f4678",
                      }}
                      onClick={() => this.onChangeTab("visaForm")}
                    >
                      VISA FORM
                    </span>
                  </div>
                  {activeTab == "customQuestionnaire" ? (
                    <>
                      <div className="title-container">
                        <span className="heading-text">QUESTIONNAIRE</span>
                      </div>

                      <div className="cq-list-table-cont">
                        <div
                          className="cq-add-button"
                          style={{ cursor: "default" }}
                        >
                          <img
                            onClick={() =>
                              this.props.history.push("custom-questionnaire")
                            }
                            src={Images.plusIcon}
                            style={{ width: 20, cursor: "pointer" }}
                          />
                        </div>
                        <div className="cq-questionnaire-list-cont">
                          <div className="cq-questionnaire-list-heading">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                              className="cq-width-30"
                            >
                              <div className="cv-doc-width">
                                <span className="cv-doc-head-text">Name</span>
                              </div>
                            </div>
                            <div
                              className="cq-width-30"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <span className="cv-doc-head-text">In Show</span>
                            </div>
                            <div
                              style={{
                                width: 100,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <span className="cv-doc-head-text">Action</span>
                            </div>
                          </div>

                          {questionnaireList &&
                            questionnaireList.map((questionnaire, index) => {
                              return (
                                <>
                                  {questionnaire.branchId !=
                                    "00000000-0000-0000-0000-000000000000" && (
                                    <div
                                      className="cq-list-content-row"
                                      style={{
                                        justifyContent: "space-between",
                                        paddingRight: 5,
                                      }}
                                    >
                                      <div
                                        // className="cq-width-30"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "25%",
                                        }}
                                      >
                                        <div>
                                          <span
                                            className="cv-doc-text"
                                            style={{ color: "#1081B8" }}
                                          >
                                            {questionnaire.name}
                                          </span>
                                        </div>
                                      </div>

                                      <div>
                                        <Checkbox
                                          checked={questionnaire.showInClient}
                                          onChange={(e) =>
                                            this.onChangeIsClient(
                                              e,
                                              questionnaire
                                            )
                                          }
                                        >
                                          Client
                                        </Checkbox>
                                        <Checkbox
                                          checked={
                                            questionnaire.showInPotential
                                          }
                                          onChange={(e) =>
                                            this.onChangeIsPotential(
                                              e,
                                              questionnaire
                                            )
                                          }
                                        >
                                          Potential Client
                                        </Checkbox>
                                        <Checkbox
                                          checked={questionnaire.showInEmployer}
                                          onChange={(e) =>
                                            this.onChangeIsEmployer(
                                              e,
                                              questionnaire
                                            )
                                          }
                                        >
                                          Employer
                                        </Checkbox>
                                        <Checkbox
                                          checked={questionnaire.showInPublic}
                                          onChange={(e) =>
                                            this.onChangeIsPublic(
                                              e,
                                              questionnaire
                                            )
                                          }
                                        >
                                          Website
                                        </Checkbox>
                                        <Checkbox
                                          checked={
                                            questionnaire.showInPublicApi
                                          }
                                          onChange={(e) =>
                                            this.onChangeIsPublicApi(
                                              e,
                                              questionnaire
                                            )
                                          }
                                        >
                                          API
                                        </Checkbox>
                                        <Checkbox
                                          checked={questionnaire.showInEzyform}
                                          onChange={(e) =>
                                            this.onChangeEzyform(
                                              e,
                                              questionnaire
                                            )
                                          }
                                        >
                                          Ezyforms
                                        </Checkbox>
                                      </div>

                                      <div
                                        className="sus-head-text-cont"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-around",
                                        }}
                                      >
                                        <div
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.setState({
                                              visibleModalDuplicate: true,
                                              questionnaireId:
                                                questionnaire.questionnaireId,
                                            })
                                          }
                                        >
                                          <img
                                            src={Images.duplicate}
                                            style={{ width: 15 }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: 5,
                                          }}
                                          onClick={() =>
                                            this.props.history.push(
                                              "update-questionnaire?id=" +
                                                questionnaire.questionnaireId
                                            )
                                          }
                                        >
                                          <img
                                            src={Images.editBorderBlue}
                                            style={{ width: 15 }}
                                          />
                                        </div>
                                        {questionnaire.showInPublicApi && (
                                          <div
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: 5,
                                            }}
                                            onClick={() =>
                                              this.getPublicApiRes(
                                                questionnaire
                                              )
                                            }
                                          >
                                            <img
                                              src={Images.jsonImg}
                                              style={{ width: 15 }}
                                            />
                                          </div>
                                        )}
                                        <div
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: 5,
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              questionnaireId:
                                                questionnaire.questionnaireId,
                                              modal1Visible: true,
                                            })
                                          }
                                        >
                                          <img
                                            src={Images.deleteBlue}
                                            style={{ width: 15 }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          marginTop: 30,
                          marginLeft: 20,
                          marginBottom: 20,
                        }}
                      >
                        <Select
                          showSearch
                          style={{ width: 200 }}
                          placeholder="Select..."
                          optionFilterProp="children"
                          onChange={this.onChangeVisaForm}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {visaFormArray}
                        </Select>
                      </div>

                      <div className="cq-list-table-cont">
                        {/* <div
                          className="cq-add-button"
                          style={{ cursor: "default" }}
                        >
                          <img
                            onClick={() =>
                              this.props.history.push("custom-questionnaire")
                            }
                            src={Images.plusIcon}
                            style={{ width: 20, cursor: "pointer" }}
                          />
                        </div> */}
                        <div className="cq-questionnaire-list-cont">
                          <div className="cq-questionnaire-list-heading">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                              className="cq-width-30"
                            >
                              <div className="cv-doc-width">
                                <span className="cv-doc-head-text">Name</span>
                              </div>
                            </div>

                            <div
                              style={{
                                width: 200,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <span className="cv-doc-head-text">Action</span>
                            </div>
                          </div>

                          {groupedQuestionnaire &&
                            groupedQuestionnaire.map((questionnaire, index) => {
                              return (
                                <>
                                  <div
                                    className="cq-list-content-row"
                                    style={{
                                      justifyContent: "space-between",
                                      paddingRight: 50,
                                    }}
                                  >
                                    <div
                                      className="cq-width-30"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        <span
                                          className="cv-doc-text"
                                          style={{ color: "#1081B8" }}
                                        >
                                          {questionnaire.questionnaireName}
                                        </span>
                                      </div>
                                    </div>

                                    <div
                                      className="sus-head-text-cont"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: 5,
                                        }}
                                        onClick={() =>
                                          this.props.history.push(
                                            "update-mapp-property?id=" +
                                              questionnaire.questionnaireId
                                          )
                                        }
                                      >
                                        <img
                                          src={Images.editBorderBlue}
                                          style={{ width: 15 }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            title="Confirmation"
            visible={visibleModalDuplicate}
            onCancel={this.handleCancelVisibleModalDuplicate}
            footer={null}
            maskClosable={false}
          >
            <Row>
              <Col span={24}>
                <Row> Are you sure, you want to duplicate questionnaire?</Row>
              </Col>
            </Row>
            <Row style={{ display: "flex", marginTop: "40px" }}>
              <Col span={4} offset={16}>
                <Button
                  onClick={() => {
                    this.handleCancelVisibleModalDuplicate();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  style={{ marginLeft: "12px" }}
                  className={"button"}
                  onClick={() => {
                    this.createDuplicateQuestionnaire();
                  }}
                >
                  Ok
                </Button>
              </Col>
            </Row>
          </Modal>
          <Modal
            title={false}
            style={{ top: 20 }}
            visible={modal1Visible}
            onOk={() => this.onDeleteQuestionnaire(questionnaireId)}
            onCancel={() => this.setState({ modal1Visible: false })}
            Header={false}
          >
            <h4>Are you sure you want to delete this ?</h4>
          </Modal>
          <Modal
            title={false}
            style={{ top: 20 }}
            visible={modal2Visible}
            // onOk={() => this.onDeleteQuestionnaire(questionnaireId)}
            onCancel={this.PublicApiResModalClose}
            Header={false}
            footer={false}
            width="60%"
          >
            <div style={{ marginTop: 20 }}>
              <Tabs
                activeKey={handleKey}
                type="card"
                size={"small"}
                className="mar-r employer-doc-tab"
                onChange={(key) => this.callback(key)}
              >
                <TabPane tab="Public Api" key="1">
                  <div className="public-api-view" style={{ marginTop: 0 }}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        style={{
                          fontSize: 10,
                          fontWeight: "500",
                          padding: 5,
                          borderRadius: 5,
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            JSON.stringify(this.state.publicApiRes)
                          );
                          message.info("Copied!");
                        }}
                      >
                        Copy
                      </Button>
                    </div>
                    <div>
                      <span className="link-label-style">URL: </span>
                      <span className="link-text-style">
                        {" "}
                        {`${baseURL}v1/QuestionaireOpenApi/Recursive/${this.state.questionnairePopupId}`}
                      </span>
                    </div>
                    <div>
                      <span className="link-label-style">METHOD: </span>
                      <span className="link-method-style">GET</span>
                    </div>
                    <pre>
                      {JSON.stringify(this.state.publicApiRes, null, 2)}
                    </pre>
                  </div>
                </TabPane>
                <TabPane tab="Answers Api" key="2">
                  <div className="public-api-view">
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        style={{
                          fontSize: 10,
                          fontWeight: "500",
                          padding: 5,
                          borderRadius: 5,
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            JSON.stringify(answersApiData)
                          );
                          message.info("Copied!");
                        }}
                      >
                        Copy
                      </Button>
                    </div>
                    <div>
                      <span className="link-label-style">URL: </span>
                      <span className="link-text-style">
                        {" "}
                        {`${baseURL}v1/QuestionaireOpenApi/InsertFilledAnswers`}
                      </span>
                    </div>
                    <div>
                      <span className="link-label-style">METHOD: </span>
                      <span className="link-method-style">POST</span>
                    </div>
                    <pre>{JSON.stringify(answersApiData, null, 2)}</pre>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Modal>
        </Spin>
      </div>
    );
  }
}

export default QuestionnaireList;
