import React from "react";
import "./ClientsStyles.css";
import { saveAs } from "file-saver";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Form,
  Input,
  Checkbox,
  InputNumber,
  Table,
  Spin,
  Modal,
} from "antd";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { bindActionCreators } from "redux";
import {
  getAllInvoiceTypes,
  getAllBanksListing,
  getAllCurrencies,
  addNewInvoice,
  getLastInvoiceNumber,
  addInvoicePayment,
  getInvoicePayments,
  deleteInvoicePayment,
  editInvoice,
  setEmailTemplate,
  getInvoiceStatuses,
  getCompany,
  checkDuplicateClientInvoice,
  getClientProfile,
  getPDF,
  getProfileClientTag,
  setEmailTemplatePayment,
  getInvoicePDF,
  getInvoiceInfo,
  getAllTaxesListing,
  getAllNotesListing,
  getInvoicePDFHtmlBlob,
  addEmailDocument,
  getInvoiceReceiptPDFHtmlBlob,
  getInvoiceTemplate,
  addInvoiceTemplate,
  getBranch,
} from "../store/Actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import FroalaEditor from "react-froala-wysiwyg";
import SendEmail from "./SendEmail";
import activityData from "../Components/ClientActivity/ActivityData";
import AddAutoReminder from "../Components/Reminder/AddAutoReminder";
import { apiRefresh } from "../services/api";
const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const { TextArea } = Input;

var currencyId = 0;
var _gstPercent = "0";
class AddInvoice extends React.Component {
  formRef = React.createRef();
  formRefTemp = React.createRef();
  formRefTempAddLine = React.createRef();
  constructor(props) {
    let clientprofileid = sessionStorage.getItem("clientprofileid");
    let userEmail = localStorage.getItem("userEmail");
    super(props);
    // let _gstPercent = "0";
    // (props && props.companyData && props.companyData.gstPercent) || "0";
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    let userName = localStorage.getItem("userName");
    let userId = localStorage.getItem("userId");
    let profileData = this.props.profileDataRdx
      ? JSON.parse(this.props.profileDataRdx)
      : null;
    this.state = {
      taxName: "",
      taxListing: [],
      loading: true,
      isTaxFieldVisible: true,
      clientProfileId:
        this.props && this.props.clientId
          ? this.props && this.props.clientId
          : clientprofileid,
      notesImageData: "",
      branchId: selectedBranchId,
      clientName: profileData && profileData.fullName && profileData.fullName,
      to:
        this.props && this.props.clientName
          ? this.props && this.props.clientName
          : profileData && profileData.fullName,
      userId: userId,
      dateStr: "",
      dueDate: "",
      invoiceNo: Math.random()
        .toString(36)
        .substr(2, 5),
      invoiceTypeId: 1,
      taxInclusive: false,
      notes: "",
      lastInvoiceNumber: "",
      clientTagId: 0,
      _amount: 100,
      qTotal: 0,
      total: 0,
      subTotal: 0,
      discountAmount: 0,
      discount: 0,
      addedAmount: 0,
      gst: parseInt(_gstPercent),
      dueAmount: 0,
      invoiceItems: [],
      invoiceItemsDefined: [],
      invoiceId: 0,
      noteListing: [],
      invoicePayments: [],
      visibleDeleteModal: false,
      paymentDescription: "",
      visibleSendingReceiptModal: false,
      sendingReceiptEmail: "",
      subjectTypeId: 2,
      invoicePaymentsAmountTotal: 0,
      branchName: "",
      setEmailData: "",
      invoiceTypesListing: [],
      invoiceStatuses: [],
      statusId: 0,
      userEmail: userEmail,
      deletedPayment: 0,
      isDisabled: false,
      isDuplicateInvoiceExists: false,
      showSendingReceiptModalForm: false,
      loadingEmailPopUp: false,
      visibleFollowUpEmailPopUp: false,
      currencyId: 106,
      postedXero: false,
      xeroInvoiceId: "",
      currentPaymentId: 0,
      isPDFRelatedToInvoice: false,
      showSecondaryEmail: false,
      taxValueDropdown: 0,
      depositInvoice: false,
      showInvoiceTitle: false,
      buttonType: "",
      invoiceItemsList: [],
      isModalVisible: false,
      title: "",
      tempListing: [],
      ids: [],
      calculated: false,
    };
  }
  paymentColumns = [
    {
      title: "Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {moment(record.receivedDate).format("DD/MM/YYYY")}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {record.amount}
        </p>
      ),
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      render: (text, record) => (
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            fontStyle: "normal",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span style={{ display: "flex" }}>
          <Button
            type="primary"
            style={{ marginRight: "4px" }}
            className="button-blue"
            onClick={() => this.showModalSendingReceipt(record)}
          >
            {"SEND RECEIPT"}{" "}
          </Button>
          {record.xeroID === "00000000-0000-0000-0000-000000000000" ? (
            <Button
              type="primary"
              className="button-blue"
              onClick={() => {
                this.showModalDelete(record.id, record.amount);
              }}
            >
              {"UNDO"}
            </Button>
          ) : null}
        </span>
      ),
    },
  ];

  showModalSendingReceipt = (record) => {
    let _invoiceInfo = this.props && this.props.invoiceInfo;
    let _statusId;
    if (
      _invoiceInfo &&
      _invoiceInfo.invoiceTypeId === 2 &&
      _invoiceInfo.statusId === 27
    ) {
      _statusId = _invoiceInfo.statusId;
    } else {
      if (this.state.dueAmount !== 0 && this.state.dueAmount > 0) {
        if (
          this.state.invoiceStatuses &&
          this.state.invoiceStatuses.length > 0
        ) {
          _statusId = this.state.invoiceStatuses[0].id;
        }
      } else {
        _statusId = this.state.invoiceStatuses[4].id;
      }
    }
    let _childrenTotalAmount =
      this.state.childrenTotalAmount + this.state.total;
    this.setState({ statusId: _statusId }, () => {
      if (this.state.invoiceTypeId === 0) {
        message.error("Please select Invoice type Id!");
      } else if (
        this.state.dateStr === "" ||
        this.state.dateStr === "Invalid date"
      ) {
        message.error("Please select date!");
      } else if (
        this.state.dueDate === "" ||
        this.state.dueDate === "Invalid date"
      ) {
        message.error("Please select due date!");
      } else if (this.state.invoiceNo === "") {
        message.error("Please add Invoice no!");
      } else if (this.state.subTotal === 0) {
        message.error("Sub total is 0!");
      } else if (
        this.props.parentInvoiceId !== 0 &&
        _childrenTotalAmount > this.state.parentTotalAmount
      ) {
        message.error(
          "Sum of all child invoices amount can never be greater than parent invoice amount!"
        );
      } else if (this.state.discountAmount > this.state.subTotal) {
        message.error("Discount amount must be less than total amount!");
      } else {
        let _data = {
          invoiceNo: this.state.invoiceNo,
        };
        this.props.checkDuplicateClientInvoice(_data).then(() => {
          let _duplicate = this.props && this.props.duplicate;
          if (
            _duplicate === true &&
            this.state.invoiceNo !== _invoiceInfo.invoiceNo
          ) {
            message.error("Invoice no must be unique!");
          } else {
            this.setState({ loadingEmailPopUp: true }, () => {
              if (this.state.selectedTemp) {
                var idsList = { items: this.state.ids };
                const delItemsOpt = {
                  url: `v1/invoice/item/DeleteAllInvoiceItem`,
                };
                delItemsOpt.types = [
                  "DELETE_INVOICE_ITEMS_SUCCESS",
                  "DELETE_INVOICE_ITEMS_FAILURE",
                ];
                apiRefresh.delete(delItemsOpt, idsList).then((res) => {
                  this.props.editInvoice(this.state).then(() => {
                    this.props
                      .getInvoiceInfo(this.state.invoiceId)
                      .then((res) => {
                        let tempInvoiceItems =
                          res &&
                          res.payload &&
                          res.payload.invoiceItems &&
                          res.payload.invoiceItems;
                        this.setState(
                          {
                            invoiceItems: tempInvoiceItems,
                            invoiceItemsDefined: tempInvoiceItems,
                          },
                          () => {
                            this.formRefTempAddLine.current.setFieldsValue({
                              invoiceItems: undefined,
                            });
                            message.success("Invoice is updated successfully!");
                          }
                        );
                      })
                      .catch(() => {
                        this.setState({ loadingEmailPopUp: false });
                      });
                    let _emailTemplatePayload = {
                      templateName: "recipt",
                      paymentId: record.id,
                      invoiceId: this.state.invoiceId,
                      parameters: [
                        {
                          key: "ClientId",
                          value: this.state.clientProfileId,
                        },
                        {
                          key: "UserId",
                          value: this.state.userId,
                        },
                        {
                          key: "CurrencyId",
                          value: this.state.currencyId
                            ? this.state.currencyId.toString()
                            : "106",
                        },
                        {
                          key: "Amount",
                          value: this.state.dueAmount.toString(),
                        },
                        {
                          key: "InvoiceNumber",
                          value: this.state.invoiceNo,
                        },
                      ],
                    };
                    this.props
                      .setEmailTemplatePayment(_emailTemplatePayload)
                      .then(() => {
                        let _setEmailData = this.props.setEmailDataPayment;

                        this.setState({ setEmailData: _setEmailData }, () => {
                          this.setState({}, () => {
                            this.setState(
                              {
                                currentPaymentId: record.id,
                                isPDFRelatedToInvoice: false,
                                showInvoiceTitle: false,
                              },
                              () => {
                                let _data = {
                                  invoiceId: this.state.invoiceId,
                                  paymentId: this.state.currentPaymentId,
                                };
                                this.props
                                  .getInvoiceReceiptPDFHtmlBlob(_data)
                                  .then((res) => {
                                    let formData = new FormData();

                                    let binaryString = window.atob(
                                      res.payload.url
                                    );
                                    let binaryLen = binaryString.length;
                                    let bytes = new Uint8Array(binaryLen);
                                    for (let i = 0; i < binaryLen; i++) {
                                      let ascii = binaryString.charCodeAt(i);
                                      bytes[i] = ascii;
                                    }
                                    let _blob = new Blob([bytes], {
                                      type: "application/pdf",
                                    });
                                    let metadata = {
                                      type: "application/pdf",
                                    };
                                    let file = new File(
                                      [_blob],
                                      res.payload.name,
                                      metadata
                                    );

                                    formData.append(
                                      "BranchId",
                                      localStorage.getItem("selectedBranchId")
                                    );

                                    formData.append("files", file);
                                    this.props
                                      .onAddEmailDocument(formData)
                                      .then((res) => {
                                        let _attData = res && res.payload;
                                        let attDataArray = [
                                          {
                                            name: _attData[0].fileName,
                                            type: _attData[0].contentType,
                                            url: _attData[0].fileUrl,
                                          },
                                        ];

                                        this.setState({
                                          attDataArray: attDataArray,
                                          loadingEmailPopUp: false,
                                          visibleSendingReceiptModal: true,
                                        });
                                      })
                                      .catch(() => {
                                        this.setState({
                                          loadingEmailPopUp: false,
                                        });
                                      });
                                  })
                                  .catch(() => {
                                    this.setState({ loadingEmailPopUp: false });
                                  });
                              }
                            );
                          });
                        });
                      })
                      .catch(() => {
                        this.setState({ loadingEmailPopUp: false });
                      });
                  });
                });
              } else {
                this.props.editInvoice(this.state).then(() => {
                  this.props
                    .getInvoiceInfo(this.state.invoiceId)
                    .then((res) => {
                      let tempInvoiceItems =
                        res &&
                        res.payload &&
                        res.payload.invoiceItems &&
                        res.payload.invoiceItems;
                      this.setState(
                        {
                          invoiceItems: tempInvoiceItems,
                          invoiceItemsDefined: tempInvoiceItems,
                        },
                        () => {
                          this.formRefTempAddLine.current.setFieldsValue({
                            invoiceItems: undefined,
                          });
                          message.success("Invoice is updated successfully!");
                        }
                      );
                    })
                    .catch(() => {
                      this.setState({ loadingEmailPopUp: false });
                    });
                  let _emailTemplatePayload = {
                    templateName: "recipt",
                    paymentId: record.id,
                    invoiceId: this.state.invoiceId,
                    parameters: [
                      {
                        key: "ClientId",
                        value: this.state.clientProfileId,
                      },
                      {
                        key: "UserId",
                        value: this.state.userId,
                      },
                      {
                        key: "CurrencyId",
                        value: this.state.currencyId
                          ? this.state.currencyId.toString()
                          : "106",
                      },
                      {
                        key: "Amount",
                        value: this.state.dueAmount.toString(),
                      },
                      {
                        key: "InvoiceNumber",
                        value: this.state.invoiceNo,
                      },
                    ],
                  };
                  this.props
                    .setEmailTemplatePayment(_emailTemplatePayload)
                    .then(() => {
                      let _setEmailData = this.props.setEmailDataPayment;

                      this.setState({ setEmailData: _setEmailData }, () => {
                        this.setState({}, () => {
                          this.setState(
                            {
                              currentPaymentId: record.id,
                              isPDFRelatedToInvoice: false,
                              showInvoiceTitle: false,
                            },
                            () => {
                              let _data = {
                                invoiceId: this.state.invoiceId,
                                paymentId: this.state.currentPaymentId,
                              };
                              this.props
                                .getInvoiceReceiptPDFHtmlBlob(_data)
                                .then((res) => {
                                  let formData = new FormData();

                                  let binaryString = window.atob(
                                    res.payload.url
                                  );
                                  let binaryLen = binaryString.length;
                                  let bytes = new Uint8Array(binaryLen);
                                  for (let i = 0; i < binaryLen; i++) {
                                    let ascii = binaryString.charCodeAt(i);
                                    bytes[i] = ascii;
                                  }
                                  let _blob = new Blob([bytes], {
                                    type: "application/pdf",
                                  });
                                  let metadata = {
                                    type: "application/pdf",
                                  };
                                  let file = new File(
                                    [_blob],
                                    res.payload.name,
                                    metadata
                                  );

                                  formData.append(
                                    "BranchId",
                                    localStorage.getItem("selectedBranchId")
                                  );

                                  formData.append("files", file);
                                  this.props
                                    .onAddEmailDocument(formData)
                                    .then((res) => {
                                      let _attData = res && res.payload;
                                      let attDataArray = [
                                        {
                                          name: _attData[0].fileName,
                                          type: _attData[0].contentType,
                                          url: _attData[0].fileUrl,
                                        },
                                      ];

                                      this.setState({
                                        attDataArray: attDataArray,
                                        loadingEmailPopUp: false,
                                        visibleSendingReceiptModal: true,
                                      });
                                    })
                                    .catch(() => {
                                      this.setState({
                                        loadingEmailPopUp: false,
                                      });
                                    });
                                })
                                .catch(() => {
                                  this.setState({ loadingEmailPopUp: false });
                                });
                            }
                          );
                        });
                      });
                    })
                    .catch(() => {
                      this.setState({ loadingEmailPopUp: false });
                    });
                });
              }
            });
          }
        });
      }
    });
  };

  componentDidMount() {
    this.props.getAllNotesListing();
    // this.props.getClientProfile(this.state.clientProfileId);
    this.props.getCompany();
    this.props.getClientTag(this.state.clientProfileId);
    this.props.getAllInvoiceTypes();
    this.props.getAllBanksListing();
    this.props.getAllCurrencies();
    this.props.getLastInvoiceNumber();
    this.props.getBranch();
    this.props.getInvoiceStatuses(this.state.invoiceTypeId);
    this.props.onGetInvoiceTemplate(this.state.clientProfileId);
    const branchUserOpt = {
      url: `v1/client/contract/BranchDetails`,
    };
    branchUserOpt.types = [
      "GET_BRANCH_USER_SUCCESS",
      "GET_BRANCH_USER_FAILURE",
    ];
    let IsUserManager;
    if (localStorage.getItem("userManager") === "true") {
      IsUserManager = true;
    } else {
      IsUserManager = false;
    }
    if (!this.props.invoiceInfo) {
      apiRefresh.put(branchUserOpt).then((res) => {
        currencyId = parseInt(res.currency || "106");
        _gstPercent = parseInt(res.gstPercent || "0");
        this.setState({ currencyId: currencyId, gst: _gstPercent });
        this.formRef.current.setFieldsValue({
          currencyId: currencyId,
          gst: _gstPercent,
        });
      });
    }
    this.setState(
      {
        parentInvoiceId: this.props && this.props.parentInvoiceId,
        childrenTotalAmount: this.props && this.props.childrenTotalAmount,
        parentTotalAmount: this.props && this.props.parentTotalAmount,
      },
      () => {
        this.formRef.current.setFieldsValue({
          currencyId: currencyId,
        });
      }
    );
  }
  componentWillReceiveProps(nextProps, nextContext) {
    let _invoiceInfo = nextProps.invoiceInfo;
    let _noteListing = nextProps && nextProps.noteListing;

    let _invoiceStatuses =
      nextProps && nextProps.invoiceStatuses && nextProps.invoiceStatuses;
    let _invoiceTypesListing =
      nextProps.invoiceTypesListing &&
      nextProps.invoiceTypesListing.items &&
      nextProps.invoiceTypesListing.items;
    let _invoicePayments = nextProps.invoicePayments;
    let _branchData = nextProps.branchData;
    let invoiceTypesListingForChild = [];
    let _taxListing = nextProps && nextProps.taxListing;
    this.setState({
      invoiceStatuses: _invoiceStatuses,
      taxListing: _taxListing,
      noteListing: _noteListing,
    });
    if (this.props.parentInvoiceId !== 0) {
      if (_invoiceTypesListing && _invoiceTypesListing.length > 0) {
        for (let index = 0; index < _invoiceTypesListing.length; index++) {
          if (_invoiceTypesListing[index].id !== 2) {
            invoiceTypesListingForChild.push({
              id: _invoiceTypesListing[index].id,
              invoiceTypeName: _invoiceTypesListing[index].invoiceTypeName,
            });
          }
        }
        this.setState({ invoiceTypesListing: invoiceTypesListingForChild });
      }
    } else {
      this.setState({ invoiceTypesListing: _invoiceTypesListing });
    }

    let _invoicePaymentsAmountTotal = 0;
    let _lastInvoiceNumber = nextProps && nextProps.lastInvoiceNumber;
    this.setState({ lastInvoiceNumber: _lastInvoiceNumber });

    if (_invoiceInfo && _invoiceInfo.id) {
      if (_invoiceInfo.id !== this.state.invoiceId) {
        // if(_invoiceInfo.invoiceTypeId === 3)
        // {
        //   this.setState({gst : 0 })
        // }
        let invoiceIds = [];
        _invoiceInfo.invoiceItems &&
          _invoiceInfo.invoiceItems.map((item, ind) => {
            invoiceIds.push({ id: item.id, delete: true });
          });
        this.setState({ ids: invoiceIds });
        let clientprofileid = sessionStorage.getItem("clientprofileid");
        let userId = localStorage.getItem("userId");
        let _dateStr = moment(_invoiceInfo.dateStr);
        let _dueDate = moment(_invoiceInfo.dueDate);
        this.props.getInvoiceStatuses(_invoiceInfo.invoiceTypeId);
        let _branchName;
        if (_branchData && _branchData.length > 0) {
          for (
            let branchIndex = 0;
            branchIndex < _branchData.length;
            branchIndex++
          ) {
            if (_branchData[branchIndex].id === this.state.branchId) {
              _branchName = _branchData[branchIndex].name;
            }
            this.setState({ branchName: _branchName });
          }
        }
        let html = _invoiceInfo.notes;

        let re = /src=([^">]+)/g;
        let results = re.exec(html);
        let _text = html.split("src")[0];
        this.setState(
          {
            depositInvoice: _invoiceInfo.depositInvoice,
            taxName: _invoiceInfo.taxName,
            postedXero: _invoiceInfo.postedXero,
            xeroInvoiceId: _invoiceInfo.xeroInvoiceId,
            dateStr: _dateStr,
            dueDate: _dueDate,
            invoiceId: _invoiceInfo.id,
            invoiceNo: _invoiceInfo.invoiceNo,
            invoiceTypeId: _invoiceInfo.invoiceTypeId,
            clientTagId: _invoiceInfo.clientTagId,
            invoiceItemsDefined: _invoiceInfo.invoiceItems,
            taxInclusive: _invoiceInfo.taxInclusive,
            notesImageData: results,
            notes: _text,
            isTaxFieldVisible: _invoiceInfo.invoiceTypeId !== 3,
          },
          () => {
            let _subTotal = _invoiceInfo.subTotal;
            let _currencyId;
            let _gst = _invoiceInfo.gst;
            let _discount = _invoiceInfo.discount;
            let _discountAmount = _invoiceInfo.discountAmount;
            let _total = _invoiceInfo.total;
            let _dueAmount = _invoiceInfo.dueAmount;

            let _bankAccount;
            if (_invoiceInfo.bankAccount === 0) {
              _bankAccount = undefined;
            } else {
              _bankAccount = _invoiceInfo.bankAccount;
            }
            if (_invoiceInfo) {
              currencyId = _invoiceInfo.currencyId;
              _gstPercent = _invoiceInfo.gst;
            }

            this.setState(
              {
                subTotal: _subTotal,
                currencyId:
                  currencyId && currencyId != 0
                    ? currencyId
                    : this.state.currencyId,
                gst:
                  _gstPercent && _gstPercent != "0"
                    ? _gstPercent
                    : _invoiceInfo
                    ? _gstPercent
                    : this.state.gst,
                discount: _discount,
                discountAmount: _discountAmount,
                total: _total,
                dueAmount: _dueAmount,
                bankAccount: _bankAccount,
              },
              () => {}
            );
            this.formRef.current.setFieldsValue({
              taxName: this.state.taxName,
              subTotal: _subTotal,
              currencyId: currencyId != 0 ? currencyId : this.state.currencyId,
              gst:
                _gstPercent != "0"
                  ? _gstPercent
                  : _invoiceInfo
                  ? _gstPercent
                  : this.state.gst,
              bankAccount: _bankAccount,
              discount: _discount,
              discountAmount: _discountAmount,
              total: _total,
              dueAmount: _dueAmount,
            });
            this.props
              .getInvoicePayments(this.state.invoiceId)
              .then(() => {
                // this.setState({invoicePayments: _invoicePayments})
              })
              .catch(() => {
                this.setState({ loading: false });
              });
          }
        );
      }
      let __invoicePayments = nextProps && nextProps._invoicePayments;

      if (__invoicePayments && __invoicePayments.length === 0) {
        this.setState({ invoicePayments: [], invoicePaymentsAmountTotal: 0 });
      }
      if (_invoicePayments && _invoicePayments.length > 0) {
        this.setState({ invoicePayments: _invoicePayments, loading: false });
        for (
          let indexInvoicePayments = 0;
          indexInvoicePayments < _invoicePayments.length;
          indexInvoicePayments++
        ) {
          _invoicePaymentsAmountTotal =
            _invoicePaymentsAmountTotal +
            _invoicePayments[indexInvoicePayments].amount;
        }
        this.setState(
          { invoicePaymentsAmountTotal: _invoicePaymentsAmountTotal },
          () => {}
        );
      }
    }
    let _selectedBranchId = nextProps && nextProps.selectedBranch;
    if (this.state.branchId !== _selectedBranchId) {
      this.setState({ branchId: _selectedBranchId }, () => {
        this.props.getLastInvoiceNumber().then(() => {});
        this.props.getAllTaxesListing();
        this.props.getAllBanksListing().then(() => {
          // this.props.history.push("/profile")
          // this.formRef.current.setFieldsValue({
          //   bankAccount: undefined
          // });
        });
      });
    }
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  handleDateChange = (date, dateString) => {
    this.setState({ dateStr: date }, () => {
      console.log("state", this.state);
    });
  };

  handleDueDateChange = (date, dateString) => {
    this.setState({ dueDate: date }, () => {
      console.log("state", this.state);
    });
  };

  getDatePickers = () => {
    return (
      <Row>
        <Col xs={11} offset={1}>
          <p style={{ fontSize: "11px" }}>{"Date"}</p>
          <DatePicker
            value={
              this.state.dateStr === null ||
              this.state.dateStr === "" ||
              this.state.dateStr === "Invalid date"
                ? null
                : moment(this.state.dateStr, dateFormat)
            }
            format={dateFormat}
            onChange={this.handleDateChange}
          />
        </Col>
        <Col xs={11} style={{ marginLeft: "8px" }}>
          <p style={{ fontSize: "11px" }}>{"Due Date"}</p>
          <DatePicker
            value={
              this.state.dueDate === null ||
              this.state.dueDate === "" ||
              this.state.dueDate === "Invalid date"
                ? null
                : moment(this.state.dueDate, dateFormat)
            }
            format={dateFormat}
            onChange={this.handleDueDateChange}
          />
        </Col>
      </Row>
    );
  };

  getTemplateDropDown = () => {
    return (
      <Row>
        <Col xs={22} style={{ marginLeft: "8px" }}>
          <p style={{ fontSize: "11px" }}>{"Invoice Template"}</p>
          <Select
            placeholder="Select Template"
            value={this.state.selectedTemp}
            onChange={this.onChangeTemp}
          >
            {this.props.invoiceTempRes &&
              this.props.invoiceTempRes.map((temp) => (
                <Option value={temp.id}>{temp.title}</Option>
              ))}
          </Select>
        </Col>
      </Row>
    );
  };

  getInvoiceTempLines = (id) => {
    const invoiceTempOpt = {
      url: `v1/invoice/AddNewLine/${id}`,
    };
    invoiceTempOpt.types = ["TEMPLATE_LINES_SUCCESS", "TEMPLATE_LINES_FAILURE"];
    apiRefresh.get(invoiceTempOpt).then((res) => {
      this.setState({ invoiceItemsDefined: res.items });
    });
  };

  onChangeTemp = (e) => {
    this.formRefTempAddLine.current.setFieldsValue({
      invoiceItems: [],
    });
    this.setState({ selectedTemp: e, calculated: true });
    this.getInvoiceTempLines(e);
  };

  handleChangeInvoiceNo = (e) => {
    if (e.target.value.length < 18) {
      this.setState({ invoiceNo: e.target.value });
    } else {
      message.error("Max character limit for invoice no reached!");
    }
  };

  handleChangeInvoiceType = (value) => {
    let _gstPercent =
      (this.props &&
        this.props.companyData &&
        this.props.companyData.gstPercent) ||
      "0";
    let cal;
    if (this.state.discount === 0) {
      cal = this.state.subTotal - this.state.discountAmount;
    } else {
      cal =
        this.state.subTotal - (this.state.discount * this.state.subTotal) / 100;
    }
    let _qTotal;
    if (this.state.qTotal === 0) {
      _qTotal = this.state.total;
    } else {
      _qTotal = this.state.qTotal;
    }
    if (value === 3) {
      this.setState({ isTaxFieldVisible: false }, () => {
        let _taxAmount = (this.state.gst * cal) / 100;
        let removedTaxTotalAmount = _qTotal - _taxAmount;
        let removedTaxTotalDue = this.state.dueAmount - _taxAmount;
        let totalDec =
          Math.round((removedTaxTotalAmount + Number.EPSILON) * 100) / 100;
        this.setState(
          {
            total: totalDec,
            dueAmount:
              Math.round((removedTaxTotalDue + Number.EPSILON) * 100) / 100,
            qTotal:
              Math.round((removedTaxTotalAmount + Number.EPSILON) * 100) / 100,
            gst: 0,
          },
          () => {
            this.formRef.current.setFieldsValue({
              total: this.state.total,
              dueAmount: this.state.dueAmount,
              gst: 0,
            });
          }
        );
      });
    } else if (this.state.invoiceTypeId === 3 && (value === 1 || value === 2)) {
      this.setState(
        { isTaxFieldVisible: true, gst: parseInt(_gstPercent) },
        () => {
          let _taxAmount = (this.state.gst * cal) / 100;
          let removedTaxTotalAmount = _qTotal + _taxAmount;
          let removedTaxTotalDue = this.state.dueAmount + _taxAmount;
          let totalDec =
            Math.round((removedTaxTotalAmount + Number.EPSILON) * 100) / 100;
          this.setState(
            {
              total: totalDec,
              dueAmount:
                Math.round((removedTaxTotalDue + Number.EPSILON) * 100) / 100,
              qTotal:
                Math.round((removedTaxTotalAmount + Number.EPSILON) * 100) /
                100,
              taxInclusive: false,
            },
            () => {
              this.formRef.current.setFieldsValue({
                total: this.state.total,
                dueAmount: this.state.dueAmount,
                gst: parseInt(_gstPercent),
              });
            }
          );
        }
      );
    }

    this.setState({ invoiceTypeId: value }, () => {
      this.props.getInvoiceStatuses(this.state.invoiceTypeId);
    });
  };

  handleChangeClientTags = (value) => {
    this.setState({ clientTagId: value });
  };

  handleChangeNotes = (e) => {
    this.setState({ notes: e.target.value });
  };

  onBlur = () => {
    let _data = {
      invoiceNo: this.state.invoiceNo,
    };

    if (_data.invoiceNo !== "") {
      let _invoiceInfo = this.props && this.props.invoiceInfo;
      this.props.checkDuplicateClientInvoice(_data).then(() => {
        let _duplicate = this.props && this.props.duplicate;
        if (_duplicate === true) {
          if (_invoiceInfo && _invoiceInfo.id) {
            if (this.state.invoiceNo !== _invoiceInfo.invoiceNo) {
              this.setState({ isDuplicateInvoiceExists: true }, () => {
                message.error("Invoice no must be unique!");
              });
            }
          } else {
            this.setState({ isDuplicateInvoiceExists: true }, () => {
              message.error("Invoice no must be unique!");
            });
          }
        } else {
          this.setState({ isDuplicateInvoiceExists: false }, () => {});
        }
      });
    }
  };

  getFilters = () => {
    return (
      <Row>
        <Col xs={6}>
          {this.props &&
          this.props.invoiceInfo &&
          this.props.invoiceInfo.id ? null : (
            <div>
              <p style={{ fontSize: "11px" }}>{"Last Invoice #"}</p>
              <Input value={this.state.lastInvoiceNumber} disabled={true} />
            </div>
          )}
        </Col>
        <Col xs={6}>
          <p style={{ fontSize: "11px", marginLeft: "8px" }}>{"Invoice #"}</p>
          <Input
            value={this.state.invoiceNo}
            onChange={this.handleChangeInvoiceNo}
            onBlur={this.onBlur}
            style={{ marginLeft: "8px" }}
          />
        </Col>
        <Col xs={5}>
          <p style={{ fontSize: "11px", marginLeft: "16px" }}>{"Type"}</p>
          <Select
            style={{ marginLeft: "16px" }}
            className={"width-web-kit"}
            placeholder={"Select"}
            onChange={this.handleChangeInvoiceType}
            defaultValue={
              this.state.invoiceTypesListing &&
              this.state.invoiceTypesListing[0] &&
              this.state.invoiceTypesListing[0].id
                ? this.state.invoiceTypesListing[0].id
                : undefined
            }
            value={
              this.state.invoiceTypeId === 0
                ? undefined
                : this.state.invoiceTypesListing &&
                  this.state.invoiceTypesListing[0] &&
                  this.state.invoiceTypesListing[0].id
                ? this.state.invoiceTypeId
                : undefined
            }
          >
            {this.state &&
              this.state.invoiceTypesListing &&
              this.state.invoiceTypesListing.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.invoiceTypeName}</Option>;
              })}
          </Select>
        </Col>
        <Col xs={5}>
          <p style={{ fontSize: "10px", marginLeft: "8px" }}>{"Client Tags"}</p>
          <Select
            style={{ marginLeft: "8px" }}
            className={"width-web-kit"}
            placeholder={"Select"}
            onChange={this.handleChangeClientTags}
            value={
              this.state.clientTagId === 0 ? undefined : this.state.clientTagId
            }
          >
            <Option value={0}>{"Select"}</Option>
            {this.props &&
              this.props.clientTagRes &&
              this.props.clientTagRes.items &&
              this.props.clientTagRes.items.map((data) => {
                // eslint-disable-next-line react/jsx-no-undef
                return <Option value={data.id}>{data.tag}</Option>;
              })}
          </Select>
        </Col>
      </Row>
    );
  };

  handleChangeAmount = (value) => {};

  onFinishAddNewLines = (values) => {
    let _invoiceInfoId =
      this.props && this.props.invoiceInfo && this.props.invoiceInfo.id;
    if (this.state.buttonType === "Template") {
      var itemsList = [];
      if (this.state.invoiceItemsDefined.length > 0) {
        for (var i = 0; i < this.state.invoiceItemsDefined.length; i++) {
          itemsList.push({
            description: this.state.invoiceItemsDefined[i].description || "",
            amount: this.state.invoiceItemsDefined[i].amount || 0,
          });
        }
      }
      if (values.invoiceItems && values.invoiceItems.length > 0) {
        for (var i = 0; i < values.invoiceItems.length; i++) {
          itemsList.push({
            description: values.invoiceItems[i].description || "",
            amount: values.invoiceItems[i].amount || 0,
          });
        }
      }

      let tempData = {
        invoiceItems: itemsList,
      };
      if (itemsList.length == 0) {
        message.error("Please add atleast one line for create template!");
        return;
      }
      this.setState({ invoiceItemsList: tempData, isModalVisible: true });
    } else {
      let invoiceItemsTemp = [];
      let _invoiceItems =
        values && values.invoiceItems ? values.invoiceItems : [];
      if (_invoiceItems && _invoiceItems.length > 0) {
        for (
          let invoiceItemIndex = 0;
          invoiceItemIndex < _invoiceItems.length;
          invoiceItemIndex++
        ) {
          invoiceItemsTemp.push({
            id: 0,
            invoiceId: _invoiceInfoId ? _invoiceInfoId : 0,
            description:
              _invoiceItems[invoiceItemIndex].description === undefined
                ? ""
                : _invoiceItems[invoiceItemIndex].description,
            amount:
              _invoiceItems[invoiceItemIndex].amount === undefined
                ? 0
                : _invoiceItems[invoiceItemIndex].amount,
            xeroLineItemID: "00000000-0000-0000-0000-000000000000",
            taxInclusive: true,
          });
        }
      }
      if (this.state && this.state.invoiceItemsDefined.length > 0) {
        for (
          let invoiceItemDefinedIndex = 0;
          invoiceItemDefinedIndex < this.state.invoiceItemsDefined.length;
          invoiceItemDefinedIndex++
        ) {
          invoiceItemsTemp.push({
            id: _invoiceInfoId
              ? this.state.invoiceItemsDefined[invoiceItemDefinedIndex].id || 0
              : 0,
            invoiceId: _invoiceInfoId ? _invoiceInfoId : 0,
            description: this.state.invoiceItemsDefined[invoiceItemDefinedIndex]
              .description,
            amount: this.state.invoiceItemsDefined[invoiceItemDefinedIndex]
              .amount,
            xeroLineItemID: this.state.invoiceItemsDefined[
              invoiceItemDefinedIndex
            ].xeroLineItemID,
            taxInclusive: true,
          });
        }
      }

      this.setState({ invoiceItems: invoiceItemsTemp });
      let amountTotal = 0;
      let amountTotalDefinedInvoiceItems = 0;
      if (values && values.invoiceItems && values.invoiceItems.length > 0) {
        for (let index = 0; index < values.invoiceItems.length; index++) {
          if (values.invoiceItems[index].amount !== undefined) {
            amountTotal = amountTotal + values.invoiceItems[index].amount;
          }
        }
      }

      let g = this.state.invoiceItemsDefined;

      if (
        this.state.invoiceItemsDefined &&
        this.state.invoiceItemsDefined.length > 0
      ) {
        for (
          let indexDefinedItems = 0;
          indexDefinedItems < this.state.invoiceItemsDefined.length;
          indexDefinedItems++
        ) {
          amountTotalDefinedInvoiceItems =
            amountTotalDefinedInvoiceItems +
            this.state.invoiceItemsDefined[indexDefinedItems].amount;
        }
      }

      amountTotal = amountTotal + amountTotalDefinedInvoiceItems;
      let totalDec = ((amountTotal + Number.EPSILON) * 100) / 100;
      this.setState(
        {
          subTotal: totalDec,
        },
        () => {
          let _discountedAmountDiscountPercentage;
          let _discountedAmountDiscountFinal;
          let _discountedAmountTaxPercentage;
          let _tempTotalAmount;
          if (this.state.discount !== 0) {
            _discountedAmountDiscountPercentage =
              (this.state.discount * this.state.subTotal) / 100;
            _discountedAmountDiscountFinal =
              this.state.subTotal - _discountedAmountDiscountPercentage;
          } else {
            _discountedAmountDiscountFinal =
              this.state.subTotal - this.state.discountAmount;
          }
          _discountedAmountTaxPercentage =
            (_discountedAmountDiscountFinal * this.state.gst) / 100;

          _tempTotalAmount =
            _discountedAmountTaxPercentage + _discountedAmountDiscountFinal;

          let totalDec = ((_tempTotalAmount + Number.EPSILON) * 100) / 100;

          this.setState(
            {
              total: totalDec,
              dueAmount:
                ((_tempTotalAmount -
                  this.state.invoicePaymentsAmountTotal +
                  Number.EPSILON) *
                  100) /
                100,
            },
            () => {
              let subTotalDec = ((amountTotal + Number.EPSILON) * 100) / 100;
              this.formRef.current.setFieldsValue({
                subTotal: subTotalDec,
                total: this.state.total,
                dueAmount: this.state.dueAmount,
              });
            }
          );
        }
      );
    }
  };

  handleChangeInvoiceItemsDefinedDescription = (e, data) => {
    let __invoiceItemsDefined = [...this.state.invoiceItemsDefined];
    if (__invoiceItemsDefined && __invoiceItemsDefined.length > 0) {
      for (
        let itemsDefinedIndex = 0;
        itemsDefinedIndex < __invoiceItemsDefined.length;
        itemsDefinedIndex++
      ) {
        // eslint-disable-next-line react/no-direct-mutation-state
        if (__invoiceItemsDefined[itemsDefinedIndex].id === data.id) {
          __invoiceItemsDefined[itemsDefinedIndex].description = e.target.value;
        }
      }
    }
    this.setState(
      {
        invoiceItemsDefined: __invoiceItemsDefined,
        invoiceItems: __invoiceItemsDefined,
      },
      () => {
        console.log("state", this.state);
      }
    );
  };

  handleChangeInvoiceItemsDefinedAmount = (value, data) => {
    let __invoiceItemsDefined = [...this.state.invoiceItemsDefined];
    if (__invoiceItemsDefined && __invoiceItemsDefined.length > 0) {
      for (
        let itemsDefinedIndex = 0;
        itemsDefinedIndex < __invoiceItemsDefined.length;
        itemsDefinedIndex++
      ) {
        // eslint-disable-next-line react/no-direct-mutation-state
        if (__invoiceItemsDefined[itemsDefinedIndex].id === data.id) {
          __invoiceItemsDefined[itemsDefinedIndex].amount = value;
        }
      }
    }
    this.setState(
      {
        invoiceItemsDefined: __invoiceItemsDefined,
        invoiceItems: __invoiceItemsDefined,
      },
      () => {
        console.log("state", this.state);
      }
    );
  };

  handleOk = () => {
    let data = {
      clientId: "00000000-0000-0000-0000-000000000000",
      title: this.state.title,
      addNewLines:
        this.state.invoiceItemsList && this.state.invoiceItemsList.invoiceItems,
    };
    if (this.state.title != "") {
      this.setState({ loading: true });
      this.props
        .onAddInvoiceTemplate(data)
        .then((res) => {
          this.props.onGetInvoiceTemplate();
          this.setState({ title: "", isModalVisible: false, loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    }
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  getAddNewLine = () => {
    return (
      <div>
        {this.state.invoiceItemsDefined.length > 0 ? (
          <Form
            onFinish={() => {
              setTimeout(() => {
                this.onFinishAddNewLines();
              }, 500);
            }}
            ref={this.formRefTempAddLine}
            initialValues={this.state.invoiceItemsDefined}
          >
            <Form.List name="invoiceItems">
              {(fields, { add, remove }) => (
                <>
                  <Row>
                    <Col xs={23} className="add-new-line-container">
                      <Row style={{ padding: "4px" }}>
                        <Col xs={24} className="add-new-line-header">
                          <Row>
                            <Col xs={2}>
                              <h5
                                style={{
                                  textAlign: "center",
                                  marginTop: "5px",
                                }}
                              >
                                {"SR#"}
                              </h5>
                            </Col>
                            <Col xs={16}>
                              <h5
                                style={{
                                  textAlign: "center",
                                  marginTop: "5px",
                                }}
                              >
                                {"DESCRIPTION"}
                              </h5>
                            </Col>
                            <Col xs={3}>
                              <h5
                                style={{
                                  textAlign: "center",
                                  marginTop: "5px",
                                }}
                              >
                                {"AMOUNT"}
                              </h5>
                            </Col>
                            <Col xs={3}>
                              <h5
                                style={{
                                  textAlign: "center",
                                  marginTop: "5px",
                                }}
                              >
                                {"ACTION"}
                              </h5>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {this.state &&
                        this.state.invoiceItemsDefined &&
                        this.state.invoiceItemsDefined.map((data, index) => {
                          return (
                            <Row key={data.id}>
                              <Col xs={2}>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  {index + 1}
                                </p>
                              </Col>
                              <Col xs={16}>
                                <div className="add-new-line-inputs">
                                  <TextArea
                                    maxLength={2000}
                                    rows={4}
                                    defaultValue={data.description}
                                    onChange={(e) =>
                                      this.handleChangeInvoiceItemsDefinedDescription(
                                        e,
                                        data
                                      )
                                    }
                                  />
                                </div>
                              </Col>
                              <Col xs={3} className="add-new-line-inputs">
                                <InputNumber
                                  defaultValue={data.amount}
                                  onChange={(value) =>
                                    this.handleChangeInvoiceItemsDefinedAmount(
                                      value,
                                      data
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          );
                        })}
                      {fields.map((field, index) => (
                        <Row key={field.key}>
                          <Col xs={2}>
                            <p
                              style={{ textAlign: "center", fontSize: "16px" }}
                            >
                              {this.state &&
                                this.state.invoiceItemsDefined &&
                                this.state.invoiceItemsDefined.length +
                                  1 +
                                  index}
                            </p>
                          </Col>
                          <Col xs={16}>
                            <div className="add-new-line-inputs">
                              <Form.Item
                                {...field}
                                name={[field.name, "description"]}
                                fieldKey={[field.fieldKey, "description"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <TextArea maxLength={2000} rows={4} />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={3}>
                            <div
                              className="add-new-line-inputs"
                              onClick={() => {
                                this.setState({ fieldKey: field }, () => {
                                  console.log("field key", this.state.fieldKey);
                                });
                              }}
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, "amount"]}
                                fieldKey={[field.fieldKey, "amount"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Required!",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                      if (value < 0) {
                                        return Promise.reject(
                                          "Type only positive numbers"
                                        );
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                          </Col>

                          <Col xs={3}>
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            >
                              <DeleteOutlined />
                            </div>
                          </Col>
                        </Row>
                      ))}
                      <Row>
                        <Col
                          xs={6}
                          offset={18}
                          style={{ paddingLeft: "10px", marginTop: "6px" }}
                        >
                          <Button
                            size="small"
                            type="primary"
                            htmlType="submit"
                            className="button-blue"
                            // style={{
                            //   display: fields.length > 0 ? "flex" : "none"
                            // }}
                            onClick={() =>
                              this.setState({
                                buttonType: "total",
                                calculated: false,
                              })
                            }
                          >
                            Calculate Sub Total
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <Form.Item>
                        <div
                          className="add-new-line-button"
                          onClick={() => {
                            add();
                          }}
                        >
                          <p>{"ADD NEW LINE"}</p>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col xs={4}>
                      <Form.Item>
                        <Button
                          className="add-new-line-button"
                          style={{ marginTop: 6, width: 167, paddingTop: 0 }}
                          htmlType="submit"
                          disabled={
                            this.state.selectedTemp == "" ? true : false
                          }
                          onClick={() => {
                            this.setState({ buttonType: "Template" });
                            // add();
                          }}
                        >
                          <p>ADD NEW TEMPLATE</p>
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Form>
        ) : (
          <Form
            onFinish={this.onFinishAddNewLines}
            ref={this.formRefTempAddLine}
          >
            <Form.List name="invoiceItems">
              {(fields, { add, remove }) => (
                <>
                  <Row>
                    <Col xs={23} className="add-new-line-container">
                      <Row style={{ padding: "4px" }}>
                        <Col xs={24} className="add-new-line-header">
                          <Row>
                            <Col xs={2}>
                              <h5
                                style={{
                                  textAlign: "center",
                                  marginTop: "5px",
                                }}
                              >
                                {"SR#"}
                              </h5>
                            </Col>
                            <Col xs={16}>
                              <h5
                                style={{
                                  textAlign: "center",
                                  marginTop: "5px",
                                }}
                              >
                                {"DESCRIPTION"}
                              </h5>
                            </Col>
                            <Col xs={3}>
                              <h5
                                style={{
                                  textAlign: "center",
                                  marginTop: "5px",
                                }}
                              >
                                {"AMOUNT"}
                              </h5>
                            </Col>
                            <Col xs={3}>
                              <h5
                                style={{
                                  textAlign: "center",
                                  marginTop: "5px",
                                }}
                              >
                                {"ACTION"}
                              </h5>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {this.state &&
                        this.state.invoiceItemsDefined &&
                        this.state.invoiceItemsDefined.map((data, index) => {
                          return (
                            <Row key={data.id}>
                              <Col xs={2}>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  {index + 1}
                                </p>
                              </Col>
                              <Col xs={16}>
                                <div className="add-new-line-inputs">
                                  <TextArea
                                    maxLength={2000}
                                    rows={4}
                                    defaultValue={data.description}
                                    onChange={(e) =>
                                      this.handleChangeInvoiceItemsDefinedDescription(
                                        e,
                                        data
                                      )
                                    }
                                  />
                                </div>
                              </Col>
                              <Col xs={3} className="add-new-line-inputs">
                                <InputNumber
                                  defaultValue={data.amount}
                                  onChange={(value) =>
                                    this.handleChangeInvoiceItemsDefinedAmount(
                                      value,
                                      data
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          );
                        })}
                      {fields.map((field, index) => (
                        <Row key={field.key}>
                          <Col xs={2}>
                            <p
                              style={{ textAlign: "center", fontSize: "16px" }}
                            >
                              {this.state &&
                                this.state.invoiceItemsDefined &&
                                this.state.invoiceItemsDefined.length +
                                  1 +
                                  index}
                            </p>
                          </Col>
                          <Col xs={16}>
                            <div className="add-new-line-inputs">
                              <Form.Item
                                {...field}
                                name={[field.name, "description"]}
                                fieldKey={[field.fieldKey, "description"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Required!",
                                  },
                                ]}
                              >
                                <TextArea maxLength={2000} rows={4} />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={3}>
                            <div
                              className="add-new-line-inputs"
                              onClick={() => {
                                this.setState({ fieldKey: field }, () => {
                                  console.log("field key", this.state.fieldKey);
                                });
                              }}
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, "amount"]}
                                fieldKey={[field.fieldKey, "amount"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Required!",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                      if (value < 0) {
                                        return Promise.reject(
                                          "Type only positive numbers"
                                        );
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                              >
                                <InputNumber />
                              </Form.Item>
                            </div>
                          </Col>

                          <Col xs={3}>
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            >
                              <DeleteOutlined />
                            </div>
                          </Col>
                        </Row>
                      ))}
                      <Row>
                        <Col
                          xs={6}
                          offset={18}
                          style={{ paddingLeft: "10px", marginTop: "6px" }}
                        >
                          <Button
                            size="small"
                            type="primary"
                            htmlType="submit"
                            className="button-blue"
                            // style={{
                            //   display: fields.length > 0 ? "flex" : "none"
                            // }}
                            onClick={() =>
                              this.setState({
                                buttonType: "total",
                                calculated: false,
                              })
                            }
                          >
                            Calculate Sub Total
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <Form.Item>
                        <div
                          className="add-new-line-button"
                          onClick={() => {
                            add();
                          }}
                        >
                          <p>{"ADD NEW LINE"}</p>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col xs={4}>
                      <Form.Item>
                        <Button
                          className="add-new-line-button"
                          style={{ marginTop: 6, width: 167, paddingTop: 0 }}
                          htmlType="submit"
                          onClick={() => {
                            this.setState({ buttonType: "Template" });
                            // add();
                          }}
                        >
                          <p>ADD NEW TEMPLATE</p>
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Form>
        )}
        <Modal
          title="Template Name"
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText="SAVE"
        >
          <div className="profile-input-border">
            <input
              className="profile-input"
              placeholder="Name"
              type="text"
              value={this.state.title}
              style={{ width: "100%" }}
              onChange={(e) => this.setState({ title: e.target.value })}
            />
          </div>
        </Modal>
      </div>
    );
  };

  handleChangeSecondaryAddressCheck = (e) => {
    this.setState({ showSecondaryEmail: e.target.checked });
  };

  onChangeTaxDropdownValue = (data) => {
    let _taxDropdownValue = data === "" ? null : JSON.parse(data);
    this.setState(
      {
        taxValueDropdown: data === "" ? 0 : _taxDropdownValue.percent,
        gst: this.state.taxInclusive
          ? 0
          : data === ""
          ? 0
          : _taxDropdownValue.percent,
        taxName: data === "" ? "" : _taxDropdownValue.name,
      },
      () => {
        this.formRef.current.setFieldsValue({
          gst: this.state.gst,
        });
        let totalAmount;
        let addedAmount;
        if (this.state.discount === 0 && this.state.discountAmount === 0) {
          addedAmount = (this.state.gst * this.state.subTotal) / 100;
          totalAmount = addedAmount + this.state.subTotal;
        } else {
          let cal;
          if (this.state.discount === 0) {
            cal = this.state.subTotal - this.state.discountAmount;
          } else {
            cal =
              this.state.subTotal -
              (this.state.discount * this.state.subTotal) / 100;
          }
          addedAmount = (this.state.gst * cal) / 100;
          totalAmount = addedAmount + cal;
        }

        this.setState({ _amount: totalAmount, qTotal: totalAmount }, () => {
          this.setState(
            {
              dueAmount:
                Math.round(
                  (this.state._amount -
                    this.state.invoicePaymentsAmountTotal +
                    Number.EPSILON) *
                    100
                ) / 100,
            },
            () => {
              console.log(
                "state",
                this.state._amount - this.state.invoicePaymentsAmountTotal
              );
            }
          );
          // this.setState({ total: this.state._amount})
          let totalDec =
            Math.round((this.state._amount + Number.EPSILON) * 100) / 100;
          this.formRef.current.setFieldsValue({
            total: totalDec,
            dueAmount:
              Math.round(
                (this.state._amount -
                  this.state.invoicePaymentsAmountTotal +
                  Number.EPSILON) *
                  100
              ) / 100,
          });
        });
      }
    );
  };

  handleChangeTaxInclusiveCheck = (e) => {
    this.setState({ taxInclusive: e.target.checked }, () => {
      this.formRef.current.setFieldsValue({
        gst:
          !this.state.taxInclusive && this.state.taxValueDropdown !== 0
            ? this.state.taxValueDropdown
            : 0,
      });
      this.setState(
        {
          gst:
            !this.state.taxInclusive && this.state.taxValueDropdown !== 0
              ? this.state.taxValueDropdown
              : 0,
        },
        () => {
          let totalAmount;
          let addedAmount;
          if (this.state.discount === 0 && this.state.discountAmount === 0) {
            addedAmount = (this.state.gst * this.state.subTotal) / 100;
            totalAmount = addedAmount + this.state.subTotal;
          } else {
            let cal;
            if (this.state.discount === 0) {
              cal = this.state.subTotal - this.state.discountAmount;
            } else {
              cal =
                this.state.subTotal -
                (this.state.discount * this.state.subTotal) / 100;
            }
            addedAmount = (this.state.gst * cal) / 100;
            totalAmount = addedAmount + cal;
          }

          this.setState({ _amount: totalAmount, qTotal: totalAmount }, () => {
            this.setState(
              {
                dueAmount:
                  Math.round(
                    (this.state._amount -
                      this.state.invoicePaymentsAmountTotal +
                      Number.EPSILON) *
                      100
                  ) / 100,
              },
              () => {
                console.log(
                  "state",
                  this.state._amount - this.state.invoicePaymentsAmountTotal
                );
              }
            );
            // this.setState({ total: this.state._amount})
            let totalDec =
              Math.round((this.state._amount + Number.EPSILON) * 100) / 100;
            this.formRef.current.setFieldsValue({
              total: totalDec,
              dueAmount:
                Math.round(
                  (this.state._amount -
                    this.state.invoicePaymentsAmountTotal +
                    Number.EPSILON) *
                    100
                ) / 100,
            });
          });
        }
      );
      if (this.state.discount === 0) {
        let finalTotalAfterTaxInclusive =
          this.state.subTotal - this.state.discountAmount;
        let totalDec =
          Math.round((finalTotalAfterTaxInclusive + Number.EPSILON) * 100) /
          100;
        this.setState(
          {
            _amount: finalTotalAfterTaxInclusive,
            total: totalDec,
            dueAmount:
              Math.round(
                (finalTotalAfterTaxInclusive -
                  this.state.invoicePaymentsAmountTotal +
                  Number.EPSILON) *
                  100
              ) / 100,
          },
          () => {
            let totalDecField =
              Math.round((this.state._amount + Number.EPSILON) * 100) / 100;
            this.formRef.current.setFieldsValue({
              total: totalDecField,
              dueAmount:
                Math.round((this.state.dueAmount + Number.EPSILON) * 100) / 100,
            });
          }
        );
      }
      if (this.state.discountAmount === 0) {
        let _discountedAmount =
          (this.state.discount * this.state.subTotal) / 100;
        let finalTotalAfterTaxInclusive =
          this.state.subTotal - _discountedAmount;
        let totalDec =
          Math.round((finalTotalAfterTaxInclusive + Number.EPSILON) * 100) /
          100;
        this.setState(
          {
            _amount: finalTotalAfterTaxInclusive,
            total: totalDec,
            dueAmount:
              Math.round(
                (finalTotalAfterTaxInclusive -
                  this.state.invoicePaymentsAmountTotal +
                  Number.EPSILON) *
                  100
              ) / 100,
          },
          () => {
            let totalDecField =
              Math.round((this.state._amount + Number.EPSILON) * 100) / 100;
            this.formRef.current.setFieldsValue({
              total: totalDecField,
              dueAmount:
                Math.round((this.state.dueAmount + Number.EPSILON) * 100) / 100,
            });
          }
        );
      }
    });
  };

  handleChangeDiscountPercentage = (value) => {
    let _discountedAmount;
    let __discountedAmount;
    if (value === null) {
      this.formRef.current.setFieldsValue({
        discount: 0,
      });
      let _tempAmount;
      let subtotalTaxPercentage = (this.state.subTotal * this.state.gst) / 100;
      _tempAmount = this.state.subTotal + subtotalTaxPercentage;
      let totalDec = Math.round((_tempAmount + Number.EPSILON) * 100) / 100;
      this.setState(
        {
          _amount: _tempAmount,
          discount: value,
          total: totalDec,
        },
        () => {
          let _dueCal = _tempAmount - this.state.invoicePaymentsAmountTotal;

          this.setState(
            {
              dueAmount: Math.round((_dueCal + Number.EPSILON) * 100) / 100,
            },
            () => {
              let totalDecField =
                Math.round((this.state._amount + Number.EPSILON) * 100) / 100;
              this.formRef.current.setFieldsValue({
                total: totalDecField,
                dueAmount:
                  Math.round((this.state.dueAmount + Number.EPSILON) * 100) /
                  100,
              });
            }
          );
        }
      );
    } else {
      let _tempAmount;
      _discountedAmount = (value * this.state.subTotal) / 100;
      __discountedAmount = this.state.subTotal - _discountedAmount;
      let discountedAmountPercentage =
        (__discountedAmount * this.state.gst) / 100;
      _tempAmount = __discountedAmount + discountedAmountPercentage;
      let totalDec = Math.round((_tempAmount + Number.EPSILON) * 100) / 100;
      this.setState(
        {
          _amount: _tempAmount,
          discount: value,
          total: totalDec,
          dueAmount:
            Math.round(
              (_tempAmount -
                this.state.invoicePaymentsAmountTotal +
                Number.EPSILON) *
                100
            ) / 100,
        },
        () => {
          let totalDecField =
            Math.round((this.state._amount + Number.EPSILON) * 100) / 100;
          this.formRef.current.setFieldsValue({
            total: totalDecField,
            dueAmount:
              Math.round((this.state.dueAmount + Number.EPSILON) * 100) / 100,
          });
        }
      );
    }
    this.formRef.current.setFieldsValue({
      discountAmount: 0,
    });
    this.setState({ discountAmount: 0, qTotal: 0 });
  };

  handleChangeDiscountAmount = (value) => {
    let ___discountedAmount;
    let _tempAmount;
    if (value === null) {
      this.formRef.current.setFieldsValue({
        discountAmount: 0,
      });
      let subtotalTaxPercentage = (this.state.subTotal * this.state.gst) / 100;
      _tempAmount = this.state.subTotal + subtotalTaxPercentage;
      let totalDec = Math.round((_tempAmount + Number.EPSILON) * 100) / 100;
      this.setState(
        {
          _amount: _tempAmount,
          discountAmount: value,
          total: totalDec,
          dueAmount:
            Math.round(
              (_tempAmount -
                this.state.invoicePaymentsAmountTotal +
                Number.EPSILON) *
                100
            ) / 100,
        },
        () => {
          let totalDecField =
            Math.round((this.state._amount + Number.EPSILON) * 100) / 100;
          this.formRef.current.setFieldsValue({
            total: totalDecField,
            dueAmount:
              Math.round((this.state.dueAmount + Number.EPSILON) * 100) / 100,
          });
        }
      );
    } else {
      ___discountedAmount = this.state.subTotal - value;
      let discountedAmountPercentage =
        (___discountedAmount * this.state.gst) / 100;
      _tempAmount = ___discountedAmount + discountedAmountPercentage;
      let totalDec = Math.round((_tempAmount + Number.EPSILON) * 100) / 100;
      this.setState(
        {
          _amount: _tempAmount,
          discountAmount: value,
          total: totalDec,
          dueAmount:
            Math.round(
              (_tempAmount -
                this.state.invoicePaymentsAmountTotal +
                Number.EPSILON) *
                100
            ) / 100,
        },
        () => {
          let totalDecField =
            Math.round((this.state._amount + Number.EPSILON) * 100) / 100;
          this.formRef.current.setFieldsValue({
            total: totalDecField,
            dueAmount:
              Math.round((this.state.dueAmount + Number.EPSILON) * 100) / 100,
          });
        }
      );
    }
    this.formRef.current.setFieldsValue({
      discount: 0,
    });
    this.setState({ discount: 0, qTotal: 0 });
  };

  handleChangeTaxPercentage = (value) => {
    if (value === null) {
      this.formRef.current.setFieldsValue({
        gst: 0,
      });
    }
    let addedAmount;

    this.setState({ gst: value }, () => {});
    let totalAmount;
    if (this.state.discount === 0 && this.state.discountAmount === 0) {
      addedAmount = (value * this.state.subTotal) / 100;
      totalAmount = addedAmount + this.state.subTotal;
    } else {
      let cal;
      if (this.state.discount === 0) {
        cal = this.state.subTotal - this.state.discountAmount;
      } else {
        cal =
          this.state.subTotal -
          (this.state.discount * this.state.subTotal) / 100;
      }
      addedAmount = (value * cal) / 100;
      totalAmount = addedAmount + cal;
    }

    this.setState({ _amount: totalAmount, qTotal: totalAmount }, () => {
      let totalDec =
        Math.round((this.state._amount + Number.EPSILON) * 100) / 100;
      this.setState(
        {
          dueAmount:
            Math.round(
              (this.state._amount -
                this.state.invoicePaymentsAmountTotal +
                Number.EPSILON) *
                100
            ) / 100,
          total: totalDec,
        },
        () => {
          console.log(
            "state",
            this.state._amount - this.state.invoicePaymentsAmountTotal
          );
        }
      );
      // this.setState({ total: this.state._amount})
      this.formRef.current.setFieldsValue({
        total: totalDec,
        dueAmount:
          Math.round(
            (this.state._amount -
              this.state.invoicePaymentsAmountTotal +
              Number.EPSILON) *
              100
          ) / 100,
      });
    });
  };

  getForm = (_invoiceInformation) => {
    return (
      <div>
        <Row style={{ marginTop: "17px", marginLeft: "17px" }}>
          <Col xs={7}>
            <div
              style={{
                backgroundColor: "white",
                border: "1px solid #ccc",
              }}
              contentEditable="false"
            >
              {this.state.notesImageData && this.state.notesImageData[1] ? (
                <img
                  src={
                    this.state.notesImageData && this.state.notesImageData[1]
                  }
                  alt={""}
                  style={{
                    height: "20vh",
                    objectFit: "contain",
                    marginLeft: "4%",
                    marginTop: "4%",
                  }}
                />
              ) : null}
              <TextArea
                className="success"
                style={{ border: "transparent", resize: "none" }}
                value={this.state.notes}
                rows={
                  _invoiceInformation &&
                  _invoiceInformation.id &&
                  this.state.notesImageData &&
                  this.state.notesImageData[1]
                    ? 8
                    : _invoiceInformation &&
                      _invoiceInformation.id &&
                      (this.state.notesImageData === "" ||
                        this.state.notesImageData === null)
                    ? 18
                    : this.state.notesImageData && this.state.notesImageData[1]
                    ? 6
                    : 16
                }
                onChange={this.handleChangeNotes}
              />
            </div>
          </Col>
          <Col xs={8} offset={1}>
            <Row>
              <Col xs={24}>
                <Select
                  placeholder="Please Select Notes!"
                  size="small"
                  onChange={(value) => {
                    let html = value;

                    let re = /src=([^">]+)/g;
                    let results = re.exec(html);
                    let _text = html.split("src")[0];
                    this.setState({ notes: _text, notesImageData: results });
                  }}
                >
                  <Option value={""}>{"Please select note!"}</Option>
                  {this.state &&
                    this.state.noteListing &&
                    this.state.noteListing.map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return <Option value={data.note}>{data.name}</Option>;
                    })}
                </Select>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="subTotal"
                  colon={false}
                  labelAlign="left"
                  label="Sub Total"
                >
                  <InputNumber size="small" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  {...this.layout}
                  name="currencyId"
                  colon={false}
                  labelAlign="left"
                  label="Currency"
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    showArrow
                    onChange={(value) => {
                      this.setState({ currencyId: value });
                    }}
                    virtual={false}
                    defaultValue={
                      this.props && this.props.currencyListing ? 106 : undefined
                    }
                    placeholder="Please Select Currency!"
                    size="small"
                  >
                    {this.props &&
                      this.props.currencyListing &&
                      this.props.currencyListing.map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return (
                          <Option value={data.id}>{data.currencyName}</Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {this.state.isTaxFieldVisible ? (
              <div>
                <Row style={{ marginTop: "10px" }}>
                  <Col xs={24}>
                    <Checkbox
                      onChange={this.handleChangeTaxInclusiveCheck}
                      checked={this.state.taxInclusive}
                    >
                      {<span className={"filter-text"}>Tax inclusive</span>}
                    </Checkbox>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col xs={24}>
                    <Form.Item name="taxName">
                      <Select
                        virtual={false}
                        placeholder="Please Select Tax!"
                        size="small"
                        onChange={this.onChangeTaxDropdownValue}
                      >
                        <Option value={""}>{"Please select tax!"}</Option>
                        {this.state &&
                          this.state.taxListing &&
                          this.state.taxListing.map((data) => {
                            // eslint-disable-next-line react/jsx-no-undef
                            return (
                              <Option
                                value={JSON.stringify(data)}
                                key={data.id}
                              >
                                {data.name}-{data.percent}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col xs={24}>
                    <Form.Item
                      name="gst"
                      colon={false}
                      labelAlign="left"
                      label="Tax %"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (value < 0) {
                              return Promise.reject(
                                "Type only positive numbers"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        size="small"
                        disabled={!!this.state.taxInclusive}
                        onChange={this.handleChangeTaxPercentage}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ) : null}

            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item name="bankAccount" colon={false} labelAlign="left">
                  <Select
                    showSearch
                    onChange={(value) => {
                      this.setState({ bankAccount: value });
                    }}
                    optionFilterProp="children"
                    showArrow
                    virtual={false}
                    placeholder="Please Select Bank Account!"
                    size="small"
                  >
                    {this.props &&
                      this.props.bankListing &&
                      this.props.bankListing.map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return <Option value={data.id}>{data.name}</Option>;
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="discount"
                  colon={false}
                  labelAlign="left"
                  label="Discount %"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value < 0) {
                          return Promise.reject("Type only positive numbers");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    size="small"
                    onChange={this.handleChangeDiscountPercentage}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="discountAmount"
                  colon={false}
                  labelAlign="left"
                  label="Discount Amount"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value < 0) {
                          return Promise.reject("Type only positive numbers");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    size="small"
                    onChange={this.handleChangeDiscountAmount}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item
                  name="total"
                  colon={false}
                  labelAlign="left"
                  label="Total"
                >
                  <InputNumber size="small" disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            {this.state.invoiceId !== 0 ? (
              <Row style={{ marginTop: "10px" }}>
                <Col xs={24}>
                  <Form.Item
                    name="dueAmount"
                    colon={false}
                    labelAlign="left"
                    label="Due"
                  >
                    <InputNumber size="small" disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
        {_invoiceInformation && _invoiceInformation.id ? (
          <Row>
            <Col
              xs={24}
              style={{
                marginLeft: "17px",
                marginBottom: "26px",
                marginTop: "35px",
              }}
            >
              <Checkbox
                onChange={this.handleChangeSecondaryAddressCheck}
                checked={this.state.showSecondaryEmail}
              >
                {
                  <span className={"filter-text"}>
                    Send email to secondary address
                  </span>
                }
              </Checkbox>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  };

  onClickExportToPDF = () => {
    let _invoiceInfo = this.props && this.props.invoiceInfo;
    let _childrenTotalAmount =
      this.state.childrenTotalAmount + this.state.total;
    let _statusId;
    if (
      _invoiceInfo &&
      _invoiceInfo.invoiceTypeId === 2 &&
      _invoiceInfo.statusId === 27
    ) {
      _statusId = _invoiceInfo.statusId;
    } else {
      if (this.state.dueAmount !== 0 && this.state.dueAmount > 0) {
        if (
          this.state.invoiceStatuses &&
          this.state.invoiceStatuses.length > 0
        ) {
          _statusId = this.state.invoiceStatuses[0].id;
        }
      } else {
        _statusId = this.state.invoiceStatuses[4].id;
      }
    }
    this.setState({ statusId: _statusId }, () => {
      if (this.state.invoiceTypeId === 0) {
        message.error("Please select Invoice type Id!");
      } else if (
        this.state.dateStr === "" ||
        this.state.dateStr === "Invalid date"
      ) {
        message.error("Please select date!");
      } else if (
        this.state.dueDate === "" ||
        this.state.dueDate === "Invalid date"
      ) {
        message.error("Please select due date!");
      } else if (this.state.invoiceNo === "") {
        message.error("Please add Invoice no!");
      } else if (this.state.subTotal === 0) {
        message.error("Sub total is 0!");
      } else if (
        this.props.parentInvoiceId !== 0 &&
        _childrenTotalAmount > this.state.parentTotalAmount
      ) {
        message.error(
          "Sum of all child invoices amount can never be greater than parent invoice amount!"
        );
      } else if (this.state.discountAmount > this.state.subTotal) {
        message.error("Discount amount must be less than total amount!");
      } else {
        let _data = {
          invoiceNo: this.state.invoiceNo,
        };
        this.props.checkDuplicateClientInvoice(_data).then(() => {
          let _duplicate = this.props && this.props.duplicate;
          if (
            _duplicate === true &&
            this.state.invoiceNo !== _invoiceInfo.invoiceNo
          ) {
            message.error("Invoice no must be unique!");
          } else {
            this.setState({ loadingEmailPopUp: true }, () => {
              if (this.state.selectedTemp) {
                var idsList = { items: this.state.ids };
                const delItemsOpt = {
                  url: `v1/invoice/item/DeleteAllInvoiceItem`,
                };
                delItemsOpt.types = [
                  "DELETE_INVOICE_ITEMS_SUCCESS",
                  "DELETE_INVOICE_ITEMS_FAILURE",
                ];
                apiRefresh.delete(delItemsOpt, idsList).then((res) => {});
              } else {
                this.props.editInvoice(this.state).then(() => {
                  this.props
                    .getInvoiceInfo(this.state.invoiceId)
                    .then((res) => {
                      let tempInvoiceItems =
                        res &&
                        res.payload &&
                        res.payload.invoiceItems &&
                        res.payload.invoiceItems;
                      this.setState(
                        {
                          invoiceItems: tempInvoiceItems,
                          invoiceItemsDefined: tempInvoiceItems,
                        },
                        () => {
                          this.formRefTempAddLine.current.setFieldsValue({
                            invoiceItems: undefined,
                          });
                          message.success("Invoice is updated successfully!");
                        }
                      );
                    })
                    .catch(() => {
                      this.setState({ loadingEmailPopUp: false });
                    });
                  let _emailTemplatePayload = {
                    templateName: "invoice",
                    parameters: [
                      {
                        key: "ClientId",
                        value: this.state.clientProfileId,
                      },
                      {
                        key: "CurrencyId",
                        value: this.state.currencyId
                          ? this.state.currencyId.toString()
                          : "106",
                      },
                      {
                        key: "UserId",
                        value: this.state.userId,
                      },
                      {
                        key: "Content",
                        value: "Invoice",
                      },
                      {
                        key: "Amount",
                        value: this.state.dueAmount.toString(),
                      },
                      {
                        key: "InvoiceNumber",
                        value: this.state.invoiceNo,
                      },
                      {
                        key: "DueDate",
                        value: this.state.dueDate,
                      },
                    ],
                  };
                  this.props
                    .setEmailTemplate(_emailTemplatePayload)
                    .then(() => {
                      let _setEmailData = this.props.setEmailData;

                      this.setState({ setEmailData: _setEmailData }, () => {
                        this.setState({}, () => {
                          this.setState(
                            {
                              currentPaymentId: 0,
                              isPDFRelatedToInvoice: true,
                              showInvoiceTitle: true,
                            },
                            () => {
                              let _data = {
                                invoiceId: this.state.invoiceId,
                                paymentId: this.state.currentPaymentId,
                              };
                              this.props
                                .getInvoicePDFHtmlBlob(_data)
                                .then((res) => {
                                  let formData = new FormData();

                                  let binaryString = window.atob(
                                    res.payload.url
                                  );
                                  let binaryLen = binaryString.length;
                                  let bytes = new Uint8Array(binaryLen);
                                  for (let i = 0; i < binaryLen; i++) {
                                    let ascii = binaryString.charCodeAt(i);
                                    bytes[i] = ascii;
                                  }
                                  let _blob = new Blob([bytes], {
                                    type: "application/pdf",
                                  });
                                  let metadata = {
                                    type: "application/pdf",
                                  };
                                  let file = new File(
                                    [_blob],
                                    res.payload.name,
                                    metadata
                                  );

                                  formData.append(
                                    "BranchId",
                                    localStorage.getItem("selectedBranchId")
                                  );

                                  formData.append("files", file);
                                  this.props
                                    .onAddEmailDocument(formData)
                                    .then((res) => {
                                      let _attData = res && res.payload;
                                      let attDataArray = [
                                        {
                                          name: _attData[0].fileName,
                                          type: _attData[0].contentType,
                                          url: _attData[0].fileUrl,
                                        },
                                      ];

                                      this.setState({
                                        attDataArray: attDataArray,
                                        loadingEmailPopUp: false,
                                        visibleSendingReceiptModal: true,
                                      });
                                    })
                                    .catch(() => {
                                      this.setState({ loading: false });
                                    });
                                })
                                .catch(() => {
                                  this.setState({ loadingEmailPopUp: false });
                                });
                            }
                          );
                        });
                      });
                    })
                    .catch(() => {
                      this.setState({ loadingEmailPopUp: false });
                    });
                });
              }
            });
          }
        });
      }
    });
  };

  getSentStatus = async (id) => {
    await this.setState({ statusId: id });
  };

  getButtons = (invoiceInformation) => {
    return (
      <div>
        <Row style={{ marginLeft: "17px" }}>
          <Col xs={24} style={{ display: "flex" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "14px" }}
              disabled={this.state.isDisabled || this.state.calculated}
              className="button-blue"
            >
              {invoiceInformation && invoiceInformation.id
                ? "UPDATE"
                : "SAVE INVOICE"}
            </Button>
            {invoiceInformation && invoiceInformation.id ? (
              <Button
                type="primary"
                style={{ marginRight: "14px" }}
                onClick={this.onClickExportToPDF}
                className="button-blue"
              >
                EXPORT TO PDF / EMAIL INVOICE
              </Button>
            ) : null}
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "14px" }}
              onClick={() => this.onClickPreview(invoiceInformation)}
              className="button-blue"
            >
              PREVIEW / DOWNLOAD
            </Button>
            {invoiceInformation &&
            invoiceInformation.id &&
            this.state.dueAmount > 0 ? (
              <Button
                type="primary"
                className="button-blue"
                onClick={this.onClickFollowUpEmail}
              >
                FOLLOW-UP EMAIL
              </Button>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  };

  onClickFollowUpEmail = () => {
    this.setState({
      showInvoiceTitle: true,
      visibleFollowUpEmailPopUp: true,
    });
  };

  onClickPreview = (invoiceInformation) => {
    let _data = {
      id: this.state.invoiceId,
      paymentId: 0,
    };
    if (invoiceInformation && invoiceInformation.id) {
      this.setState({ loadingEmailPopUp: true, isPDFRelatedToInvoice: true });
      this.props
        .getPDF(_data)
        .then((response) => {
          let blob = new Blob([response.payload], {
            type: "application/octet-stream",
          });
          this.setState({ loadingEmailPopUp: false });
          saveAs(blob, `Invoice-${this.state.invoiceNo}.pdf`);
        })
        .catch((e) => {
          this.setState({ loadingEmailPopUp: false });
          message.error("Unable to download, please try again!");
        });
    } else {
      let _childrenTotalAmount =
        this.state.childrenTotalAmount + this.state.total;
      if (this.state.invoiceTypeId === 0) {
        message.error("Please select Invoice type Id!");
      } else if (
        this.state.dateStr === "" ||
        this.state.dateStr === "Invalid date"
      ) {
        message.error("Please select date!");
      } else if (
        this.state.dueDate === "" ||
        this.state.dueDate === "Invalid date"
      ) {
        message.error("Please select due date!");
      } else if (this.state.invoiceNo === "") {
        message.error("Please add Invoice no!");
      } else if (
        this.state.invoiceItems.length === 0 ||
        this.state.subTotal === 0
      ) {
        message.error("Subtotal is 0!");
      } else if (
        this.props.parentInvoiceId !== 0 &&
        _childrenTotalAmount > this.state.parentTotalAmount
      ) {
        message.error(
          "Sum of all child invoices amount can never be greater than parent invoice amount!"
        );
      } else if (this.state.discountAmount > this.state.subTotal) {
        message.error("Discount amount must be less than total amount!");
      } else {
        let _data = {
          invoiceNo: this.state.invoiceNo,
        };
        this.setState({ loadingEmailPopUp: true, isPDFRelatedToInvoice: true });
        this.props.checkDuplicateClientInvoice(_data).then(() => {
          let _duplicate = this.props && this.props.duplicate;
          if (_duplicate === true) {
            message.error("Invoice no must be unique!");
          } else {
            this.props
              .getInvoicePDF(this.state)
              .then((response) => {
                let blob = new Blob([response.payload], {
                  type: "application/octet-stream",
                });
                this.setState({ loadingEmailPopUp: false });
                saveAs(blob, `Invoice-${this.state.invoiceNo}.pdf`);
              })
              .catch((e) => {
                this.setState({ loadingEmailPopUp: false });
                message.error("Unable to download, please try again!");
              });
          }
        });
      }
    }
  };

  onFinish = (values) => {
    let _subTotal =
      values && values.subTotal === undefined ? 0 : parseFloat(values.subTotal);
    let _currencyId =
      values && values.currencyId === undefined ? 0 : values.currencyId;
    let _gst = values && values.gst === undefined ? 0 : values.gst;
    let _bankAccount =
      values && values.bankAccount === undefined ? 0 : values.bankAccount;
    let _discount;
    if (
      (values && values.discount === undefined) ||
      (values && values.discount === null)
    ) {
      _discount = 0;
    } else {
      _discount = values.discount;
    }
    let _discountAmount;
    if (
      (values && values.discountAmount === undefined) ||
      (values && values.discountAmount === null)
    ) {
      _discountAmount = 0;
    } else {
      _discountAmount = values.discountAmount;
    }
    let _total =
      values && values.total === undefined ? 0 : parseFloat(values.total);
    let _dueAmount =
      values && values.dueAmount === undefined
        ? parseFloat(values.total)
        : parseFloat(values.dueAmount);
    let _invoiceInfo = this.props && this.props.invoiceInfo;
    let _statusId;
    if (
      _invoiceInfo &&
      _invoiceInfo.invoiceTypeId === 2 &&
      _invoiceInfo.statusId === 27
    ) {
      _statusId = _invoiceInfo.statusId;
    } else {
      if (this.state.dueAmount !== 0 && this.state.dueAmount > 0) {
        if (
          this.state.invoiceStatuses &&
          this.state.invoiceStatuses.length > 0
        ) {
          _statusId = this.state.invoiceStatuses[0].id;
        }
      } else {
        _statusId = this.state.invoiceStatuses[4].id;
      }
    }
    this.setState(
      {
        subTotal: _subTotal,
        currencyId: _currencyId,
        gst: _gst,
        bankAccount: _bankAccount,
        discount: _discount,
        discountAmount: _discountAmount,
        total: _total,
        dueAmount: _dueAmount,
        statusId: _statusId,
      },
      () => {
        let _childrenTotalAmount =
          this.state.childrenTotalAmount + this.state.total;
        let _invoiceInfo = this.props && this.props.invoiceInfo;
        if (this.state.invoiceId !== 0) {
          if (this.state.invoiceTypeId === 0) {
            message.error("Please select Invoice type Id!");
          } else if (
            this.state.dateStr === "" ||
            this.state.dateStr === "Invalid date"
          ) {
            message.error("Please select date!");
          } else if (
            this.state.dueDate === "" ||
            this.state.dueDate === "Invalid date"
          ) {
            message.error("Please select due date!");
          } else if (this.state.invoiceNo === "") {
            message.error("Please add Invoice no!");
          } else if (this.state.subTotal === 0) {
            message.error("Sub total is 0!");
          } else if (
            this.props.parentInvoiceId !== 0 &&
            _childrenTotalAmount > this.state.parentTotalAmount
          ) {
            message.error(
              "Sum of all child invoices amount can never be greater than parent invoice amount!"
            );
          } else if (this.state.discountAmount > this.state.subTotal) {
            message.error("Discount amount must be less than total amount!");
          } else {
            let _data = {
              invoiceNo: this.state.invoiceNo,
            };
            this.props.checkDuplicateClientInvoice(_data).then(() => {
              let _duplicate = this.props && this.props.duplicate;
              if (
                _duplicate === true &&
                this.state.invoiceNo !== _invoiceInfo.invoiceNo
              ) {
                message.error("Invoice no must be unique!");
              } else {
                this.setState({ loadingEmailPopUp: true }, () => {
                  if (this.state.selectedTemp) {
                    var idsList = { items: this.state.ids };
                    const delItemsOpt = {
                      url: `v1/invoice/item/DeleteAllInvoiceItem`,
                    };
                    delItemsOpt.types = [
                      "DELETE_INVOICE_ITEMS_SUCCESS",
                      "DELETE_INVOICE_ITEMS_FAILURE",
                    ];
                    apiRefresh.delete(delItemsOpt, idsList).then((res) => {
                      this.props
                        .editInvoice(this.state)
                        .then(() => {
                          let tcData = {
                            title: "Invoice",
                            reminderDate: this.state.dueDate
                              ? moment(this.state.dueDate).format(
                                  "YYYY-MM-DDT00:00:00+00:00"
                                )
                              : "1900-01-01T00:00:00+00:00",
                            typeId: 14,
                          };
                          AddAutoReminder(tcData);
                          this.props
                            .getInvoiceInfo(this.state.invoiceId)
                            .then((res) => {
                              let tempInvoiceItems =
                                res &&
                                res.payload &&
                                res.payload.invoiceItems &&
                                res.payload.invoiceItems;
                              this.setState(
                                {
                                  invoiceItems: tempInvoiceItems,
                                  invoiceItemsDefined: tempInvoiceItems,
                                },
                                () => {
                                  this.formRefTempAddLine.current.setFieldsValue(
                                    {
                                      invoiceItems: undefined,
                                    }
                                  );
                                  this.props
                                    .getInvoicePayments(this.state.invoiceId)
                                    .then(() => {
                                      this.setState({
                                        loadingEmailPopUp: false,
                                      });
                                    })
                                    .catch(() => {
                                      this.setState({
                                        loadingEmailPopUp: false,
                                      });
                                    });
                                  message.success(
                                    "Invoice is updated successfully!"
                                  );
                                }
                              );
                            })
                            .catch(() => {
                              this.setState({ loadingEmailPopUp: false });
                            });
                        })
                        .catch(() => {
                          this.setState({ loadingEmailPopUp: false });
                        });
                    });
                  } else {
                    this.props
                      .editInvoice(this.state)
                      .then(() => {
                        let tcData = {
                          title: "Invoice",
                          reminderDate: this.state.dueDate
                            ? moment(this.state.dueDate).format(
                                "YYYY-MM-DDT00:00:00+00:00"
                              )
                            : "1900-01-01T00:00:00+00:00",
                          typeId: 14,
                        };
                        AddAutoReminder(tcData);
                        this.props
                          .getInvoiceInfo(this.state.invoiceId)
                          .then((res) => {
                            let tempInvoiceItems =
                              res &&
                              res.payload &&
                              res.payload.invoiceItems &&
                              res.payload.invoiceItems;
                            this.setState(
                              {
                                invoiceItems: tempInvoiceItems,
                                invoiceItemsDefined: tempInvoiceItems,
                              },
                              () => {
                                this.formRefTempAddLine.current.setFieldsValue({
                                  invoiceItems: undefined,
                                });
                                this.props
                                  .getInvoicePayments(this.state.invoiceId)
                                  .then(() => {
                                    this.setState({ loadingEmailPopUp: false });
                                  })
                                  .catch(() => {
                                    this.setState({ loadingEmailPopUp: false });
                                  });
                                message.success(
                                  "Invoice is updated successfully!"
                                );
                              }
                            );
                          })
                          .catch(() => {
                            this.setState({ loadingEmailPopUp: false });
                          });
                      })
                      .catch(() => {
                        this.setState({ loadingEmailPopUp: false });
                      });
                  }
                });
              }
            });
          }
        } else {
          if (this.state.invoiceTypeId === 0) {
            message.error("Please select Invoice type Id!");
          } else if (
            this.state.dateStr === "" ||
            this.state.dateStr === "Invalid date"
          ) {
            message.error("Please select date!");
          } else if (
            this.state.dueDate === "" ||
            this.state.dueDate === "Invalid date"
          ) {
            message.error("Please select due date!");
          } else if (this.state.invoiceNo === "") {
            message.error("Please add Invoice no!");
          } else if (
            this.state.invoiceItems.length === 0 ||
            this.state.subTotal === 0
          ) {
            message.error("Subtotal is 0!");
          } else if (
            this.props.parentInvoiceId !== 0 &&
            _childrenTotalAmount > this.state.parentTotalAmount
          ) {
            message.error(
              "Sum of all child invoices amount can never be greater than parent invoice amount!"
            );
          } else if (this.state.discountAmount > this.state.subTotal) {
            message.error("Discount amount must be less than total amount!");
          } else {
            let _data = {
              invoiceNo: this.state.invoiceNo,
            };
            this.props.checkDuplicateClientInvoice(_data).then(() => {
              let _duplicate = this.props && this.props.duplicate;
              if (_duplicate === true) {
                message.error("Invoice no must be unique!");
              } else {
                this.setState({ isDisabled: true, loadingEmailPopUp: true });
                this.props
                  .addNewInvoice(this.state)
                  .then(() => {
                    this.setState({ loadingEmailPopUp: false });
                    message.success("Invoice is added successfully!");
                    this.props.getChildInfo(0, 0, 0, "invoices");
                    let userName = localStorage.getItem("userName");
                    var profileData = JSON.parse(this.props.profileDataRdx);
                    if (profileData) {
                      let myData = {
                        clientName: profileData.fullName,
                        logMessage:
                          "invoice " +
                          this.state.invoiceNo +
                          " added by " +
                          userName,
                        date: moment(new Date()).format("DD/MM/YYYY"),
                        logType: "Client Invoice",
                        invoiceId: "0",
                      };
                      activityData(myData);
                    }

                    let tcData = {
                      title: "Invoice",
                      reminderDate: this.state.dueDate
                        ? moment(this.state.dueDate).format(
                            "YYYY-MM-DDT00:00:00+00:00"
                          )
                        : "1900-01-01T00:00:00+00:00",
                      typeId: 14,
                    };
                    AddAutoReminder(tcData);
                  })
                  .catch(() => {
                    this.setState({
                      isDisabled: false,
                      loadingEmailPopUp: false,
                    });
                  });
              }
            });
          }
        }
      }
    );
  };

  handleChangePaymentAmount = (value) => {
    this.setState({ paymentAmount: value });
  };

  handlePaymentDateChange = (date, dateString) => {
    this.setState({ paymentDate: date }, () => {
      console.log("state", this.state);
    });
  };

  handleChangePaymentBank = (value) => {
    this.setState({ paymentBank: value });
  };

  handleChangePaymentDescription = (e) => {
    this.setState({ paymentDescription: e.target.value });
  };

  onFinishAddPaymentForm = (values) => {
    let _addedAmount;
    if (this.state.invoiceId !== 0) {
      let _invoiceInfo = this.props && this.props.invoiceInfo;
      let _statusId;
      if (
        _invoiceInfo &&
        _invoiceInfo.invoiceTypeId === 2 &&
        _invoiceInfo.statusId === 27
      ) {
        _statusId = _invoiceInfo.statusId;
      } else {
        if (this.state.dueAmount !== 0 && this.state.dueAmount > 0) {
          if (
            this.state.invoiceStatuses &&
            this.state.invoiceStatuses.length > 0
          ) {
            _statusId =
              this.state.showSendingReceiptModalForm === true
                ? this.state.invoiceStatuses[0].id
                : this.state.invoiceStatuses[0].id;
          }
        } else {
          _statusId =
            this.state.showSendingReceiptModalForm === true
              ? this.state.invoiceStatuses[4].id
              : this.state.invoiceStatuses[4].id;
        }
      }
      let _childrenTotalAmount =
        this.state.childrenTotalAmount + this.state.total;
      this.setState({ statusId: _statusId }, () => {
        if (this.state.invoiceTypeId === 0) {
          message.error("Please select Invoice type Id!");
        } else if (
          this.state.dateStr === "" ||
          this.state.dateStr === "Invalid date"
        ) {
          message.error("Please select date!");
        } else if (
          this.state.dueDate === "" ||
          this.state.dueDate === "Invalid date"
        ) {
          message.error("Please select due date!");
        } else if (this.state.invoiceNo === "") {
          message.error("Please add Invoice no!");
        } else if (this.state.subTotal === 0) {
          message.error("Sub total is 0!");
        } else if (
          this.props.parentInvoiceId !== 0 &&
          _childrenTotalAmount > this.state.parentTotalAmount
        ) {
          message.error(
            "Sum of all child invoices amount can never be greater than parent invoice amount!"
          );
        } else if (this.state.discountAmount > this.state.subTotal) {
          message.error("Discount amount must be less than total amount!");
        } else {
          let _data = {
            invoiceNo: this.state.invoiceNo,
          };
          this.props.checkDuplicateClientInvoice(_data).then(() => {
            let _duplicate = this.props && this.props.duplicate;
            if (
              _duplicate === true &&
              this.state.invoiceNo !== _invoiceInfo.invoiceNo
            ) {
              message.error("Invoice no must be unique!");
            } else {
              this.setState({ loading: true });
              if (this.state.selectedTemp) {
                var idsList = { items: this.state.ids };
                const delItemsOpt = {
                  url: `v1/invoice/item/DeleteAllInvoiceItem`,
                };
                delItemsOpt.types = [
                  "DELETE_INVOICE_ITEMS_SUCCESS",
                  "DELETE_INVOICE_ITEMS_FAILURE",
                ];
                apiRefresh.delete(delItemsOpt, idsList).then((res) => {
                  this.props
                    .editInvoice(this.state)
                    .then(() => {
                      this.props
                        .getInvoiceInfo(this.state.invoiceId)
                        .then((res) => {
                          let tempInvoiceItems =
                            res &&
                            res.payload &&
                            res.payload.invoiceItems &&
                            res.payload.invoiceItems;
                          this.setState(
                            {
                              invoiceItems: tempInvoiceItems,
                              invoiceItemsDefined: tempInvoiceItems,
                            },
                            () => {
                              this.formRefTempAddLine.current.setFieldsValue({
                                invoiceItems: undefined,
                              });
                              message.success(
                                "Invoice is updated successfully!"
                              );
                            }
                          );
                        })
                        .catch(() => {
                          this.setState({ loading: false });
                        });
                      this.props
                        .addInvoicePayment(this.state)
                        .then(() => {
                          _addedAmount = this.state.paymentAmount;
                          message.success("Payment is added successfully!");

                          this.setState(
                            {
                              paymentDescription: "",
                              paymentAmount: 0,
                              paymentDate: "",
                              paymentBank: 0,
                            },
                            () => {
                              // this.formRefTemp.current.setFieldsValue({
                              //   paymentAmount: undefined,
                              //   paymentDate: undefined,
                              //   paymentBank: undefined,
                              // });
                            }
                          );

                          let userName = localStorage.getItem("userName");
                          var profileData = JSON.parse(
                            this.props.profileDataRdx
                          );
                          if (profileData) {
                            let myData = {
                              clientName: profileData.fullName,
                              logMessage: "Payment added by " + userName,
                              date: moment(new Date()).format("DD/MM/YYYY"),
                              logType: "Client Invoice",
                              invoiceId: "0",
                            };
                            activityData(myData);
                          }

                          this.props
                            .getInvoicePayments(this.state.invoiceId)
                            .then(() => {
                              let _invoicePayments =
                                this.props && this.props.invoicePayments;
                              if (
                                _invoicePayments &&
                                _invoicePayments.length > 0
                              ) {
                                // let _addedAmount;
                                // for (
                                //   let indexInvoicePayments = 0;
                                //   indexInvoicePayments < _invoicePayments.length;
                                //   indexInvoicePayments++
                                // ) {
                                //   _addedAmount =
                                //     _invoicePayments[_invoicePayments.length - 1]
                                //       .amount;
                                // }

                                this.setState(
                                  {
                                    dueAmount:
                                      Math.round(
                                        (this.state.dueAmount -
                                          _addedAmount +
                                          Number.EPSILON) *
                                          100
                                      ) / 100,
                                  },
                                  () => {
                                    this.formRef.current.setFieldsValue({
                                      dueAmount: this.state.dueAmount,
                                    });
                                    let _invoiceInfo =
                                      this.props && this.props.invoiceInfo;
                                    let _statusId;
                                    if (
                                      _invoiceInfo &&
                                      _invoiceInfo.invoiceTypeId === 2 &&
                                      _invoiceInfo.statusId === 27
                                    ) {
                                      _statusId = _invoiceInfo.statusId;
                                    } else {
                                      if (
                                        this.state.dueAmount !== 0 &&
                                        this.state.dueAmount > 0
                                      ) {
                                        if (
                                          this.state.invoiceStatuses &&
                                          this.state.invoiceStatuses.length > 0
                                        ) {
                                          _statusId =
                                            this.state
                                              .showSendingReceiptModalForm ===
                                            true
                                              ? this.state.invoiceStatuses[0].id
                                              : this.state.invoiceStatuses[0]
                                                  .id;
                                        }
                                      } else {
                                        _statusId =
                                          this.state
                                            .showSendingReceiptModalForm ===
                                          true
                                            ? this.state.invoiceStatuses[4].id
                                            : this.state.invoiceStatuses[4].id;
                                      }
                                    }

                                    let _childrenTotalAmount =
                                      this.state.childrenTotalAmount +
                                      this.state.total;
                                    this.setState(
                                      { statusId: _statusId },
                                      () => {
                                        if (this.state.invoiceTypeId === 0) {
                                          message.error(
                                            "Please select Invoice type Id!"
                                          );
                                        } else if (
                                          this.state.dateStr === "" ||
                                          this.state.dateStr === "Invalid date"
                                        ) {
                                          message.error("Please select date!");
                                        } else if (
                                          this.state.dueDate === "" ||
                                          this.state.dueDate === "Invalid date"
                                        ) {
                                          message.error(
                                            "Please select due date!"
                                          );
                                        } else if (
                                          this.state.invoiceNo === ""
                                        ) {
                                          message.error(
                                            "Please add Invoice no!"
                                          );
                                        } else if (this.state.subTotal === 0) {
                                          message.error("Sub total is 0!");
                                        } else if (
                                          this.props.parentInvoiceId !== 0 &&
                                          _childrenTotalAmount >
                                            this.state.parentTotalAmount
                                        ) {
                                          message.error(
                                            "Sum of all child invoices amount can never be greater than parent invoice amount!"
                                          );
                                        } else if (
                                          this.state.discountAmount >
                                          this.state.subTotal
                                        ) {
                                          message.error(
                                            "Discount amount must be less than total amount!"
                                          );
                                        } else {
                                          let _data = {
                                            invoiceNo: this.state.invoiceNo,
                                          };
                                          this.props
                                            .checkDuplicateClientInvoice(_data)
                                            .then(() => {
                                              let _duplicate =
                                                this.props &&
                                                this.props.duplicate;
                                              if (
                                                _duplicate === true &&
                                                this.state.invoiceNo !==
                                                  _invoiceInfo.invoiceNo
                                              ) {
                                              } else {
                                                this.props
                                                  .editInvoice(this.state)
                                                  .then((res) => {
                                                    let _emailTemplatePayload = {
                                                      templateName: "recipt",
                                                      paymentId: 0,
                                                      invoiceId: this.state
                                                        .invoiceId,
                                                      parameters: [
                                                        {
                                                          key: "ClientId",
                                                          value: this.state
                                                            .clientProfileId,
                                                        },
                                                        {
                                                          key: "UserId",
                                                          value: this.state
                                                            .userId,
                                                        },
                                                        {
                                                          key: "CurrencyId",
                                                          value: this.state
                                                            .currencyId
                                                            ? this.state.currencyId.toString()
                                                            : "106",
                                                        },
                                                        {
                                                          key: "Amount",
                                                          value: this.state.dueAmount.toString(),
                                                        },
                                                        {
                                                          key: "InvoiceNumber",
                                                          value: this.state
                                                            .invoiceNo,
                                                        },
                                                      ],
                                                    };
                                                    if (
                                                      this.state
                                                        .showSendingReceiptModalForm ===
                                                      true
                                                    ) {
                                                      this.props
                                                        .setEmailTemplatePayment(
                                                          _emailTemplatePayload
                                                        )
                                                        .then(() => {
                                                          let _setEmailData = this
                                                            .props
                                                            .setEmailDataPayment;

                                                          this.setState(
                                                            {
                                                              setEmailData: _setEmailData,
                                                            },
                                                            () => {
                                                              this.setState(
                                                                {
                                                                  currentPaymentId: 0,
                                                                  isPDFRelatedToInvoice: false,
                                                                },
                                                                () => {
                                                                  let _data = {
                                                                    invoiceId: this
                                                                      .state
                                                                      .invoiceId,
                                                                    paymentId: this
                                                                      .state
                                                                      .currentPaymentId,
                                                                  };
                                                                  this.props
                                                                    .getInvoiceReceiptPDFHtmlBlob(
                                                                      _data
                                                                    )
                                                                    .then(
                                                                      (res) => {
                                                                        let formData = new FormData();

                                                                        let binaryString = window.atob(
                                                                          res
                                                                            .payload
                                                                            .url
                                                                        );
                                                                        let binaryLen =
                                                                          binaryString.length;
                                                                        let bytes = new Uint8Array(
                                                                          binaryLen
                                                                        );
                                                                        for (
                                                                          let i = 0;
                                                                          i <
                                                                          binaryLen;
                                                                          i++
                                                                        ) {
                                                                          let ascii = binaryString.charCodeAt(
                                                                            i
                                                                          );
                                                                          bytes[
                                                                            i
                                                                          ] = ascii;
                                                                        }
                                                                        let _blob = new Blob(
                                                                          [
                                                                            bytes,
                                                                          ],
                                                                          {
                                                                            type:
                                                                              "application/pdf",
                                                                          }
                                                                        );
                                                                        let metadata = {
                                                                          type:
                                                                            "application/pdf",
                                                                        };
                                                                        let file = new File(
                                                                          [
                                                                            _blob,
                                                                          ],
                                                                          res.payload.name,
                                                                          metadata
                                                                        );

                                                                        formData.append(
                                                                          "BranchId",
                                                                          localStorage.getItem(
                                                                            "selectedBranchId"
                                                                          )
                                                                        );

                                                                        formData.append(
                                                                          "files",
                                                                          file
                                                                        );
                                                                        this.props
                                                                          .onAddEmailDocument(
                                                                            formData
                                                                          )
                                                                          .then(
                                                                            (
                                                                              res
                                                                            ) => {
                                                                              let _attData =
                                                                                res &&
                                                                                res.payload;
                                                                              let attDataArray = [
                                                                                {
                                                                                  name:
                                                                                    _attData[0]
                                                                                      .fileName,
                                                                                  type:
                                                                                    _attData[0]
                                                                                      .contentType,
                                                                                  url:
                                                                                    _attData[0]
                                                                                      .fileUrl,
                                                                                },
                                                                              ];

                                                                              this.setState(
                                                                                {
                                                                                  attDataArray: attDataArray,
                                                                                  loading: false,
                                                                                  visibleSendingReceiptModal: true,
                                                                                }
                                                                              );
                                                                            }
                                                                          )
                                                                          .catch(
                                                                            () => {
                                                                              this.setState(
                                                                                {
                                                                                  loading: false,
                                                                                }
                                                                              );
                                                                            }
                                                                          );
                                                                      }
                                                                    )
                                                                    .catch(
                                                                      () => {
                                                                        this.setState(
                                                                          {
                                                                            loading: false,
                                                                          }
                                                                        );
                                                                      }
                                                                    );
                                                                }
                                                              );
                                                            }
                                                          );
                                                        })
                                                        .catch(() => {
                                                          this.setState({
                                                            loading: false,
                                                          });
                                                        });
                                                    } else {
                                                      this.setState({
                                                        visibleSendingReceiptModal: false,
                                                      });
                                                    }

                                                    // message.success("Invoice is updated successfully!");
                                                  })
                                                  .catch(() => {
                                                    this.setState({
                                                      loading: false,
                                                    });
                                                  });
                                              }
                                            })
                                            .catch(() => {
                                              this.setState({ loading: false });
                                            });
                                        }
                                      }
                                    );
                                  }
                                );
                              }
                            })
                            .catch(() => {
                              this.setState({ loading: false });
                            });
                        })
                        .catch(() => {
                          message.error("An error occurred!");
                          this.setState({ loading: false });
                        });
                    })
                    .catch(() => {
                      this.setState({ loading: false });
                    });
                });
              } else {
                this.props
                  .editInvoice(this.state)
                  .then(() => {
                    this.props
                      .getInvoiceInfo(this.state.invoiceId)
                      .then((res) => {
                        let tempInvoiceItems =
                          res &&
                          res.payload &&
                          res.payload.invoiceItems &&
                          res.payload.invoiceItems;
                        this.setState(
                          {
                            invoiceItems: tempInvoiceItems,
                            invoiceItemsDefined: tempInvoiceItems,
                          },
                          () => {
                            this.formRefTempAddLine.current.setFieldsValue({
                              invoiceItems: undefined,
                            });
                            message.success("Invoice is updated successfully!");
                          }
                        );
                      })
                      .catch(() => {
                        this.setState({ loading: false });
                      });
                    this.props
                      .addInvoicePayment(this.state)
                      .then(() => {
                        _addedAmount = this.state.paymentAmount;
                        message.success("Payment is added successfully!");

                        this.setState(
                          {
                            paymentDescription: "",
                            paymentAmount: 0,
                            paymentDate: "",
                            paymentBank: 0,
                          },
                          () => {
                            // this.formRefTemp.current.setFieldsValue({
                            //   paymentAmount: undefined,
                            //   paymentDate: undefined,
                            //   paymentBank: undefined,
                            // });
                          }
                        );

                        let userName = localStorage.getItem("userName");
                        var profileData = JSON.parse(this.props.profileDataRdx);
                        if (profileData) {
                          let myData = {
                            clientName: profileData.fullName,
                            logMessage: "Payment added by " + userName,
                            date: moment(new Date()).format("DD/MM/YYYY"),
                            logType: "Client Invoice",
                            invoiceId: "0",
                          };
                          activityData(myData);
                        }

                        this.props
                          .getInvoicePayments(this.state.invoiceId)
                          .then(() => {
                            let _invoicePayments =
                              this.props && this.props.invoicePayments;
                            if (
                              _invoicePayments &&
                              _invoicePayments.length > 0
                            ) {
                              // let _addedAmount;
                              // for (
                              //   let indexInvoicePayments = 0;
                              //   indexInvoicePayments < _invoicePayments.length;
                              //   indexInvoicePayments++
                              // ) {
                              //   _addedAmount =
                              //     _invoicePayments[_invoicePayments.length - 1]
                              //       .amount;
                              // }

                              this.setState(
                                {
                                  dueAmount:
                                    Math.round(
                                      (this.state.dueAmount -
                                        _addedAmount +
                                        Number.EPSILON) *
                                        100
                                    ) / 100,
                                },
                                () => {
                                  this.formRef.current.setFieldsValue({
                                    dueAmount: this.state.dueAmount,
                                  });
                                  let _invoiceInfo =
                                    this.props && this.props.invoiceInfo;
                                  let _statusId;
                                  if (
                                    _invoiceInfo &&
                                    _invoiceInfo.invoiceTypeId === 2 &&
                                    _invoiceInfo.statusId === 27
                                  ) {
                                    _statusId = _invoiceInfo.statusId;
                                  } else {
                                    if (
                                      this.state.dueAmount !== 0 &&
                                      this.state.dueAmount > 0
                                    ) {
                                      if (
                                        this.state.invoiceStatuses &&
                                        this.state.invoiceStatuses.length > 0
                                      ) {
                                        _statusId =
                                          this.state
                                            .showSendingReceiptModalForm ===
                                          true
                                            ? this.state.invoiceStatuses[0].id
                                            : this.state.invoiceStatuses[0].id;
                                      }
                                    } else {
                                      _statusId =
                                        this.state
                                          .showSendingReceiptModalForm === true
                                          ? this.state.invoiceStatuses[4].id
                                          : this.state.invoiceStatuses[4].id;
                                    }
                                  }

                                  let _childrenTotalAmount =
                                    this.state.childrenTotalAmount +
                                    this.state.total;
                                  this.setState({ statusId: _statusId }, () => {
                                    if (this.state.invoiceTypeId === 0) {
                                      message.error(
                                        "Please select Invoice type Id!"
                                      );
                                    } else if (
                                      this.state.dateStr === "" ||
                                      this.state.dateStr === "Invalid date"
                                    ) {
                                      message.error("Please select date!");
                                    } else if (
                                      this.state.dueDate === "" ||
                                      this.state.dueDate === "Invalid date"
                                    ) {
                                      message.error("Please select due date!");
                                    } else if (this.state.invoiceNo === "") {
                                      message.error("Please add Invoice no!");
                                    } else if (this.state.subTotal === 0) {
                                      message.error("Sub total is 0!");
                                    } else if (
                                      this.props.parentInvoiceId !== 0 &&
                                      _childrenTotalAmount >
                                        this.state.parentTotalAmount
                                    ) {
                                      message.error(
                                        "Sum of all child invoices amount can never be greater than parent invoice amount!"
                                      );
                                    } else if (
                                      this.state.discountAmount >
                                      this.state.subTotal
                                    ) {
                                      message.error(
                                        "Discount amount must be less than total amount!"
                                      );
                                    } else {
                                      let _data = {
                                        invoiceNo: this.state.invoiceNo,
                                      };
                                      this.props
                                        .checkDuplicateClientInvoice(_data)
                                        .then(() => {
                                          let _duplicate =
                                            this.props && this.props.duplicate;
                                          if (
                                            _duplicate === true &&
                                            this.state.invoiceNo !==
                                              _invoiceInfo.invoiceNo
                                          ) {
                                          } else {
                                            this.props
                                              .editInvoice(this.state)
                                              .then(() => {
                                                let _emailTemplatePayload = {
                                                  templateName: "recipt",
                                                  paymentId: 0,
                                                  invoiceId: this.state
                                                    .invoiceId,
                                                  parameters: [
                                                    {
                                                      key: "ClientId",
                                                      value: this.state
                                                        .clientProfileId,
                                                    },
                                                    {
                                                      key: "UserId",
                                                      value: this.state.userId,
                                                    },
                                                    {
                                                      key: "CurrencyId",
                                                      value: this.state
                                                        .currencyId
                                                        ? this.state.currencyId.toString()
                                                        : "106",
                                                    },
                                                    {
                                                      key: "Amount",
                                                      value: this.state.dueAmount.toString(),
                                                    },
                                                    {
                                                      key: "InvoiceNumber",
                                                      value: this.state
                                                        .invoiceNo,
                                                    },
                                                  ],
                                                };
                                                if (
                                                  this.state
                                                    .showSendingReceiptModalForm ===
                                                  true
                                                ) {
                                                  this.props
                                                    .setEmailTemplatePayment(
                                                      _emailTemplatePayload
                                                    )
                                                    .then(() => {
                                                      let _setEmailData = this
                                                        .props
                                                        .setEmailDataPayment;

                                                      this.setState(
                                                        {
                                                          setEmailData: _setEmailData,
                                                        },
                                                        () => {
                                                          this.setState(
                                                            {
                                                              currentPaymentId: 0,
                                                              isPDFRelatedToInvoice: false,
                                                            },
                                                            () => {
                                                              let _data = {
                                                                invoiceId: this
                                                                  .state
                                                                  .invoiceId,
                                                                paymentId: this
                                                                  .state
                                                                  .currentPaymentId,
                                                              };

                                                              this.props
                                                                .getInvoiceReceiptPDFHtmlBlob(
                                                                  _data
                                                                )
                                                                .then((res) => {
                                                                  let formData = new FormData();

                                                                  let binaryString = window.atob(
                                                                    res.payload
                                                                      .url
                                                                  );
                                                                  let binaryLen =
                                                                    binaryString.length;
                                                                  let bytes = new Uint8Array(
                                                                    binaryLen
                                                                  );
                                                                  for (
                                                                    let i = 0;
                                                                    i <
                                                                    binaryLen;
                                                                    i++
                                                                  ) {
                                                                    let ascii = binaryString.charCodeAt(
                                                                      i
                                                                    );
                                                                    bytes[
                                                                      i
                                                                    ] = ascii;
                                                                  }
                                                                  let _blob = new Blob(
                                                                    [bytes],
                                                                    {
                                                                      type:
                                                                        "application/pdf",
                                                                    }
                                                                  );
                                                                  let metadata = {
                                                                    type:
                                                                      "application/pdf",
                                                                  };
                                                                  let file = new File(
                                                                    [_blob],
                                                                    res.payload.name,
                                                                    metadata
                                                                  );

                                                                  formData.append(
                                                                    "BranchId",
                                                                    localStorage.getItem(
                                                                      "selectedBranchId"
                                                                    )
                                                                  );

                                                                  formData.append(
                                                                    "files",
                                                                    file
                                                                  );
                                                                  this.props
                                                                    .onAddEmailDocument(
                                                                      formData
                                                                    )
                                                                    .then(
                                                                      (res) => {
                                                                        let _attData =
                                                                          res &&
                                                                          res.payload;
                                                                        let attDataArray = [
                                                                          {
                                                                            name:
                                                                              _attData[0]
                                                                                .fileName,
                                                                            type:
                                                                              _attData[0]
                                                                                .contentType,
                                                                            url:
                                                                              _attData[0]
                                                                                .fileUrl,
                                                                          },
                                                                        ];

                                                                        this.setState(
                                                                          {
                                                                            attDataArray: attDataArray,
                                                                            loading: false,
                                                                            visibleSendingReceiptModal: true,
                                                                          }
                                                                        );
                                                                      }
                                                                    )
                                                                    .catch(
                                                                      () => {
                                                                        this.setState(
                                                                          {
                                                                            loading: false,
                                                                          }
                                                                        );
                                                                      }
                                                                    );
                                                                })
                                                                .catch(() => {
                                                                  this.setState(
                                                                    {
                                                                      loading: false,
                                                                    }
                                                                  );
                                                                });
                                                            }
                                                          );
                                                        }
                                                      );
                                                    })
                                                    .catch(() => {
                                                      this.setState({
                                                        loading: false,
                                                      });
                                                    });
                                                } else {
                                                  this.setState({
                                                    visibleSendingReceiptModal: false,
                                                  });
                                                }

                                                // message.success("Invoice is updated successfully!");
                                              })
                                              .catch(() => {
                                                this.setState({
                                                  loading: false,
                                                });
                                              });
                                          }
                                        })
                                        .catch(() => {
                                          this.setState({ loading: false });
                                        });
                                    }
                                  });
                                }
                              );
                            }
                          })
                          .catch(() => {
                            this.setState({ loading: false });
                          });
                      })
                      .catch(() => {
                        message.error("An error occurred!");
                        this.setState({ loading: false });
                      });
                  })
                  .catch(() => {
                    this.setState({ loading: false });
                  });
              }
            }
          });
        }
      });
    }
  };

  onClickAddPaymentSendReceipt = (values) => {
    this.setState({
      showInvoiceTitle: false,
      showSendingReceiptModalForm: true,
    });
  };

  onClickAddPayment = (values) => {
    this.setState({ showSendingReceiptModalForm: false });
  };

  getMakePaymentForm = () => {
    return (
      <Row>
        <Col xs={24}>
          {this.state.loading ? (
            <div className={"spinner"}>
              <Spin size="large" />
            </div>
          ) : (
            <Form onFinish={this.onFinishAddPaymentForm} ref={this.formRefTemp}>
              <div className="ca-gray-cont ca-gray-padding">
                <Row style={{ marginBottom: "4px" }}>
                  <Col xs={10}>
                    <p className="payment-label">{"Amount"}</p>
                  </Col>
                  <Col xs={14}>
                    <Form.Item
                      name="paymentAmount"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (value < 0) {
                              return Promise.reject(
                                "Type only positive numbers"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        onChange={this.handleChangePaymentAmount}
                        style={{ width: "-webkit-fill-available" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "4px" }}>
                  <Col xs={10}>
                    <p className="payment-label">{"Date"}</p>
                  </Col>
                  <Col xs={14}>
                    <Form.Item
                      name="paymentDate"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        onChange={this.handlePaymentDateChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={10}>
                    <p className="payment-label">{"Bank Account"}</p>
                  </Col>
                  <Col xs={14}>
                    <Form.Item name="paymentBank">
                      <Select
                        placeholder="Please Select Bank Account!"
                        onChange={this.handleChangePaymentBank}
                      >
                        {this.props &&
                          this.props.bankListing &&
                          this.props.bankListing.map((data) => {
                            // eslint-disable-next-line react/jsx-no-undef
                            return <Option value={data.id}>{data.name}</Option>;
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={10}>
                    <p className="payment-label">{"Description"}</p>
                  </Col>
                  <Col xs={14}>
                    <Input
                      value={this.state.paymentDescription}
                      onChange={this.handleChangePaymentDescription}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <div className="payment-buttons-container">
                      <Button
                        style={{ marginRight: "13px" }}
                        type="primary"
                        htmlType="submit"
                        onClick={this.onClickAddPayment}
                        className="button-blue"
                      >
                        {"ADD PAYMENT"}
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="button-blue"
                        onClick={this.onClickAddPaymentSendReceipt}
                      >
                        {"ADD PAYMENT AND SEND RECEIPT"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    );
  };

  getPaymentHistoryTable = () => {
    return (
      <Row>
        <Col xs={24} className={"school-table"}>
          {this.state.loading ? (
            <div className={"spinner"}>
              <Spin size="large" />
            </div>
          ) : (
            <div className="ca-gray-cont ca-gray-padding-payment-history">
              <Table
                dataSource={
                  this.state.invoicePayments
                    ? this.state.invoicePayments.sort((a, b) =>
                        a.receivedDate.localeCompare(b.receivedDate)
                      )
                    : null
                }
                columns={this.paymentColumns}
                pagination={false}
              />
            </div>
          )}
        </Col>
      </Row>
    );
  };

  handleCancelDeleteModal = (e) => {
    this.setState({
      visibleDeleteModal: false,
    });
  };

  showModalDelete = (id, amount) => {
    this.setState({
      visibleDeleteModal: true,
      index: id,
      deletedPayment: amount,
    });
  };

  handleDeletePayment = (id) => {
    this.props.deleteInvoicePayment(id).then(() => {
      message.success("Payment deleted successfully!");
      let userName = localStorage.getItem("userName");
      var profileData = JSON.parse(sessionStorage.getItem("profileData"));
      let myData = {
        clientName: profileData.fullName,
        logMessage:
          "Payment for " + this.state.invoiceNo + " deleted by " + userName,
        date: moment(new Date()).format("DD/MM/YYYY"),
        logType: "Client Invoice",
        invoiceId: "0",
      };
      activityData(myData);
      this.setState(
        {
          visibleDeleteModal: false,
          dueAmount:
            Math.round(
              (this.state.dueAmount +
                this.state.deletedPayment +
                Number.EPSILON) *
                100
            ) / 100,
        },
        () => {
          this.formRef.current.setFieldsValue({
            dueAmount: this.state.dueAmount,
          });
          let _invoiceInfo = this.props && this.props.invoiceInfo;
          let _statusId;
          if (
            _invoiceInfo &&
            _invoiceInfo.invoiceTypeId === 2 &&
            _invoiceInfo.statusId === 27
          ) {
            _statusId = _invoiceInfo.statusId;
          } else {
            if (this.state.dueAmount !== 0 && this.state.dueAmount > 0) {
              if (
                this.state.invoiceStatuses &&
                this.state.invoiceStatuses.length > 0
              ) {
                _statusId = this.state.invoiceStatuses[0].id;
              }
            } else {
              _statusId = this.state.invoiceStatuses[4].id;
            }
          }

          let _childrenTotalAmount =
            this.state.childrenTotalAmount + this.state.total;
          this.setState({ statusId: _statusId }, () => {
            if (this.state.invoiceTypeId === 0) {
              message.error("Please select Invoice type Id!");
            } else if (
              this.state.dateStr === "" ||
              this.state.dateStr === "Invalid date"
            ) {
              message.error("Please select date!");
            } else if (
              this.state.dueDate === "" ||
              this.state.dueDate === "Invalid date"
            ) {
              message.error("Please select due date!");
            } else if (this.state.invoiceNo === "") {
              message.error("Please add Invoice no!");
            } else if (this.state.subTotal === 0) {
              message.error("Sub total is 0!");
            } else if (
              this.props.parentInvoiceId !== 0 &&
              _childrenTotalAmount > this.state.parentTotalAmount
            ) {
              message.error(
                "Sum of all child invoices amount can never be greater than parent invoice amount!"
              );
            } else if (this.state.discountAmount > this.state.subTotal) {
              message.error("Discount amount must be less than total amount!");
            } else {
              let _data = {
                invoiceNo: this.state.invoiceNo,
              };
              this.props.checkDuplicateClientInvoice(_data).then(() => {
                let _duplicate = this.props && this.props.duplicate;
                if (
                  _duplicate === true &&
                  this.state.invoiceNo !== _invoiceInfo.invoiceNo
                ) {
                  message.error("Invoice no must be unique!");
                } else {
                  if (this.state.selectedTemp) {
                    var idsList = { items: this.state.ids };
                    const delItemsOpt = {
                      url: `v1/invoice/item/DeleteAllInvoiceItem`,
                    };
                    delItemsOpt.types = [
                      "DELETE_INVOICE_ITEMS_SUCCESS",
                      "DELETE_INVOICE_ITEMS_FAILURE",
                    ];
                    apiRefresh.delete(delItemsOpt, idsList).then((res) => {
                      this.props.editInvoice(this.state).then(() => {
                        this.props
                          .getInvoiceInfo(this.state.invoiceId)
                          .then((res) => {
                            let tempInvoiceItems =
                              res &&
                              res.payload &&
                              res.payload.invoiceItems &&
                              res.payload.invoiceItems;
                            this.setState(
                              {
                                invoiceItems: tempInvoiceItems,
                                invoiceItemsDefined: tempInvoiceItems,
                              },
                              () => {
                                this.formRefTempAddLine.current.setFieldsValue({
                                  invoiceItems: undefined,
                                });
                                message.success(
                                  "Invoice is updated successfully!"
                                );
                              }
                            );
                          })
                          .catch(() => {});

                        let userName = localStorage.getItem("userName");
                        var profileData = JSON.parse(this.props.profileDataRdx);
                        if (profileData) {
                          let myData = {
                            clientName: profileData.fullName,
                            logMessage: "Invoice updated by " + userName,
                            date: moment(new Date()).format("DD/MM/YYYY"),
                            logType: "Client Invoice",
                            invoiceId: "0",
                          };
                          activityData(myData);
                        }
                      });
                    });
                  } else {
                    this.props.editInvoice(this.state).then(() => {
                      this.props
                        .getInvoiceInfo(this.state.invoiceId)
                        .then((res) => {
                          let tempInvoiceItems =
                            res &&
                            res.payload &&
                            res.payload.invoiceItems &&
                            res.payload.invoiceItems;
                          this.setState(
                            {
                              invoiceItems: tempInvoiceItems,
                              invoiceItemsDefined: tempInvoiceItems,
                            },
                            () => {
                              this.formRefTempAddLine.current.setFieldsValue({
                                invoiceItems: undefined,
                              });
                              message.success(
                                "Invoice is updated successfully!"
                              );
                            }
                          );
                        })
                        .catch(() => {});

                      let userName = localStorage.getItem("userName");
                      var profileData = JSON.parse(this.props.profileDataRdx);
                      if (profileData) {
                        let myData = {
                          clientName: profileData.fullName,
                          logMessage: "Invoice updated by " + userName,
                          date: moment(new Date()).format("DD/MM/YYYY"),
                          logType: "Client Invoice",
                          invoiceId: "0",
                        };
                        activityData(myData);
                      }
                    });
                  }
                }
              });
            }
          });
        }
      );
      this.props.getInvoicePayments(this.state.invoiceId);
    });
  };

  handleClickOkFollowUpEmail = () => {
    let _invoiceInfo = this.props && this.props.invoiceInfo;
    let _statusId;
    if (
      _invoiceInfo &&
      _invoiceInfo.invoiceTypeId === 2 &&
      _invoiceInfo.statusId === 27
    ) {
      _statusId = _invoiceInfo.statusId;
    } else {
      if (this.state.dueAmount !== 0 && this.state.dueAmount > 0) {
        if (
          this.state.invoiceStatuses &&
          this.state.invoiceStatuses.length > 0
        ) {
          _statusId = this.state.invoiceStatuses[0].id;
        }
      } else {
        _statusId = this.state.invoiceStatuses[4].id;
      }
    }

    let _childrenTotalAmount =
      this.state.childrenTotalAmount + this.state.total;
    this.setState({ statusId: _statusId }, () => {
      if (this.state.invoiceTypeId === 0) {
        message.error("Please select Invoice type Id!");
      } else if (
        this.state.dateStr === "" ||
        this.state.dateStr === "Invalid date"
      ) {
        message.error("Please select date!");
      } else if (
        this.state.dueDate === "" ||
        this.state.dueDate === "Invalid date"
      ) {
        message.error("Please select due date!");
      } else if (this.state.invoiceNo === "") {
        message.error("Please add Invoice no!");
      } else if (this.state.subTotal === 0) {
        message.error("Sub total is 0!");
      } else if (
        this.props.parentInvoiceId !== 0 &&
        _childrenTotalAmount > this.state.parentTotalAmount
      ) {
        message.error(
          "Sum of all child invoices amount can never be greater than parent invoice amount!"
        );
      } else if (this.state.discountAmount > this.state.subTotal) {
        message.error("Discount amount must be less than total amount!");
      } else {
        let _data = {
          invoiceNo: this.state.invoiceNo,
        };
        this.props.checkDuplicateClientInvoice(_data).then(() => {
          let _duplicate = this.props && this.props.duplicate;
          if (
            _duplicate === true &&
            this.state.invoiceNo !== _invoiceInfo.invoiceNo
          ) {
            message.error("Invoice no must be unique!");
          } else {
            this.setState({ loadingEmailPopUp: true }, () => {
              if (this.state.selectedTemp) {
                var idsList = { items: this.state.ids };
                const delItemsOpt = {
                  url: `v1/invoice/item/DeleteAllInvoiceItem`,
                };
                delItemsOpt.types = [
                  "DELETE_INVOICE_ITEMS_SUCCESS",
                  "DELETE_INVOICE_ITEMS_FAILURE",
                ];
                apiRefresh.delete(delItemsOpt, idsList).then((res) => {
                  this.props.editInvoice(this.state).then(() => {
                    this.props
                      .getInvoiceInfo(this.state.invoiceId)
                      .then((res) => {
                        let tempInvoiceItems =
                          res &&
                          res.payload &&
                          res.payload.invoiceItems &&
                          res.payload.invoiceItems;
                        this.setState(
                          {
                            invoiceItems: tempInvoiceItems,
                            invoiceItemsDefined: tempInvoiceItems,
                          },
                          () => {
                            this.formRefTempAddLine.current.setFieldsValue({
                              invoiceItems: undefined,
                            });
                            message.success("Invoice is updated successfully!");
                          }
                        );
                      })
                      .catch(() => {
                        this.setState({ loadingEmailPopUp: false });
                      });
                    let _emailTemplatePayload = {
                      templateName: "invoicefu",
                      parameters: [
                        {
                          key: "ClientId",
                          value: this.state.clientProfileId,
                        },
                        {
                          key: "CurrencyId",
                          value: this.state.currencyId
                            ? this.state.currencyId.toString()
                            : "106",
                        },
                        {
                          key: "UserId",
                          value: this.state.userId,
                        },
                        {
                          key: "Content",
                          value: "Follow-up",
                        },
                        {
                          key: "Amount",
                          value: this.state.dueAmount.toString(),
                        },
                        {
                          key: "InvoiceNumber",
                          value: this.state.invoiceNo,
                        },
                        {
                          key: "DueDate",
                          value: this.state.dueDate,
                        },
                      ],
                    };
                    this.props
                      .setEmailTemplate(_emailTemplatePayload)
                      .then(() => {
                        let _setEmailData = this.props.setEmailData;

                        this.setState({ setEmailData: _setEmailData }, () => {
                          this.setState({}, () => {
                            this.setState(
                              {
                                currentPaymentId: 0,
                                visibleFollowUpEmailPopUp: false,
                                isPDFRelatedToInvoice: false,
                              },
                              () => {
                                let _data = {
                                  invoiceId: this.state.invoiceId,
                                  paymentId: this.state.currentPaymentId,
                                };
                                this.props
                                  .getInvoicePDFHtmlBlob(_data)
                                  .then((res) => {
                                    let formData = new FormData();

                                    let binaryString = window.atob(
                                      res.payload.url
                                    );
                                    let binaryLen = binaryString.length;
                                    let bytes = new Uint8Array(binaryLen);
                                    for (let i = 0; i < binaryLen; i++) {
                                      let ascii = binaryString.charCodeAt(i);
                                      bytes[i] = ascii;
                                    }
                                    let _blob = new Blob([bytes], {
                                      type: "application/pdf",
                                    });
                                    let metadata = {
                                      type: "application/pdf",
                                    };
                                    let file = new File(
                                      [_blob],
                                      res.payload.name,
                                      metadata
                                    );

                                    formData.append(
                                      "BranchId",
                                      localStorage.getItem("selectedBranchId")
                                    );

                                    formData.append("files", file);
                                    this.props
                                      .onAddEmailDocument(formData)
                                      .then((res) => {
                                        let _attData = res && res.payload;
                                        let attDataArray = [
                                          {
                                            name: _attData[0].fileName,
                                            type: _attData[0].contentType,
                                            url: _attData[0].fileUrl,
                                          },
                                        ];

                                        this.setState({
                                          attDataArray: attDataArray,
                                          loadingEmailPopUp: false,
                                          visibleSendingReceiptModal: true,
                                        });
                                      })
                                      .catch(() => {
                                        this.setState({
                                          loadingEmailPopUp: false,
                                        });
                                      });
                                  })
                                  .catch(() => {
                                    this.setState({ loadingEmailPopUp: false });
                                  });
                              }
                            );
                          });
                        });
                      })
                      .catch(() => {
                        this.setState({ loadingEmailPopUp: false });
                      });
                  });
                });
              } else {
                this.props.editInvoice(this.state).then(() => {
                  this.props
                    .getInvoiceInfo(this.state.invoiceId)
                    .then((res) => {
                      let tempInvoiceItems =
                        res &&
                        res.payload &&
                        res.payload.invoiceItems &&
                        res.payload.invoiceItems;
                      this.setState(
                        {
                          invoiceItems: tempInvoiceItems,
                          invoiceItemsDefined: tempInvoiceItems,
                        },
                        () => {
                          this.formRefTempAddLine.current.setFieldsValue({
                            invoiceItems: undefined,
                          });
                          message.success("Invoice is updated successfully!");
                        }
                      );
                    })
                    .catch(() => {
                      this.setState({ loadingEmailPopUp: false });
                    });
                  let _emailTemplatePayload = {
                    templateName: "invoicefu",
                    parameters: [
                      {
                        key: "ClientId",
                        value: this.state.clientProfileId,
                      },
                      {
                        key: "CurrencyId",
                        value: this.state.currencyId
                          ? this.state.currencyId.toString()
                          : "106",
                      },
                      {
                        key: "UserId",
                        value: this.state.userId,
                      },
                      {
                        key: "Content",
                        value: "Follow-up",
                      },
                      {
                        key: "Amount",
                        value: this.state.dueAmount.toString(),
                      },
                      {
                        key: "InvoiceNumber",
                        value: this.state.invoiceNo,
                      },
                      {
                        key: "DueDate",
                        value: this.state.dueDate,
                      },
                    ],
                  };
                  this.props
                    .setEmailTemplate(_emailTemplatePayload)
                    .then(() => {
                      let _setEmailData = this.props.setEmailData;

                      this.setState({ setEmailData: _setEmailData }, () => {
                        this.setState({}, () => {
                          this.setState(
                            {
                              currentPaymentId: 0,
                              visibleFollowUpEmailPopUp: false,
                              isPDFRelatedToInvoice: false,
                            },
                            () => {
                              let _data = {
                                invoiceId: this.state.invoiceId,
                                paymentId: this.state.currentPaymentId,
                              };
                              this.props
                                .getInvoicePDFHtmlBlob(_data)
                                .then((res) => {
                                  let formData = new FormData();

                                  let binaryString = window.atob(
                                    res.payload.url
                                  );
                                  let binaryLen = binaryString.length;
                                  let bytes = new Uint8Array(binaryLen);
                                  for (let i = 0; i < binaryLen; i++) {
                                    let ascii = binaryString.charCodeAt(i);
                                    bytes[i] = ascii;
                                  }
                                  let _blob = new Blob([bytes], {
                                    type: "application/pdf",
                                  });
                                  let metadata = {
                                    type: "application/pdf",
                                  };
                                  let file = new File(
                                    [_blob],
                                    res.payload.name,
                                    metadata
                                  );

                                  formData.append(
                                    "BranchId",
                                    localStorage.getItem("selectedBranchId")
                                  );

                                  formData.append("files", file);
                                  this.props
                                    .onAddEmailDocument(formData)
                                    .then((res) => {
                                      let _attData = res && res.payload;
                                      let attDataArray = [
                                        {
                                          name: _attData[0].fileName,
                                          type: _attData[0].contentType,
                                          url: _attData[0].fileUrl,
                                        },
                                      ];

                                      this.setState({
                                        attDataArray: attDataArray,
                                        loadingEmailPopUp: false,
                                        visibleSendingReceiptModal: true,
                                      });
                                    })
                                    .catch(() => {
                                      this.setState({
                                        loadingEmailPopUp: false,
                                      });
                                    });
                                })
                                .catch(() => {
                                  this.setState({ loadingEmailPopUp: false });
                                });
                            }
                          );
                        });
                      });
                    })
                    .catch(() => {
                      this.setState({ loadingEmailPopUp: false });
                    });
                });
              }
            });
          }
        });
      }
    });
  };

  getModalFollowUpEmail = () => {
    return (
      <div>
        <Modal
          title=""
          visible={this.state.visibleFollowUpEmailPopUp}
          onCancel={this.handleCancelFollowUpEmailPopUp}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure you want to send invoice email again ?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={5} offset={17}>
              <Button
                onClick={() => {
                  this.handleCancelFollowUpEmailPopUp();
                }}
                style={{ borderRadius: "5px" }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={2}>
              <Button
                className={"button button-blue"}
                onClick={() => {
                  this.handleClickOkFollowUpEmail();
                }}
              >
                Ok
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleCancelFollowUpEmailPopUp = (e) => {
    this.setState({
      visibleFollowUpEmailPopUp: false,
    });
  };

  getModalDelete = () => {
    return (
      <div>
        <Modal
          title="Delete"
          visible={this.state.visibleDeleteModal}
          onCancel={this.handleCancelDeleteModal}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row> Are you sure, you want to delete payment?</Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={5} offset={15}>
              <Button
                onClick={() => {
                  this.handleCancelDeleteModal();
                }}
                style={{ borderRadius: "5px" }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button button-blue"}
                onClick={() => {
                  this.handleDeletePayment(this.state.index);
                }}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  handleCancelSendingReceiptModal = (e) => {
    this.setState(
      {
        visibleSendingReceiptModal: false,
      },
      () => {
        this.props.getInvoicePayments(this.state.invoiceId);
      }
    );
  };

  render() {
    let _gstPercent =
      (this.props &&
        this.props.companyData &&
        this.props.companyData.gstPercent) ||
      "0";
    let _invoiceInformation =
      this.props && this.props.invoiceInfo && this.props.invoiceInfo;
    return (
      <div>
        <Row>
          <Col xs={23} offset={1}>
            <h4 style={{ marginTop: "6px" }}>
              {_invoiceInformation && _invoiceInformation.id
                ? "UPDATE INVOICE"
                : "NEW INVOICE"}
            </h4>
          </Col>
        </Row>
        <Spin size="large" spinning={this.state.loadingEmailPopUp}>
          <Form
            onFinish={this.onFinish}
            ref={this.formRef}
            initialValues={{
              discount: this.state.discount,
              discountAmount: this.state.discountAmount,
              subTotal: this.state.subTotal,
              gst: parseInt(_gstPercent),
              total: this.state.subTotal,
              dueAmount: 0,
            }}
          >
            <div className="ca-gray-cont">
              <Row style={{ marginTop: "-15px", marginRight: "-21px" }}>
                <Col xs={11}>{this.getDatePickers()}</Col>
                <Col xs={13}>{this.getFilters()}</Col>
              </Row>
              <Row style={{ marginLeft: "10px" }}>
                <Col xs={12}>{this.getTemplateDropDown()}</Col>
              </Row>
              {this.getAddNewLine()}
              {this.getForm(_invoiceInformation)}
              {this.getButtons(_invoiceInformation)}
            </div>
            {this.state.invoiceId !== 0 ? (
              <div>
                <Row>
                  <Col xs={23} offset={1}>
                    <h4 style={{ marginTop: "6px" }}>{"MAKE A PAYMENT"}</h4>
                  </Col>
                </Row>
                {this.getMakePaymentForm()}
                <Row>
                  <Col xs={23} offset={1}>
                    <h4 style={{ marginTop: "6px" }}>{"PAYMENT HISTORY"}</h4>
                  </Col>
                </Row>
                {this.getPaymentHistoryTable()}
              </div>
            ) : null}
            {this.getModalDelete()}
            {this.getModalFollowUpEmail()}
          </Form>
        </Spin>
        {this.state.visibleSendingReceiptModal ? (
          <SendEmail
            fromClient={true}
            clientId={this.state.clientProfileId}
            attDataArray={this.state.attDataArray}
            getSentStatus={this.getSentStatus}
            state={this.state}
            showSecondaryEmail={this.state.showSecondaryEmail}
            isPDFRelatedToInvoice={this.state.isPDFRelatedToInvoice}
            setEmailData={this.state.setEmailData}
            userEmail={this.state.userEmail}
            paymentId={this.state.currentPaymentId}
            invoiceNo={this.state.invoiceNo}
            invoiceId={this.state.invoiceId}
            branchName={this.state.branchName}
            clientName={this.props && this.props.clientName}
            clientEmail={this.props && this.props.clientEmail}
            clientProfileData={this.props && this.props.clientProfileData}
            handleCancelSendingReceiptModal={
              this.handleCancelSendingReceiptModal
            }
            invoiceInfo={_invoiceInformation}
            visibleSendingReceiptModal={this.state.visibleSendingReceiptModal}
            showInvoiceTitle={this.state.showInvoiceTitle}
            stateValue={this.state}
            setEmailTemplate={this.props.setEmailTemplate}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let b =
    state &&
    state.invoicesReducer &&
    state.invoicesReducer.duplicate &&
    state.invoicesReducer.duplicate.duplicate;

  return {
    clientTagRes:
      state &&
      state.allClientReducer &&
      state.allClientReducer.getClientRes &&
      state.allClientReducer.getClientRes,
    invoiceTypesListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceTypesListing &&
      state.invoicesReducer.invoiceTypesListing,
    bankListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.bankListing &&
      state.invoicesReducer.bankListing.items &&
      state.invoicesReducer.bankListing.items,
    currencyListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.currencyListing &&
      state.invoicesReducer.currencyListing.items &&
      state.invoicesReducer.currencyListing.items,
    lastInvoiceNumber:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.lastInvoiceNumber &&
      state.invoicesReducer.lastInvoiceNumber.invoiceNumber &&
      state.invoicesReducer.lastInvoiceNumber.invoiceNumber,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,
    invoiceInfo:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceInfo &&
      state.invoicesReducer.invoiceInfo,
    duplicate:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.duplicate &&
      state.invoicesReducer.duplicate.duplicate &&
      state.invoicesReducer.duplicate.duplicate,
    _invoicePayments:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoicePayments &&
      state.invoicesReducer.invoicePayments,
    taxListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.taxListing &&
      state.invoicesReducer.taxListing.items &&
      state.invoicesReducer.taxListing.items,
    invoicePayments:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoicePayments &&
      state.invoicesReducer.invoicePayments.items &&
      state.invoicesReducer.invoicePayments.items,
    setEmailData:
      state &&
      state.questionnaireReducer &&
      state.questionnaireReducer.setEmailData &&
      state.questionnaireReducer.setEmailData,
    setEmailDataPayment:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.setEmailData &&
      state.invoicesReducer.setEmailData,
    branchData:
      state &&
      state.branchReducer &&
      state.branchReducer.branchData &&
      state.branchReducer.branchData.items &&
      state.branchReducer.branchData.items,
    invoiceStatuses:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.invoiceStatuses &&
      state.invoicesReducer.invoiceStatuses.items &&
      state.invoicesReducer.invoiceStatuses.items,
    companyData:
      state &&
      state.companyReducer &&
      state.companyReducer.companyData &&
      state.companyReducer.companyData,
    clientProfileData:
      state.clientProfileReducer &&
      state.clientProfileReducer.clientProfileData,
    noteListing:
      state &&
      state.invoicesReducer &&
      state.invoicesReducer.noteListing &&
      state.invoicesReducer.noteListing.items &&
      state.invoicesReducer.noteListing.items,
    invoiceTempRes:
      state && state.invoicesReducer && state.invoicesReducer.invoiceTempRes,
    profileDataRdx: state.clientProfileReducer.profileDataRdx,
    // state.invoicesReducer.invoiceTempRes.items &&
    // state.invoicesReducer.invoiceTempRes.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientTag: bindActionCreators(getProfileClientTag, dispatch),
  getAllInvoiceTypes: bindActionCreators(getAllInvoiceTypes, dispatch),
  getAllBanksListing: bindActionCreators(getAllBanksListing, dispatch),
  getAllCurrencies: bindActionCreators(getAllCurrencies, dispatch),
  addNewInvoice: bindActionCreators(addNewInvoice, dispatch),
  getLastInvoiceNumber: bindActionCreators(getLastInvoiceNumber, dispatch),
  addInvoicePayment: bindActionCreators(addInvoicePayment, dispatch),
  getInvoicePayments: bindActionCreators(getInvoicePayments, dispatch),
  deleteInvoicePayment: bindActionCreators(deleteInvoicePayment, dispatch),
  editInvoice: bindActionCreators(editInvoice, dispatch),
  getInvoiceInfo: bindActionCreators(getInvoiceInfo, dispatch),
  setEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
  setEmailTemplatePayment: bindActionCreators(
    setEmailTemplatePayment,
    dispatch
  ),
  getBranch: bindActionCreators(getBranch, dispatch),
  getInvoiceStatuses: bindActionCreators(getInvoiceStatuses, dispatch),
  getCompany: bindActionCreators(getCompany, dispatch),
  checkDuplicateClientInvoice: bindActionCreators(
    checkDuplicateClientInvoice,
    dispatch
  ),
  getInvoicePDFHtmlBlob: bindActionCreators(getInvoicePDFHtmlBlob, dispatch),
  getInvoiceReceiptPDFHtmlBlob: bindActionCreators(
    getInvoiceReceiptPDFHtmlBlob,
    dispatch
  ),
  getAllNotesListing: bindActionCreators(getAllNotesListing, dispatch),
  getClientProfile: bindActionCreators(getClientProfile, dispatch),
  getPDF: bindActionCreators(getPDF, dispatch),
  getInvoicePDF: bindActionCreators(getInvoicePDF, dispatch),
  getAllTaxesListing: bindActionCreators(getAllTaxesListing, dispatch),
  onAddEmailDocument: bindActionCreators(addEmailDocument, dispatch),
  onGetInvoiceTemplate: bindActionCreators(getInvoiceTemplate, dispatch),
  onAddInvoiceTemplate: bindActionCreators(addInvoiceTemplate, dispatch),
});
AddInvoice = connect(mapStateToProps, mapDispatchToProps)(AddInvoice);

export default withRouter(AddInvoice);
