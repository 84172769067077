import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Col,
  Form,
  Row,
  Select,
  Input,
  DatePicker,
  Button,
  message,
  InputNumber,
  Modal,
  Tag,
  Spin,
} from "antd";
import FroalaEditor from "react-froala-wysiwyg";
import PotentialHeaderTabs from "../Components/Header/PotentialHeaderTabs";
import { bindActionCreators } from "redux";
import { RollbackOutlined } from "@ant-design/icons";
import {
  getClientTag,
  getVisaType,
  getClientSource,
  addPotentialClient,
  getTeamMember,
  getCountries,
  editPotentialClient,
  getEmployerHistory,
  getAutoEmailImport,
  addAutoEmailImport,
  sendDynamicLinkEmail,
  getLetterTemplates,
  getEmployerFile,
  addEmployerFile,
  getEmployerDocument,
  addEmployerDocument,
  getDocumentChecklist,
  getEmployerCheckList,
  removeEmployerDocument,
  getClientFile,
  addClientFile,
  updateClientFile,
  deleteClientFile,
  checkDuplicatePotentialClient,
  getPotentialClientStatus,
  getCompany,
  movePClientToClient,
  getClientTasks,
  updetaTasks,
  getUsers,
  addTaskFollower,
  removeTasks,
  addDailyTasks,
  getClientFamily,
  updateCompletedTask,
  addTaskFileNote,
  addTaskComment,
  getTasksComments,
  getTasksFollowers,
  getPotentialClientTasks,
  getFilteredType,
  addFilteredType,
  removeFollower,
  getDocumentDownload,
  updateEmployerDocument,
  getSignature,
  getDocuments,
  manualImportPotentialEmail,
  getPClientEmailHistory,
  getPdf,
  getImapForAll,
  addEmailDocument,
  getPotentialClientInfo,
  getAllQuestionnaire,
  createDynamicLink,
  getDynamicLink,
  getEmailTemplate,
  setEmailTemplate,
  getQuestionnaire,
  updateQuestionnaire,
  getMappingParent,
  getMappingChild,
  getQuestionnaireGroup,
  groupedQuestionnaire,
  getFilledQuestionnaire,
  getAllFilledQuestionnaire,
  addEmployerCheckList,
  addDocumentChecklist,
  mergeFromPotentialClient,
  updMergeFromPotentialClient,
  updMergeToPotentialClient,
  addProcessingPerson,
  getEmailContent,
  getBranchPipeline,
  removeProcessingPerson,
  getPermissonUser,
  getMailBox,
  autoImportEmailClient,
  getPClientEmailImport,
} from "../store/Actions";
import moment from "moment";
import PotentialClientEmail from "./PotentialClientEmail";
import PotentialClientDocuments from "./PotentialClientDocuments";
import FileNotesMain from "./FileNotes";
import Invoices from "./Invoices/Invoices";
import AddInvoice from "./Invoices/AddInvoice";
import TaskList from "./Task/TaskList";
import { Images } from "../Themes";
import PotentialClientQuestionnaire from "./PotentialClientQuestionnaire";
import FroalaEditorCom from "../Containers/FroalaEditorCom";
import { apiRefresh } from "../services/api";
import DealCard from "../Components/Deals";
import PurchaseSubscription from "../Common/PurchaseMessage";

const { Option } = Select;
const dateFormat = "DD/MM/YYYY";

const layout = {
  labelCol: { span: 4, offset: 1 },
  wrapperCol: { span: 11, offset: 4 },
};

const layoutFormItemSub = {
  labelCol: { span: 23, offset: 1 },
  wrapperCol: { span: 24 },
};

const layoutFormItemButton = {
  wrapperCol: { span: 22, offset: 2 },
};

let tabs = [
  {
    label: "Detail",
  },
  {
    label: "EMAIL",
  },
  {
    label: "DOCUMENTS",
  },
  {
    label: "FILE NOTES",
  },
  // {
  //   label: "ACTIVITY LOG",
  // },
  {
    label: "TASKS",
  },
  // {
  //   label: "MEETINGS",
  // },
  // {
  //   label: "REMINDERS",
  // },
  {
    label: "QUESTIONNAIRE",
  },
  {
    label: "INVOICES",
  },
  {
    label: "DEALS",
  },
  // {
  //   label: "CREDIT NOTES",
  // },
];

const headOption = [
  {
    tabName: "Potential Clients",
    linkName: "/potential-client/potential-clients",
  },
  // {
  //   tabName: "Potential Clients Status",
  //   linkName: "/potential-client/potential-clients-status",
  // },
  {
    tabName: "Potential Employers",
    linkName: "/potential-client/potential-employer",
  },
];

class AddNewPotentialClient extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    let selectedBranchId = localStorage.getItem("selectedBranchId");
    super(props);
    this.state = {
      visaTypeId: 0,
      visaTypeName: "",
      activeTab: "Detail",
      processingPersons: [],
      clientTags: [],
      clientStatus: "",
      notes: "",
      mobile: "",
      countryCode: 0,
      finaldate: "",
      branchId: selectedBranchId,
      showDuplicateModal: false,
      firstName: "",
      potentialClientDataStatuses: [],
      isDisabled: false,
      loading: false,
      addInvoice: "",
      parentInvoiceId: 0,
      childrenTotalAmount: 0,
      parentTotalAmount: 0,
      visibleModalMovePClient: false,
      countriesData: [],
      visaTypes: [],
      users: [],
    };
  }

  componentDidMount() {
    this.props.getClientTag();
    this.props
      .onGetVisaType(localStorage.getItem("selectedBranchId"))
      .then((res) => {
        var visaTypeOption = [];
        if (
          this.props.potentialClientInfo &&
          this.props.potentialClientInfo.intrestedVisa != "" &&
          this.props.potentialClientInfo.intrestedVisa != undefined
        ) {
          if (
            this.props.visaTypeData &&
            this.props.visaTypeData.items.length > 0
          ) {
            var findVisa = this.props.visaTypeData.items.find(
              (obj) => obj.id === this.props.potentialClientInfo.intrestedVisa
            );
            if (!findVisa) {
              const branchUserOpt = {
                url: `v1/company/BranchVisaType/${this.props.potentialClientInfo.intrestedVisa}`,
              };
              branchUserOpt.types = [
                "GET_VISA_TYPE_SUCCESS",
                "GET_VISA_TYPE_FAILURE",
              ];
              apiRefresh.get(branchUserOpt).then((resp) => {
                visaTypeOption = [];
                visaTypeOption.push(
                  <Option key={resp.id}>{resp.visaTypeName}</Option>
                );
                for (let i = 0; i < this.props.visaTypeData.items.length; i++) {
                  visaTypeOption.push(
                    <Option key={this.props.visaTypeData.items[i].id}>
                      {this.props.visaTypeData.items[i].visaTypeName}
                    </Option>
                  );
                }
                this.setState({ visaTypes: visaTypeOption });
              });
            }
          }
        } else {
          visaTypeOption = [];
          if (this.props.visaTypeData) {
            for (let i = 0; i < this.props.visaTypeData.items.length; i++) {
              visaTypeOption.push(
                <Option key={this.props.visaTypeData.items[i].id}>
                  {this.props.visaTypeData.items[i].visaTypeName}
                </Option>
              );
            }
            this.setState({ visaTypes: visaTypeOption });
          }
        }
      });
    this.props.getClientSource();
    this.props.getTeamMember();
    this.props.getCountries().then((res) => {
      this.setState({ countriesData: res.payload.items });
    });
    this.props.getPotentialClientStatus();
    this.props.getCompany();
    this.props.onGetPermProcessingPerson(true).then((res) => {
      let usersData = res.payload.users.map((item, index) => (
        <Option value={item.id}>{item.fullName}</Option>
      ));
      this.setState({ users: usersData });
    });
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    let _potentialClientDataStatuses =
      nextProps && nextProps.potentialClientData;
    if (
      this.state.potentialClientDataStatuses !== _potentialClientDataStatuses
    ) {
      this.setState({
        potentialClientDataStatuses: _potentialClientDataStatuses,
      });
    }
    if (this.state.activeTab === "Detail") {
      let _selectedBranchId = nextProps && nextProps.selectedBranch;
      if (
        this.state.branchId !== _selectedBranchId &&
        _selectedBranchId !== undefined
      ) {
        this.setState({ branchId: _selectedBranchId }, () => {
          this.props.getPotentialClientStatus().then(() => {
            let _potentialClientDataStatuses =
              nextProps && nextProps.potentialClientData;

            this.setState(
              { potentialClientDataStatuses: _potentialClientDataStatuses },
              () => {
                this.formRef.current.setFieldsValue({
                  clientStatus: "",
                });
              }
            );
          });
        });
      }
      let _bol = nextProps && nextProps.bol;

      if (window.performance) {
        if (performance.navigation.type == 1) {
          if (_bol === undefined) {
            this.props.history.push("/potential-client/potential-clients");
          } else {
          }
        }
      }
      let potentialClientInfo =
        nextProps &&
        nextProps.potentialClientInfo &&
        nextProps.potentialClientInfo;

      if (potentialClientInfo && potentialClientInfo.id) {
        if (this.state.firstName !== potentialClientInfo.firstName) {
          if (tabs.length > 0) {
            for (let ind = 0; ind < tabs.length; ind++) {
              if (tabs[ind].label === "MEETINGS") {
                if (
                  JSON.parse(
                    decodeURIComponent(
                      escape(
                        window.atob(
                          localStorage.getItem("userSystemPermissions")
                        )
                      )
                    )
                  ).find((x) => x.role.toLowerCase() == "meetings").status == 0
                ) {
                  tabs.splice(ind, 1);
                }
              }
            }
          }
          if (tabs.length > 0) {
            for (let ind = 0; ind < tabs.length; ind++) {
              if (tabs[ind].label === "QUESTIONNAIRE") {
                if (
                  JSON.parse(
                    decodeURIComponent(
                      escape(
                        window.atob(
                          localStorage.getItem("userSystemPermissions")
                        )
                      )
                    )
                  ).find(
                    (x) =>
                      x.role.toLowerCase() == "custom questionnaire" ||
                      x.role.toLowerCase() == "custom questionnaires"
                  ).status == 0
                ) {
                  tabs.splice(ind, 1);
                }
              }
            }
          }
          this.setState(
            {
              firstName: potentialClientInfo.firstName,
              lastName: potentialClientInfo.lastName,
              dob: potentialClientInfo.dob,
              email: potentialClientInfo.email,
              mobile:
                potentialClientInfo.mobile === undefined
                  ? ""
                  : potentialClientInfo.mobile,
              address: potentialClientInfo.address,
              notes:
                potentialClientInfo.notes === undefined
                  ? ""
                  : potentialClientInfo.notes,
              worth: potentialClientInfo.worth,
              visaTypeId: potentialClientInfo.visaTypeId,
              visaTypeName: potentialClientInfo.visaTypeName,
              phone: potentialClientInfo.phone,
              salePerson: potentialClientInfo.salePerson,
              intrestedVisa: potentialClientInfo.intrestedVisa,
              sourceId: potentialClientInfo.sourceId,
              sourceDescription: potentialClientInfo.sourceDescription,
              clientStatus: potentialClientInfo.clientStatus,
              occupation: potentialClientInfo.occupation,
              contactDate: potentialClientInfo.contactDate,
              potentialClientId: potentialClientInfo.id,
              countryCode: potentialClientInfo.countryCode,
              clientTags: potentialClientInfo.clientTags,
              processingPersons: potentialClientInfo.processingPersons,
            },
            () => {
              console.log("its state", this.state);
            }
          );

          let _clientTags = [];
          let _processingPersons = [];
          if (
            potentialClientInfo &&
            potentialClientInfo.clientTags &&
            potentialClientInfo.clientTags
          ) {
            for (
              let _clientTagsIndex = 0;
              _clientTagsIndex < potentialClientInfo.clientTags.length;
              _clientTagsIndex++
            ) {
              _clientTags.push(
                potentialClientInfo.clientTags[_clientTagsIndex].tag
              );
            }
          }
          if (
            potentialClientInfo &&
            potentialClientInfo.processingPersons &&
            potentialClientInfo.processingPersons
          ) {
            for (
              let _processingPersonsIndex = 0;
              _processingPersonsIndex <
              potentialClientInfo.processingPersons.length;
              _processingPersonsIndex++
            ) {
              _processingPersons.push(
                potentialClientInfo.processingPersons[_processingPersonsIndex]
                  .userId
              );
            }
          }
          let _contactDate;
          if (
            potentialClientInfo.contactDate === undefined ||
            potentialClientInfo.contactDate === "" ||
            potentialClientInfo.contactDate === "1900-01-01T00:00:00+00:00"
          ) {
            _contactDate = undefined;
          } else {
            _contactDate = moment(potentialClientInfo.contactDate).format(
              "DD-MM-YYYY"
            );
          }
          let _dob;
          if (
            potentialClientInfo.dob === undefined ||
            potentialClientInfo.dob === "" ||
            potentialClientInfo.dob === "1900-01-01T00:00:00.000Z"
          ) {
            _dob = undefined;
          } else {
            let validDate = null;
            if (isNaN(new Date(potentialClientInfo.dob))) {
              validDate = potentialClientInfo.dob.replace(
                /(\d+[/])(\d+[/])/,
                "$2$1"
              );
            } else {
              validDate = potentialClientInfo.dob;
            }
            if (validDate instanceof Date) {
              _dob = moment(new Date(validDate)).format("DD-MM-YYYY");
            } else {
              let spDnT = validDate.split("T");
              let mkValidDate;
              if (spDnT && spDnT.length > 1) {
                let spDate = spDnT[0];
                mkValidDate = spDate.split("-");
                validDate = `${mkValidDate[0]}/${mkValidDate[1]}/${mkValidDate[2]} GMT`;
                _dob = moment(new Date(validDate)).format("DD-MM-YYYY");
              } else {
                mkValidDate = validDate.split("-");
                validDate = `${mkValidDate[2]}/${mkValidDate[1]}/${mkValidDate[0]} GMT`;
                _dob = moment(new Date(validDate)).format("DD-MM-YYYY");
              }
            }
          }
          let _sourceId;
          if (
            potentialClientInfo.sourceId === undefined ||
            potentialClientInfo.sourceId === 0
          ) {
            _sourceId = undefined;
          } else {
            _sourceId = potentialClientInfo.sourceId;
          }
          let _countryCode;
          if (
            potentialClientInfo.countryCode === undefined ||
            potentialClientInfo.countryCode === 0
          ) {
            _countryCode = undefined;
          } else {
            _countryCode = potentialClientInfo.countryCode;
          }
          let _intrestedVisa;
          this.formRef.current.setFieldsValue({
            clientTags: _clientTags,
            processingPersons: _processingPersons,
            clientStatus: potentialClientInfo.clientStatus,
            firstName: potentialClientInfo.firstName,
            lastName: potentialClientInfo.lastName,
            email: potentialClientInfo.email,
            address: potentialClientInfo.address,
            phone: potentialClientInfo.phone,
            intrestedVisa: potentialClientInfo.intrestedVisa,
            worth: potentialClientInfo.worth,
            salePerson: potentialClientInfo.salePerson,
            sourceDescription: potentialClientInfo.sourceDescription,
            occupation: potentialClientInfo.occupation,
            sourceId: _sourceId,
            visaType:
              potentialClientInfo && potentialClientInfo.visaTypeId
                ? parseInt(potentialClientInfo.visaTypeId)
                : undefined,
            dob: _dob === undefined ? undefined : moment(_dob, dateFormat),
            contactDate:
              _contactDate === undefined
                ? undefined
                : moment(_contactDate, dateFormat),
            mobile: potentialClientInfo.mobile,
            countryCode: _countryCode,
          });
        }
      }
    }
  }

  showModalMovePClient = (id) => {
    this.setState({
      visibleModalMovePClient: true,
      movePClientIndex: id,
    });
  };

  handleCancelModalMovePClient = (e) => {
    this.setState({
      visibleModalMovePClient: false,
    });
  };

  getModalMovePClient = () => {
    return (
      <div>
        <Modal
          title="Confirmation"
          visible={this.state.visibleModalMovePClient}
          onCancel={this.handleCancelModalMovePClient}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row>
                {" "}
                Are you sure, you want to move potential client to client?
              </Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={15} style={{ marginRight: "10px" }}>
              <Button
                onClick={() => {
                  this.handleCancelModalMovePClient();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                className={"button"}
                onClick={() => {
                  this.props
                    .movePClientToClient(this.state.movePClientIndex)
                    .then(() => {
                      message.success(
                        "Potential Client is successfully added to client"
                      );
                      setTimeout(() => {
                        this.props.history.push(
                          "/potential-client/potential-clients"
                        );
                      }, 1000);
                    })
                    .catch(() => {
                      message.error("An error occurred!");
                    });
                }}
              >
                OK
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  getEditorValue = (value) => {
    this.setState({ notes: value });
  };

  onHandleChangeInputNumber = (value) => {};

  getSaveButton = () => {
    return (
      <Row>
        <Col xs={24} style={{ marginTop: "27px" }}>
          <Form.Item {...layoutFormItemButton}>
            <Button type="primary" htmlType="submit" className="button-blue">
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  getTabs = () => {
    let _id =
      this.props &&
      this.props.potentialClientInfo &&
      this.props.potentialClientInfo.id;
    return (
      <Row
        style={{
          display:
            this.props &&
            this.props.potentialClientInfo &&
            this.props.potentialClientInfo.firstName
              ? "flex"
              : "none",
        }}
      >
        <Col>
          <div style={{ display: "flex" }}>
            <div className="sus-tab-container-clients">
              {tabs.map((data, index) => {
                return (
                  <div
                    onClick={() => {
                      if (data.label === "INVOICES") {
                        this.setState({ loading: true }, () => {
                          this.setState({ addInvoice: "invoices" }, () => {
                            this.setState({ loading: false });
                          });
                        });
                      } else {
                        this.setState({ addInvoice: "" });
                      }

                      this.setState({ activeTab: data.label }, () => {
                        if (this.state.activeTab === "Detail") {
                          let _selectedBranchId =
                            this.props && this.props.selectedBranch;
                          if (
                            this.state.branchId !== _selectedBranchId &&
                            _selectedBranchId !== undefined
                          ) {
                            this.setState(
                              { branchId: _selectedBranchId },
                              () => {
                                this.props
                                  .getPotentialClientStatus()
                                  .then(() => {
                                    let _potentialClientDataStatuses =
                                      this.props &&
                                      this.props.potentialClientData;

                                    this.setState(
                                      {
                                        potentialClientDataStatuses: _potentialClientDataStatuses,
                                      },
                                      () => {
                                        this.formRef.current.setFieldsValue({
                                          clientStatus: "",
                                        });
                                      }
                                    );
                                  });
                              }
                            );
                          }

                          let potentialClientInfo =
                            this.props &&
                            this.props.potentialClientInfo &&
                            this.props.potentialClientInfo;

                          if (potentialClientInfo && potentialClientInfo.id) {
                            this.setState(
                              {
                                firstName: potentialClientInfo.firstName,
                                lastName: potentialClientInfo.lastName,
                                dob: potentialClientInfo.dob,
                                email: potentialClientInfo.email,
                                mobile:
                                  potentialClientInfo.mobile === undefined
                                    ? ""
                                    : potentialClientInfo.mobile,
                                address: potentialClientInfo.address,
                                notes:
                                  potentialClientInfo.notes === undefined
                                    ? ""
                                    : potentialClientInfo.notes,
                                worth: potentialClientInfo.worth,
                                visaTypeId: potentialClientInfo.visaTypeId,
                                visaTypeName: potentialClientInfo.visaTypeName,
                                phone: potentialClientInfo.phone,
                                salePerson: potentialClientInfo.salePerson,
                                intrestedVisa:
                                  potentialClientInfo.intrestedVisa,
                                sourceId: potentialClientInfo.sourceId,
                                sourceDescription:
                                  potentialClientInfo.sourceDescription,
                                clientStatus: potentialClientInfo.clientStatus,
                                occupation: potentialClientInfo.occupation,
                                contactDate: potentialClientInfo.contactDate,
                                potentialClientId: potentialClientInfo.id,
                                countryCode: potentialClientInfo.countryCode,
                                clientTags: potentialClientInfo.clientTags,
                                processingPersons:
                                  potentialClientInfo.processingPersons,
                              },
                              () => {
                                console.log("its state", this.state);
                              }
                            );

                            let _clientTags = [];
                            let _processingPersons = [];
                            if (
                              potentialClientInfo &&
                              potentialClientInfo.clientTags &&
                              potentialClientInfo.clientTags
                            ) {
                              for (
                                let _clientTagsIndex = 0;
                                _clientTagsIndex <
                                potentialClientInfo.clientTags.length;
                                _clientTagsIndex++
                              ) {
                                _clientTags.push(
                                  potentialClientInfo.clientTags[
                                    _clientTagsIndex
                                  ].tag
                                );
                              }
                            }
                            if (
                              potentialClientInfo &&
                              potentialClientInfo.processingPersons &&
                              potentialClientInfo.processingPersons
                            ) {
                              for (
                                let _processingPersonsIndex = 0;
                                _processingPersonsIndex <
                                potentialClientInfo.processingPersons.length;
                                _processingPersonsIndex++
                              ) {
                                _processingPersons.push(
                                  potentialClientInfo.processingPersons[
                                    _processingPersonsIndex
                                  ].userName
                                );
                              }
                            }
                            let _contactDate;
                            if (
                              potentialClientInfo.contactDate === undefined ||
                              potentialClientInfo.contactDate === "" ||
                              potentialClientInfo.contactDate ===
                                "1900-01-01T00:00:00+00:00"
                            ) {
                              _contactDate = undefined;
                            } else {
                              _contactDate = moment(
                                potentialClientInfo.contactDate
                              ).format("DD-MM-YYYY");
                            }
                            let _dob;
                            if (
                              potentialClientInfo.dob === undefined ||
                              potentialClientInfo.dob === "" ||
                              potentialClientInfo.dob ===
                                "1900-01-01T00:00:00+00:00"
                            ) {
                              _dob = undefined;
                            } else {
                              _dob = moment(
                                potentialClientInfo.dob,
                                "DD-MM-YYYY"
                              ).format("DD-MM-YYYY");
                            }
                            let _sourceId;
                            if (
                              potentialClientInfo.sourceId === undefined ||
                              potentialClientInfo.sourceId === 0
                            ) {
                              _sourceId = undefined;
                            } else {
                              _sourceId = potentialClientInfo.sourceId;
                            }
                            let _countryCode;
                            if (
                              potentialClientInfo.countryCode === undefined ||
                              potentialClientInfo.countryCode === 0
                            ) {
                              _countryCode = undefined;
                            } else {
                              _countryCode = potentialClientInfo.countryCode;
                            }
                            let _intrestedVisa;
                            this.formRef.current.setFieldsValue({
                              clientTags: _clientTags,
                              processingPersons: _processingPersons,
                              clientStatus: potentialClientInfo.clientStatus,
                              firstName: potentialClientInfo.firstName,
                              lastName: potentialClientInfo.lastName,
                              email: potentialClientInfo.email,
                              address: potentialClientInfo.address,
                              phone: potentialClientInfo.phone,
                              intrestedVisa: potentialClientInfo.intrestedVisa,
                              worth: potentialClientInfo.worth,
                              salePerson: potentialClientInfo.salePerson,
                              sourceDescription:
                                potentialClientInfo.sourceDescription,
                              occupation: potentialClientInfo.occupation,
                              sourceId: _sourceId,
                              visaType: potentialClientInfo
                                ? parseInt(potentialClientInfo.visaTypeName)
                                : undefined,
                              dob:
                                _dob === undefined
                                  ? undefined
                                  : moment(_dob, dateFormat),
                              contactDate:
                                _contactDate === undefined
                                  ? undefined
                                  : moment(_contactDate, dateFormat),
                              mobile: potentialClientInfo.mobile,
                              countryCode: _countryCode,
                            });
                          }
                        }
                      });
                    }}
                    style={{
                      borderLeft: index === 0 ? "none" : "2px solid white",
                    }}
                    className={
                      this.state.activeTab === data.label
                        ? "sus-active-tab-schools"
                        : "sus-inactive-tab-schools"
                    }
                    key={index}
                  >
                    <span
                      className={
                        this.state.activeTab === data.label
                          ? "sus-active-tab-text-school"
                          : "sus-inactive-tab-text-school"
                      }
                    >
                      {data.label.toUpperCase()}
                    </span>
                  </div>
                );
              })}
            </div>
            {JSON.parse(
              decodeURIComponent(
                escape(
                  window.atob(localStorage.getItem("userSystemPermissions"))
                )
              )
            ).find((x) => x.role.toLowerCase() == "client database").status ==
            1 ? (
              <div
                className="d-end space-between cont-positioning"
                style={{ marginTop: "47px" }}
              >
                <div className="add-tag-btn">
                  <>
                    <img
                      src={Images.addIcon}
                      className="icons-client"
                      type="primary"
                      onClick={() => this.showModalMovePClient(_id)}
                    />
                  </>
                </div>
              </div>
            ) : null}
            <div style={{ marginLeft: "5px" }}>
              <Button
                type="primary"
                icon={<RollbackOutlined color={"white"} />}
                size={"small"}
                className={"back-button"}
                onClick={() => {
                  this.props.history.push(
                    "/potential-client/potential-clients"
                  );
                }}
              ></Button>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  removeProcessingPerson = (id) => {
    let data = {
      id: id,
      delete: true,
    };
    this.setState({ loading: true });

    this.props
      .onRemoveProcessingPerson(data)
      .then((res) => {
        message.success("Processing Person removed Successfully!");
        this.setState({ loading: false });
      })
      .catch((err) => {
        message.error("Failed!");
        this.setState({ loading: false });
      });
  };

  handleChangeProcessingPersons = (value) => {
    let _processingPersons = [];
    let _stateProcessingPersons = [...this.state.processingPersons];
    let valueId = [];
    let ppId = [];
    _stateProcessingPersons.map((pp, ind) => {
      ppId.push(pp.userId);
    });
    if (
      (_stateProcessingPersons && _stateProcessingPersons.length > 0) ||
      (value && value.length > 0)
    ) {
      for (let i = 0; i < _stateProcessingPersons.length; i++) {
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] === "string") {
            if (
              _stateProcessingPersons[i].userName === value[j] ||
              _stateProcessingPersons[i].userId === value[j]
            ) {
              _processingPersons.push({
                userId: _stateProcessingPersons[i].userId,
                userName: _stateProcessingPersons[i].userName,
              });
            }
          }
        }
      }
      if (value.length > _stateProcessingPersons.length) {
        _processingPersons.push({ userId: value[value.length - 1] });

        let procPersonData = {
          clientId: this.props.potentialClientInfo.id,
          userId: value[value.length - 1],
        };
        this.props.onAddProcessingPerson(procPersonData).then((res) => {
          this.props.onGetMailBox(procPersonData.userId).then((res) => {
            let addEmailImp = {
              clientId: this.props.potentialClientInfo.id,
              isRead: true,
              isAutoImportEmail: true,
              isImportFromPrimaryEmail: true,
              isImportFromSecondaryEmail: true,
              isImportFromOtherEmail: true,
              importStartDate: new Date(),
              istTimeImport: true,
            };
            this.props.onAutoImportEmailClient(addEmailImp);
          });
        });
      } else {
        var val = ppId.filter((f) => !value.includes(f));
        if (val && val.length > 0) {
          let findPp = this.props.potentialClientInfo.processingPersons.find(
            (obj) => obj.userId == val[0]
          );

          if (findPp) this.removeProcessingPerson(findPp.id);
        }
      }
    } else {
      for (let k = 0; k < value.length; k++) {
        _processingPersons.push({ userId: value[k] });
      }
    }

    let finalListPersons = _processingPersons;

    this.setState({ processingPersons: finalListPersons }, () => {
      console.log("processing persons final", this.state);
    });
  };

  onChangeVisaType = (value) => {
    let _visaTypeId = 0;
    let _visaTypeName = "";
    let findVisaType =
      this.props.visaTypeData &&
      this.props.visaTypeData.items.find((obj) => obj.id == value);
    if (findVisaType) {
      _visaTypeId = findVisaType.id;
      _visaTypeName = findVisaType.visaTypeName;
    }
    this.setState({ visaTypeId: _visaTypeId, visaTypeName: _visaTypeName });
  };

  handleChangeClientTags = (value) => {
    let _clientTags = [];
    let _stateClientTags = [...this.state.clientTags];
    if (_stateClientTags && _stateClientTags.length > 0) {
      for (let i = 0; i < _stateClientTags.length; i++) {
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] === "string") {
            if (_stateClientTags[i].tag === value[j]) {
              _clientTags.push({
                tagId: _stateClientTags[i].tagId,
                tag: _stateClientTags[i].tag,
              });
            }
          }
          if (typeof value[j] === "number") {
            let foundDuplicate = _clientTags.some(
              (el) => el.tagId === value[j]
            );
            if (!foundDuplicate) {
              _clientTags.push({ tagId: value[j] });
            }
          }
        }
      }
    } else {
      for (let k = 0; k < value.length; k++) {
        _clientTags.push({ tagId: value[k] });
      }
    }

    let finalListTags = _clientTags;

    this.setState({ clientTags: finalListTags }, () => {
      console.log("client tags final", this.state);
    });
  };

  tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"blue"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  getForm = (potentialClientInfo) => {
    return (
      <div
        className=" emp-w-box  add-employer-para"
        style={{ backgroundColor: "#f0f2f5 " }}
      >
        {potentialClientInfo && potentialClientInfo.firstName && (
          <Row>
            <Col xs={24}>
              <div>
                <Form.Item
                  className={
                    "add-client-form-inputs pclient-input-margins multi-select-option"
                  }
                  colon={false}
                  labelAlign="left"
                  label={"PROCESSING PERSON"}
                  name="processingPersons"
                >
                  <Select
                    showSearch={true}
                    optionFilterProp="children"
                    showArrow
                    virtual={false}
                    placeholder="ADD PROCESSING PERSON"
                    size="middle"
                    mode="multiple"
                    tagRender={this.tagRender}
                    onChange={this.handleChangeProcessingPersons}
                  >
                    {/* {this.props &&
                      this.props.teamMembers &&
                      this.props.teamMembers.map((data) => {
                        // eslint-disable-next-line react/jsx-no-undef
                        return <Option value={data.id}>{data.fullName}</Option>;
                      })} */}
                    {this.state.users}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={24}>
            <div>
              <Form.Item
                className={
                  "add-client-form-inputs pclient-input-margins multi-select-option"
                }
                colon={false}
                labelAlign="left"
                label={"CLIENT TAGS"}
                name="clientTags"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  showArrow
                  placeholder="SELECT CLIENT TAGS"
                  size="middle"
                  mode="multiple"
                  virtual={false}
                  tagRender={this.tagRender}
                  onChange={this.handleChangeClientTags}
                >
                  {this.props &&
                    this.props.clientTagRes &&
                    this.props.clientTagRes.items &&
                    this.props.clientTagRes.items.map((data) => {
                      // eslint-disable-next-line react/jsx-no-undef
                      return <Option value={data.id}>{data.name}</Option>;
                    })}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Client Status"
              name="clientStatus"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                placeholder="PLEASE SELECT"
                size="middle"
                virtual={false}
                showSearch
                optionFilterProp="children"
              >
                <Option value={""}>{"Select"}</Option>
                {this.state &&
                  this.state.potentialClientDataStatuses &&
                  this.state.potentialClientDataStatuses.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.name}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Client's First Name"
              name="firstName"
              rules={[{ required: true, message: "Required!" }]}
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Last Name"
              name="lastName"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Email"
              name="email"
              rules={[{ type: "email", message: "Invalid!" }]}
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Date Of Birth"
              name="dob"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Address"
              name="address"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: "12px" }}>
          <Col xs={24} style={{ marginLeft: "30px" }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Form.Item
              className="pclient-input-margins"
              // className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Mobile Phone"
              // style={{ display: "flex" }}
            >
              <div
                style={{ display: "flex", marginLeft: "-11px", width: "97%" }}
              >
                <div style={{ width: "20%" }}>
                  <Form.Item
                    // className={"add-client-form-inputs"}
                    name="countryCode"
                  >
                    <Select
                      className={"width-web-kit"}
                      placeholder={"Select"}
                      size="middle"
                      // style={{ width: "150px", minWidth: "150px" }}
                      virtual={false}
                      showSearch
                      optionFilterProp="children"
                      dropdownClassName="dropdown-options-width"
                    >
                      {this.props &&
                        this.state.countriesData &&
                        this.state.countriesData.length > 0 &&
                        this.state.countriesData.map((data) => {
                          // eslint-disable-next-line react/jsx-no-undef
                          return data.id === 0 ? null : (
                            <Option
                              value={data.id}
                              style={{ fontSize: "14px" }}
                            >
                              +{data.callingCode} - {data.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div style={{ width: "80%" }}>
                  <Form.Item
                    // className={"add-client-form-inputs"}
                    name="mobile"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (Math.ceil(Math.log10(value + 1)) > 15) {
                            return Promise.reject("Character limit exceeded");
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (value < 0) {
                            return Promise.reject("Type only positive numbers");
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className={"width-web-kit"}
                      size="middle"
                      onChange={this.onHandleChangeInputNumber}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Land Line"
              name="phone"
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value < 0) {
                      return Promise.reject("Type only positive numbers");
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <InputNumber
                style={{ width: "-webkit-fill-available" }}
                size="middle"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Current Visa Type"
              name="visaType"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="PLEASE SELECT VISA"
                size="middle"
                onChange={this.onChangeVisaType}
                virtual={false}
              >
                {this.props &&
                  this.props.visaTypeData &&
                  this.props.visaTypeData.items &&
                  this.props.visaTypeData.items.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.id}>{data.visaTypeName}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Interested Visa"
              name="intrestedVisa"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="PLEASE SELECT VISA"
                size="middle"
                virtual={false}
              >
                {this.state.visaTypes}
                {/* {this.props &&
                  this.props.visaTypeData &&
                  this.props.visaTypeData.items &&
                  this.props.visaTypeData.items.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return (
                      <Option value={data.id.toString()}>
                        {data.visaTypeName}
                      </Option>
                    );
                  })} */}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Contact Date"
              name="contactDate"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <DatePicker format={dateFormat} size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Deal Worth"
              name="worth"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Sales Person"
              name="salePerson"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="SELECT SALES PERSON"
                size="middle"
                virtual={false}
              >
                {this.props &&
                  this.props.teamMembers &&
                  this.props.teamMembers.map((data, ind) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.id}>{data.fullName}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Client Source"
              name="sourceId"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="SELECT CLIENT'S SOURCE"
                size="middle"
                virtual={false}
              >
                {this.props &&
                  this.props.clientSourceListing &&
                  this.props.clientSourceListing.map((data) => {
                    // eslint-disable-next-line react/jsx-no-undef
                    return <Option value={data.id}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Source Description"
              name="sourceDescription"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs pclient-input-margins"}
              colon={false}
              labelAlign="left"
              label="Occupation"
              name="occupation"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              className={"add-client-form-inputs"}
              colon={false}
              labelAlign="left"
              label="Notes"
            >
              {/* eslint-disable-next-line react/jsx-no-undef */}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={19} style={{ marginLeft: "70px", marginTop: "14px" }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <FroalaEditorCom
              model={this.state.notes}
              onModelChange={this.getEditorValue}
            />
          </Col>
        </Row>
        {this.getSaveButton()}
      </div>
    );
  };

  onFinish = (values) => {
    // let _processingPersons = [];
    // let _clientTags = [];
    let _clientStatus =
      values.clientStatus === undefined ? "" : values.clientStatus;
    let _firstName =
      values.firstName === undefined
        ? ""
        : values.firstName.trim().replace(/\s+/g, " ");
    let _lastName =
      values.lastName === undefined
        ? ""
        : values.lastName.trim().replace(/\s+/g, " ");
    let _email = values.email === undefined ? "" : values.email;
    let _dob;
    if (values.dob === undefined || values.dob === null) {
      _dob = "";
    } else {
      _dob = moment(values.dob, "DD-MM-YYYY").format("DD-MM-YYYY");
    }
    let _address = values.address === undefined ? "" : values.address;
    let _phone = values.phone ? values.phone.toString() : "";
    let _mobile = values.mobile ? values.mobile.toString() : "";
    let _intrestedVisa =
      values.intrestedVisa === undefined ? "" : values.intrestedVisa;
    let _contactDate;
    if (values.contactDate === undefined || values.contactDate === null) {
      _contactDate = "1900-01-01T00:00:00.000Z";
    } else {
      let contactDateTemp = moment(values.contactDate).format();

      _contactDate = moment(contactDateTemp).format(
        "YYYY-MM-DDT00:00:00+00:00"
      );
    }
    let _worth = values.worth === undefined ? "" : values.worth;
    let _sourceDescription =
      values.sourceDescription === undefined ? "" : values.sourceDescription;
    let _salePerson = values.salePerson === undefined ? "" : values.salePerson;
    let _sourceId = values.sourceId === undefined ? 0 : values.sourceId;
    let _countryCode;
    if (values.countryCode === undefined) {
      _countryCode = 0;
    } else {
      _countryCode = values.countryCode;
    }
    let _occupation = values.occupation === undefined ? "" : values.occupation;

    this.setState(
      {
        clientStatus: _clientStatus,
        firstName: _firstName,
        lastName: _lastName,
        email: _email,
        dob: _dob,
        address: _address,
        phone: _phone,
        mobile: _mobile,
        intrestedVisa: _intrestedVisa,
        contactDate: _contactDate,
        worth: _worth,
        sourceDescription: _sourceDescription,
        occupation: _occupation,
        salePerson: _salePerson,
        sourceId: _sourceId,
        countryCode: _countryCode,
      },
      () => {
        if (
          this.props &&
          this.props.potentialClientInfo &&
          this.props.potentialClientInfo.firstName
        ) {
          this.props.editPotentialClient(this.state).then(() => {
            message.success("Potential Client is updated successfully!");
            this.props.getPotentialClientInfo(
              this.props.potentialClientInfo.id
            );
          });
        } else {
          this.props
            .checkDuplicatePotentialClient(this.state)
            .then(() => {
              this.props.addPotentialClient(this.state).then((res) => {
                let procPersonData = {
                  clientId: res.payload,
                  userId: localStorage.getItem("userId"),
                };
                this.props.onAddProcessingPerson(procPersonData);
                message.success("Potential Client is added successfully!");
                setTimeout(() => {
                  this.props.history.push(
                    "/potential-client/potential-clients"
                  );
                }, 3000);
              });
            })
            .catch(() => {
              this.setState({ showDuplicateModal: true });
            });
        }
      }
    );
  };

  handleCancelShowModalDuplicatePClientCheck = () => {
    this.setState({
      showDuplicateModal: false,
    });
  };

  getModalDuplicatePClientCheck = () => {
    return (
      <div>
        <Modal
          title="Confirmation"
          visible={this.state.showDuplicateModal}
          onCancel={this.handleCancelShowModalDuplicatePClientCheck}
          footer={null}
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row>
                {" "}
                {`Potential client with email: ${this.state.email} or name: 
                ${this.state.firstName + " " + this.state.lastName} 
                already exists. Do you want to
                continue?`}
              </Row>
            </Col>
          </Row>
          <Row style={{ display: "flex", marginTop: "40px" }}>
            <Col span={4} offset={15} style={{ marginRight: "10px" }}>
              <Button
                onClick={() => {
                  this.handleCancelShowModalDuplicatePClientCheck();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                disabled={this.state.isDisabled}
                className={"button"}
                onClick={() => {
                  this.setState({ isDisabled: true });
                  setTimeout(() => {
                    this.props
                      .addPotentialClient(this.state)
                      .then((res) => {
                        let procPersonData = {
                          clientId: res.payload,
                          userId: localStorage.getItem("userId"),
                        };
                        this.props.onAddProcessingPerson(procPersonData);
                        message.success(
                          "Potential Client is added successfully!"
                        );
                        this.handleCancelShowModalDuplicatePClientCheck();
                        setTimeout(() => {
                          this.props.history.push(
                            "/potential-client/potential-clients"
                          );
                        }, 1000);
                      })
                      .catch(
                        this.setState({
                          isDisabled: false,
                        })
                      );
                  }, 500);
                }}
              >
                OK
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  getChildInfo = (
    parentInvoiceId,
    childrenTotalAmount,
    parentTotalAmount,
    addInvoice
  ) => {
    this.setState({
      parentInvoiceId: parentInvoiceId,
      childrenTotalAmount: childrenTotalAmount,
      parentTotalAmount: parentTotalAmount,
      addInvoice: addInvoice,
    });
  };

  render() {
    let potentialClientInfo =
      this.props &&
      this.props.potentialClientInfo &&
      this.props.potentialClientInfo;

    return (
      <div>
        <div>
          <Row>
            <Col xs={24}>
              <PotentialHeaderTabs
                data={headOption}
                activePath={window.location.pathname}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <div style={{ display: "flex" }}>
                <div className="page-container">
                  {this.getModalDuplicatePClientCheck()}
                  {this.getModalMovePClient()}
                  {this.getTabs()}
                  {this.state.activeTab === "Detail" && (
                    <div
                      className="border-box emp-profile-box"
                      style={{ backgroundColor: "white " }}
                    >
                      <p style={{ marginLeft: "40px !important" }}>
                        {potentialClientInfo && potentialClientInfo.firstName
                          ? `Name: ${potentialClientInfo.firstName}  ${potentialClientInfo.lastName}`
                          : `Add a New Potential Client`}
                      </p>
                      <Form
                        {...layout}
                        onFinish={this.onFinish}
                        ref={this.formRef}
                      >
                        {this.getForm(potentialClientInfo)}
                      </Form>
                    </div>
                  )}
                  {this.state.activeTab === "EMAIL" && (
                    <PotentialClientEmail
                      onGetEmailContent={this.props.onGetEmailContent}
                      potentialClientInfo={potentialClientInfo}
                      onGetEmployerHistory={this.props.onGetEmployerHistory}
                      employerHistoryRes={this.props.employerHistoryRes}
                      onGetAutoEmailImport={this.props.onGetAutoEmailImport}
                      emailImportRes={this.props.emailImportRes}
                      onAddAutoEmailImport={this.props.onAddAutoEmailImport}
                      onSendEmailLink={this.props.onSendEmailLink}
                      onGetLetterTemplates={this.props.onGetLetterTemplates}
                      LetterTemplatesRes={this.props.LetterTemplatesRes}
                      onGetSignature={this.props.onGetSignature}
                      onGetDocuments={this.props.onGetDocuments}
                      onAddPotentialEmail={this.props.onAddPotentialEmail}
                      onGetPClientHistory={this.props.onGetPClientHistory}
                      potentialClientHistory={this.props.potentialClientHistory}
                      onGetPdf={this.props.onGetPdf}
                      onGetImapForAll={this.props.onGetImapForAll}
                      imapForAllRes={this.props.imapForAllRes}
                      signatureRes={this.props.signatureRes}
                      onAddEmailDocument={this.props.onAddEmailDocument}
                      documentRes={this.props.documentRes}
                      onGetEmployerDocument={this.props.onGetEmployerDocument}
                      employerDocumentRes={this.props.employerDocumentRes}
                      onGetDocumentDownload={this.props.onGetDocumentDownload}
                      onGetPClientImportSetting={
                        this.props.onGetPClientImportSetting
                      }
                    />
                  )}
                  {this.state.activeTab === "DOCUMENTS" && (
                    <PotentialClientDocuments
                      onGetEmployerDocument={this.props.onGetEmployerDocument}
                      employerDocumentRes={this.props.employerDocumentRes}
                      potentialClientInfo={potentialClientInfo}
                      onAddEmployerDocument={this.props.onAddEmployerDocument}
                      onGetDocumentDownload={this.props.onGetDocumentDownload}
                      onGetDocumentChecklist={this.props.onGetDocumentChecklist}
                      docChecklistRes={this.props.docChecklistRes}
                      onGetEmployerCheckList={this.props.onGetEmployerCheckList}
                      onGetFilteredType={this.props.onGetFilteredType}
                      onAddFilteredType={this.props.onAddFilteredType}
                      onRemoveEmployerDocument={
                        this.props.onRemoveEmployerDocument
                      }
                      onUpdateEmployerDocument={
                        this.props.onUpdateEmployerDocument
                      }
                      onSendEmailLink={this.props.onSendEmailLink}
                      employerCheckListRes={this.props.employerCheckListRes}
                      onAddEmployerCheckList={this.props.onAddEmployerCheckList}
                    />
                  )}
                  {this.state.activeTab === "FILE NOTES" && (
                    <FileNotesMain
                      onGetLetterTemplates={this.props.onGetLetterTemplates}
                      LetterTemplatesRes={this.props.LetterTemplatesRes}
                      potentialClientInfo={potentialClientInfo}
                      onGetEmployerFile={this.props.onGetEmployerFile}
                      onAddEmployerFile={this.props.onAddEmployerFile}
                      onGetClientFile={this.props.onGetClientFile}
                      employerDocumentRes={this.props.employerDocumentRes}
                      onAddClientFile={this.props.onAddClientFile}
                      onUpdateClientFile={this.props.onUpdateClientFile}
                      onDeleteClientFile={this.props.onDeleteClientFile}
                      clientFileRes={this.props.clientFileRes}
                      onGetAllUsers={this.props.onGetAllUsers}
                      onAddDailyTasks={this.props.onAddDailyTasks}
                      users={this.state.users}
                    />
                  )}
                  {this.state.activeTab === "ACTIVITY LOG" && (
                    <div style={{ height: "100vh" }}>
                      <h3 style={{ display: "flex", justifyContent: "center" }}>
                        Coming Soon
                      </h3>
                    </div>
                  )}
                  {this.state.activeTab === "MEETINGS" && (
                    <div style={{ height: "100vh" }}>
                      <h3 style={{ display: "flex", justifyContent: "center" }}>
                        Coming Soon
                      </h3>
                    </div>
                  )}
                  {this.state.activeTab === "REMINDERS" && (
                    <div style={{ height: "100vh" }}>
                      <h3 style={{ display: "flex", justifyContent: "center" }}>
                        Coming Soon
                      </h3>
                    </div>
                  )}
                  {this.state.activeTab === "QUESTIONNAIRE" &&
                  localStorage.getItem("userBranchPermissions") &&
                  JSON.parse(
                    decodeURIComponent(
                      escape(
                        window.atob(
                          localStorage.getItem("userBranchPermissions")
                        )
                      )
                    )
                  ).branchPermission.find(
                    (x) => x.name.toLowerCase() == "customquestionnaire"
                  ).status ? (
                    <PotentialClientQuestionnaire
                      potentialClientInfo={potentialClientInfo}
                      onGetAllQuestionnaire={this.props.onGetAllQuestionnaire}
                      onCreateDynamicLink={this.props.onCreateDynamicLink}
                      onGetDynamicLink={this.props.onGetDynamicLink}
                      onGetEmailTemplate={this.props.onGetEmailTemplate}
                      onSetEmailTemplate={this.props.onSetEmailTemplate}
                      onGetQuestionnaire={this.props.onGetQuestionnaire}
                      onUpdateQuestionnaire={this.props.onUpdateQuestionnaire}
                      onGetMappingParent={this.props.onGetMappingParent}
                      onGetMappingChild={this.props.onGetMappingChild}
                      onGetQuestionnaireGroup={
                        this.props.onGetQuestionnaireGroup
                      }
                      onGetGroupedQuestionnaire={
                        this.props.onGetGroupedQuestionnaire
                      }
                      onGetFilledQuestionnaire={
                        this.props.onGetFilledQuestionnaire
                      }
                      onGetAllFilledQuestionnaire={
                        this.props.onGetAllFilledQuestionnaire
                      }
                      onGetCountries={this.props.getCountries}
                      questionnaireList={this.props.questionnaireList}
                      dynamicLinkSuccess={this.props.dynamicLinkSuccess}
                      dynamicLinkData={this.props.dynamicLinkData}
                      emailTemplateData={this.props.emailTemplateData}
                      setEmailData={this.props.setEmailData}
                      countriesData={this.props.countriesData}
                      questionnaireData={this.props.questionnaireData}
                      mappParentData={this.props.mappParentData}
                      mappChildData={this.props.mappChildData}
                      questionnaireGroupRes={this.props.questionnaireGroupRes}
                      groupedQuestionnaireRes={
                        this.props.groupedQuestionnaireRes
                      }
                      filledQuestionnaireRes={this.props.filledQuestionnaireRes}
                      allFilledQuestionnaireRes={
                        this.props.allFilledQuestionnaireRes
                      }
                      mergeFromClientRes={this.props.mergeFromClientRes}
                      updMergeFromClientRes={this.props.updMergeFromClientRes}
                      updMergeToClientRes={this.props.updMergeToClientRes}
                      onMergeFromClient={this.props.onMergeFromClient}
                      onUpdMergeFromClient={this.props.onUpdMergeFromClient}
                      onUpdMergeToClient={this.props.onUpdMergeToClient}
                      onGetSignature={this.props.onGetSignature}
                      signatureRes={this.props.signatureRes}
                      onGetVisaType={this.props.onGetVisaType}
                      visaTypeData={this.props.visaTypeData}
                    />
                  ) : (
                    this.state.activeTab === "QUESTIONNAIRE" && (
                      <PurchaseSubscription />
                    )
                  )}
                  {this.state.activeTab === "CREDIT NOTES" && (
                    <div style={{ height: "100vh" }}>
                      <h3 style={{ display: "flex", justifyContent: "center" }}>
                        Coming Soon
                      </h3>
                    </div>
                  )}
                  {this.state.activeTab === "DEALS" &&
                  localStorage.getItem("userBranchPermissions") &&
                  JSON.parse(
                    decodeURIComponent(
                      escape(
                        window.atob(
                          localStorage.getItem("userBranchPermissions")
                        )
                      )
                    )
                  ).branchPermission.find(
                    (x) => x.name.toLowerCase() == "deals"
                  ).status ? (
                    <div style={{ height: "100vh" }}>
                      <DealCard
                        clientId={potentialClientInfo.id}
                        onGetTeamMember={this.props.getTeamMember}
                        teamMembers={this.props.teamMembersData}
                        onGetBranchPipeline={this.props.onGetBranchPipeline}
                        brnchPipelineData={this.props.brnchPipelineData}
                        hideButton={true}
                        clientType="pc"
                      />
                    </div>
                  ) : (
                    this.state.activeTab === "DEALS" && <PurchaseSubscription />
                  )}
                  {this.state.activeTab === "TASKS" && (
                    <TaskList
                      potentialClientInfo={
                        this.props && this.props.potentialClientInfo
                      }
                      onGetClientTask={this.props.onGetClientTask}
                      onUpdateTask={this.props.onUpdateTask}
                      onGetAllUsers={this.props.onGetAllUsers}
                      onAddTaskFollower={this.props.onAddTaskFollower}
                      onRemoveTasks={this.props.onRemoveTasks}
                      onAddDailyTasks={this.props.onAddDailyTasks}
                      onGetClientFamily={this.props.onGetClientFamily}
                      onUpdateCompletedTask={this.props.onUpdateCompletedTask}
                      onAddTaskFileNote={this.props.onAddTaskFileNote}
                      onAddTaskComment={this.props.onAddTaskComment}
                      onGetTaskComments={this.props.onGetTaskComments}
                      onGetTaskFollowers={this.props.onGetTaskFollowers}
                      clientInfo={this.props.potentialClientInfo}
                      onGetPotentialTasks={this.props.onGetPotentialTasks}
                      onRemoveFollowers={this.props.onRemoveFollowers}
                      users={this.state.users}
                    />
                  )}
                  {this.state.loading ? (
                    <div className={"spinner"}>
                      <Spin size="large" />
                    </div>
                  ) : this.state.addInvoice === "invoices" ? (
                    // <div style={{ height: "100vh" }}>
                    //   <h3 style={{ display: "flex", justifyContent: "center" }}>
                    //     Coming Soon!
                    //   </h3>
                    // </div>
                    <Invoices
                      potentialClientInfo={
                        this.props && this.props.potentialClientInfo
                      }
                      getChildInfo={this.getChildInfo}
                    />
                  ) : // <div className="ts-container-Pclient">
                  //   <div style={{height: "100vh"}}>
                  //     <h3 style={{display: "flex", justifyContent: "center"}}>
                  //       Coming Soon!
                  //     </h3>
                  //   </div>
                  //   {/*<Invoices*/}
                  //   {/*  potentialClientInfo={*/}
                  //   {/*    this.props && this.props.potentialClientInfo*/}
                  //   {/*  }*/}
                  //   {/*  getChildInfo={this.getChildInfo}*/}
                  //   {/*/>*/}
                  // </div>
                  null}
                  {this.state.addInvoice === "add" ? (
                    <div
                      className="border-box emp-profile-box"
                      style={{ backgroundColor: "white " }}
                    >
                      <div>
                        <p style={{ marginBottom: "8px" }}>
                          {potentialClientInfo && potentialClientInfo.firstName
                            ? `Name: ${potentialClientInfo.firstName} ${potentialClientInfo.lastName}`
                            : `Add a New Potential Client`}
                        </p>
                        <AddInvoice
                          potentialClientInfo={
                            this.props && this.props.potentialClientInfo
                          }
                          parentInvoiceId={this.state.parentInvoiceId}
                          childrenTotalAmount={this.state.childrenTotalAmount}
                          parentTotalAmount={this.state.parentTotalAmount}
                          getChildInfo={this.getChildInfo}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {/* > */}
        {/* {potentialClientInfo && potentialClientInfo.firstName
                        ? `NAME: ${potentialClientInfo.firstName.toUpperCase()}`
                        : `ADD A NEW POTENTIAL CLIENT`}
                    </h4>
                  </Col>
                </Row>
                {this.getTabs()}
                <div className="ts-container-client">
                  <Form {...layout} onFinish={this.onFinish} ref={this.formRef}>
                    {this.getForm(potentialClientInfo)}
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {this.getModalDuplicatePClientCheck()} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    potentialClientInfo:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.potentialClientInfo &&
      state.potentialClientReducer.potentialClientInfo,
    bol:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.bol &&
      state.potentialClientReducer.bol,
    clientTagRes:
      state &&
      state.accountSetReducer &&
      state.accountSetReducer.clientTagRes &&
      state.accountSetReducer.clientTagRes,
    clientSourceListing:
      state &&
      state.allClientReducer &&
      state.allClientReducer.clientSourceListing &&
      state.allClientReducer.clientSourceListing.items &&
      state.allClientReducer.clientSourceListing.items,
    teamMembers:
      state &&
      state.teamMemberReducer &&
      state.teamMemberReducer.teamMembers &&
      state.teamMemberReducer.teamMembers.users &&
      state.teamMemberReducer.teamMembers.users,
    countriesDataItems:
      state &&
      state.visaTypeReducer &&
      state.visaTypeReducer.countriesData &&
      state.visaTypeReducer.countriesData.items &&
      state.visaTypeReducer.countriesData.items,
    visaTypeData:
      state && state.visaTypeReducer && state.visaTypeReducer.visaTypeData,
    selectedBranch:
      state && state.branchReducer && state.branchReducer.selectedBranch,

    employerHistoryRes: state.employerManagReducer.employerHistoryRes,
    emailImportRes: state.employerManagReducer.emailImportRes,
    LetterTemplatesRes: state.LetterTemplatesReducer.LetterTemplatesRes,
    employerDocumentRes: state.employerManagReducer.employerDocumentRes,
    docChecklistRes: state.docChecklistReducer.docChecklistRes,
    clientFileRes: state.employerManagReducer.clientFileRes,
    addClientFileRes: state.employerManagReducer.addClientFileRes,
    potentialClientData:
      state &&
      state.potentialClientReducer &&
      state.potentialClientReducer.potentialClientData &&
      state.potentialClientReducer.potentialClientData.items &&
      state.potentialClientReducer.potentialClientData.items,
    potentialClientHistory: state.potentialClientReducer.potentialClientHistory,
    imapForAllRes: state.imapSettingReducer.imapForAllRes,
    signatureRes: state.userLevelSettingsReducer.signatureRes,
    documentRes: state.accountDocumentReducer.documentRes,

    questionnaireList: state.questionnaireReducer.questionnaireList,
    dynamicLinkSuccess: state.questionnaireReducer.dynamicLinkSuccess,
    dynamicLinkData: state.questionnaireReducer.dynamicLinkData,
    emailTemplateData: state.questionnaireReducer.emailTemplateData,
    setEmailData: state.questionnaireReducer.setEmailData,
    clientTab: state.employerManagReducer.clientTab,
    countriesData: state.visaTypeReducer.countriesData,
    questionnaireData: state.questionnaireReducer.questionnaireData,
    mappParentData: state.questionnaireReducer.mappParentData,
    mappChildData: state.questionnaireReducer.mappChildData,
    questionnaireGroupRes: state.questionnaireReducer.questionnaireGroupRes,
    groupedQuestionnaireRes: state.questionnaireReducer.groupedQuestionnaireRes,
    filledQuestionnaireRes: state.questionnaireReducer.filledQuestionnaireRes,
    allFilledQuestionnaireRes:
      state.questionnaireReducer.allFilledQuestionnaireRes,
    mergeFromClientRes: state.questionnaireReducer.mergeFromClientRes,
    updMergeFromClientRes: state.questionnaireReducer.updMergeFromClientRes,
    updMergeToClientRes: state.questionnaireReducer.updMergeToClientRes,
    employerCheckListRes: state.employerManagReducer.employerCheckListRes,
    brnchPipelineData: state.dealsReducer.brnchPipelineData,
    teamMembersData: state.teamMemberReducer.teamMembers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getClientTag: bindActionCreators(getClientTag, dispatch),
  onGetVisaType: bindActionCreators(getVisaType, dispatch),
  onGetFilteredType: bindActionCreators(getFilteredType, dispatch),
  onAddFilteredType: bindActionCreators(addFilteredType, dispatch),
  getClientSource: bindActionCreators(getClientSource, dispatch),
  addPotentialClient: bindActionCreators(addPotentialClient, dispatch),
  getTeamMember: bindActionCreators(getTeamMember, dispatch),
  getCountries: bindActionCreators(getCountries, dispatch),
  editPotentialClient: bindActionCreators(editPotentialClient, dispatch),
  onGetEmployerHistory: bindActionCreators(getEmployerHistory, dispatch),
  onGetAutoEmailImport: bindActionCreators(getAutoEmailImport, dispatch),
  onAddAutoEmailImport: bindActionCreators(addAutoEmailImport, dispatch),
  onSendEmailLink: bindActionCreators(sendDynamicLinkEmail, dispatch),
  onGetLetterTemplates: bindActionCreators(getLetterTemplates, dispatch),
  onGetEmployerFile: bindActionCreators(getEmployerFile, dispatch),
  onAddEmployerFile: bindActionCreators(addEmployerFile, dispatch),
  onGetEmployerDocument: bindActionCreators(getEmployerDocument, dispatch),
  onAddEmployerDocument: bindActionCreators(addEmployerDocument, dispatch),
  onUpdateEmployerDocument: bindActionCreators(
    updateEmployerDocument,
    dispatch
  ),
  onGetDocumentDownload: bindActionCreators(getDocumentDownload, dispatch),
  onAddEmployerCheckList: bindActionCreators(addEmployerCheckList, dispatch),
  onGetDocumentChecklist: bindActionCreators(getDocumentChecklist, dispatch),
  onAddDocumentChecklist: bindActionCreators(addDocumentChecklist, dispatch),
  onGetEmployerCheckList: bindActionCreators(getEmployerCheckList, dispatch),
  onRemoveEmployerDocument: bindActionCreators(
    removeEmployerDocument,
    dispatch
  ),
  getPotentialClientStatus: bindActionCreators(
    getPotentialClientStatus,
    dispatch
  ),
  onGetClientFile: bindActionCreators(getClientFile, dispatch),
  onAddClientFile: bindActionCreators(addClientFile, dispatch),

  onUpdateClientFile: bindActionCreators(updateClientFile, dispatch),
  onDeleteClientFile: bindActionCreators(deleteClientFile, dispatch),
  checkDuplicatePotentialClient: bindActionCreators(
    checkDuplicatePotentialClient,
    dispatch
  ),
  getCompany: bindActionCreators(getCompany, dispatch),
  onGetClientTask: bindActionCreators(getClientTasks, dispatch),
  onUpdateTask: bindActionCreators(updetaTasks, dispatch),
  onGetAllUsers: bindActionCreators(getUsers, dispatch),
  onAddTaskFollower: bindActionCreators(addTaskFollower, dispatch),
  onRemoveTasks: bindActionCreators(removeTasks, dispatch),
  onAddDailyTasks: bindActionCreators(addDailyTasks, dispatch),
  onGetClientFamily: bindActionCreators(getClientFamily, dispatch),
  onUpdateCompletedTask: bindActionCreators(updateCompletedTask, dispatch),
  onAddTaskFileNote: bindActionCreators(addTaskFileNote, dispatch),
  onAddTaskComment: bindActionCreators(addTaskComment, dispatch),
  onGetTaskComments: bindActionCreators(getTasksComments, dispatch),
  onGetTaskFollowers: bindActionCreators(getTasksFollowers, dispatch),
  onGetPotentialTasks: bindActionCreators(getPotentialClientTasks, dispatch),
  movePClientToClient: bindActionCreators(movePClientToClient, dispatch),
  onRemoveFollowers: bindActionCreators(removeFollower, dispatch),
  onGetSignature: bindActionCreators(getSignature, dispatch),
  onGetDocuments: bindActionCreators(getDocuments, dispatch),
  onAddPotentialEmail: bindActionCreators(manualImportPotentialEmail, dispatch),
  onGetPClientHistory: bindActionCreators(getPClientEmailHistory, dispatch),
  onGetPdf: bindActionCreators(getPdf, dispatch),
  onGetImapForAll: bindActionCreators(getImapForAll, dispatch),
  onAddEmailDocument: bindActionCreators(addEmailDocument, dispatch),
  getPotentialClientInfo: bindActionCreators(getPotentialClientInfo, dispatch),

  onGetAllQuestionnaire: bindActionCreators(getAllQuestionnaire, dispatch),
  onCreateDynamicLink: bindActionCreators(createDynamicLink, dispatch),
  onGetDynamicLink: bindActionCreators(getDynamicLink, dispatch),
  onGetEmailTemplate: bindActionCreators(getEmailTemplate, dispatch),
  onSetEmailTemplate: bindActionCreators(setEmailTemplate, dispatch),
  onGetQuestionnaire: bindActionCreators(getQuestionnaire, dispatch),
  onUpdateQuestionnaire: bindActionCreators(updateQuestionnaire, dispatch),
  onGetMappingParent: bindActionCreators(getMappingParent, dispatch),
  onGetMappingChild: bindActionCreators(getMappingChild, dispatch),
  onGetQuestionnaireGroup: bindActionCreators(getQuestionnaireGroup, dispatch),
  onGetGroupedQuestionnaire: bindActionCreators(groupedQuestionnaire, dispatch),
  onGetFilledQuestionnaire: bindActionCreators(
    getFilledQuestionnaire,
    dispatch
  ),
  onGetAllFilledQuestionnaire: bindActionCreators(
    getAllFilledQuestionnaire,
    dispatch
  ),
  onMergeFromClient: bindActionCreators(mergeFromPotentialClient, dispatch),
  onUpdMergeFromClient: bindActionCreators(
    updMergeFromPotentialClient,
    dispatch
  ),
  onUpdMergeToClient: bindActionCreators(updMergeToPotentialClient, dispatch),
  onAddProcessingPerson: bindActionCreators(addProcessingPerson, dispatch),
  onGetEmailContent: bindActionCreators(getEmailContent, dispatch),
  onGetBranchPipeline: bindActionCreators(getBranchPipeline, dispatch),
  onRemoveProcessingPerson: bindActionCreators(
    removeProcessingPerson,
    dispatch
  ),
  onGetPermProcessingPerson: bindActionCreators(getPermissonUser, dispatch),
  onGetMailBox: bindActionCreators(getMailBox, dispatch),
  onAutoImportEmailClient: bindActionCreators(autoImportEmailClient, dispatch),
  onGetPClientImportSetting: bindActionCreators(
    getPClientEmailImport,
    dispatch
  ),
});

AddNewPotentialClient = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewPotentialClient);

export default withRouter(AddNewPotentialClient);
